// @flow

import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";

import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";

import CreateIcon from "@material-ui/icons/Create";

import { useTeams, Provider, type Team } from "./context/teams";
import Table, {
    type Column,
    dataTypes,
} from "../../shared/components/SimpleTable";
import SectionToolbar from "../../shared/components/SectionToolbar";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import { DebounceInput } from "react-debounce-input";
import TextField from "@material-ui/core/TextField";

const columns: Column[] = [
    {
        label: "Id",
        name: "id",
    },
    {
        label: "Name",
        name: "name",
    },
    {
        dataType: dataTypes.BOOLEAN,
        label: "Is shown",
        name: "show",
    },
    {
        dataType: dataTypes.DATE_TIME,
        label: "Created At",
        name: "createdAt",
    },
    {
        formatter: ({ createdBy }) =>
            [createdBy?.firstName, createdBy?.lastName].join(" "),
        isSortable: false,
        label: "Created By",
        name: "createdBy",
    },
    {
        dataType: dataTypes.DATE_TIME,
        label: "Updated At",
        name: "updatedAt",
    },
    {
        formatter: ({ updatedBy }) =>
            [updatedBy?.firstName, updatedBy?.lastName].join(" "),
        isSortable: false,
        label: "Updated By",
        name: "updatedBy",
    },
];

const PaymentsSection = () => {
    const { teams, count, isLoading, refetch } = useTeams();
    const history = useHistory();

    const handleEdit = useCallback(
        ({ slug }: Team) => {
            history.push(`/forecasts/teams/${slug}`);
        },
        [history]
    );

    const handleCreate = useCallback(() => {
        history.push("/forecasts/teams/new");
    }, [history]);

    const handleSearch = useCallback(
        (e) =>
            refetch({
                searchValue: e.target.value || undefined,
            }),
        [refetch]
    );

    return (
        <Paper>
            <SectionToolbar
                title={<>Teams {!isLoading && <>({count})</>}</>}
                refetch={refetch}
            >
                <TextField
                    label="Search"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                        inputComponent: DebounceInput,
                        inputProps: { debounceTimeout: 500 },
                        onChange: handleSearch,
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <Button
                    variant="contained"
                    color="primary"
                    endIcon={<CreateIcon />}
                    onClick={handleCreate}
                >
                    Create team
                </Button>
            </SectionToolbar>
            <Table
                columns={columns}
                data={teams}
                count={count}
                refetch={refetch}
                isLoading={isLoading}
                onEdit={handleEdit}
                withPagination={false}
            />
        </Paper>
    );
};

export default () => (
    <Provider>
        <PaymentsSection />
    </Provider>
);
