// @flow
import React, { useState, useCallback, useMemo, useEffect } from 'react'
import { type Match, useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components/macro'
import fpKeyBy from 'lodash/fp/keyBy'
import compose from 'lodash/fp/compose'
import fpMap from 'lodash/fp/map'
import fpPick from 'lodash/fp/pick'
import keyBy from 'lodash/keyBy'
import times from 'lodash/times'
import mapValues from 'lodash/mapValues'
import { DateTimePicker } from '@material-ui/pickers'

import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import RefreshIcon from '@material-ui/icons/Refresh'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import CircularProgress from '@material-ui/core/CircularProgress'
import Input from '@material-ui/core/Input'
import Checkbox from '@material-ui/core/Checkbox'
import ListItemText from '@material-ui/core/ListItemText'

import formatDate from '../../utils/formatDate'
import Loader from '../../shared/components/Loader'
import ContentEditor from '../../shared/components/ContentEditor'
import useAPI from '../../shared/hooks/useAPI'
import { type Forecast } from './context/forecasts'
import withErrorHandler from '../../shared/hocs/withErrorHandler'
import {
    useCategories,
    Provider as CategoriesProvider,
} from '../context/categories'
import useCRUD from '../../shared/hooks/useCRUD'
import ImageUpload from '../../shared/components/ImageUpload'
import stateToHTML from '../../utils/stateToHTML'
import Sticky from 'react-sticky-el'
import NotFound from '../../shared/components/NotFound'
import type { RefetchType } from '../../shared/hooks/useAPI'
import { useTeams, Provider as TeamsProvider } from './context/teams'
import {
    useReviews,
    Provider as ReviewsProvider,
} from '../Reviews/context/reviews'
import { type Config, useConfig } from '../context/config'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import StatusSelect from '../../shared/components/StatusSelect'
import { globalLanguages } from '../../contants'
import getSlug from '../../utils/getSlug'

import EditorComponent from '../../shared/components/Editor'

const Container = styled.div`
    padding: 15px;
`

const ToolbarContainer = styled.div`
    > * {
        margin-right: 5px;
    }
`

type Props = {
    isNew?: boolean,
    data: { forecast: ?Forecast },
    isLoading: boolean,
    refetch?: RefetchType,
}

const languages = globalLanguages

const stickyStyle = {
    backgroundColor: 'white',
    paddingBottom: 15,
    paddingTop: 77,
    zIndex: 2,
}

const teamContainerStyles = {
    alignItems: 'center',
    display: 'flex',
}

const teamImageStyles = {
    borderRadius: 0,
    height: 42,
    marginRight: 15,
}

const getEntity = ({
    forecast,
    config,
}: {
    forecast: ?Forecast,
    config: ?Config,
}) =>
    forecast
        ? {
              author: forecast.author,
              coefficient: forecast.coefficient,
              commentStatus: forecast.commentStatus || 'opened',
              content: compose(
                  fpKeyBy('locale'),
                  fpMap(
                      fpPick([
                          'locale',
                          'title',
                          'content',
                          'createdAt',
                          'metaTitle',
                          'metaDescription',
                      ]),
                  ),
              )(forecast.mainContent),
              eventDate: forecast.eventDate
                  ? new Date(forecast.eventDate)
                  : null,
              forecastDate: forecast.forecastDate,
              hasDraw:
                  typeof forecast.hasDraw === 'boolean'
                      ? forecast.hasDraw
                      : true,
              images: mapValues(
                  forecast.images,
                  (url) => `https://${config.filesBucketName}${url}`,
              ),
              league: forecast.league,
              slug: forecast.slug,
              status: forecast.status || 'created',
              tags: forecast.tags || [],
              teams: forecast.teams || [],
              videoUrl: forecast.videoUrl || '',
          }
        : null

const youtubeEmbedUrlRegex = /https:\/\/www\.youtube\.com\/embed\/(.+)/

const prepareUpdateBody = ({
    coefficient,
    content,
    images,
    eventDate,
    teams,
    ...forecast
}) => {
    Object.keys(content).forEach((key) => {
        const html = document.createElement('div')
        html.innerHTML = content[key].content
        if (html.querySelector('[data-f-id]')) {
            html.querySelector('[data-f-id]').remove()
        }
        content[key].content = html.innerHTML
    })
    return {
        content: mapValues(
            content,
            ({ images, createdAt, ...localeContent }) => ({
                ...localeContent,
                content: !localeContent.content
                    ? ''
                    : typeof localeContent.content === 'string'
                    ? localeContent.content
                    : stateToHTML(localeContent.content.getCurrentContent()),
            }),
        ),
        forecast: {
            ...forecast,
            coefficient: {
                ...coefficient,
                value:
                    typeof coefficient.value === 'string'
                        ? parseFloat(coefficient.value)
                        : coefficient.value,
            },
            eventDate: eventDate instanceof Date && eventDate.toISOString(),
            images: mapValues(images, (url) => new URL(url).pathname),
            teams: fpMap(fpPick(['win', 'teamId']))(teams),
            videoUrl: forecast.videoUrl || null,
        },
    }
}

const ForecastPage = ({ data, isLoading, refetch, isNew = false }: Props) => {
    const { config } = useConfig()
    const {
        categories: allCategories,
        isLoading: isCategoriesLoading,
    } = useCategories()
    const { teams: allTeams, isLoading: isTeamsLoading } = useTeams()
    const { reviews: allReviews } = useReviews()
    const forecast = data?.forecast

    const { search } = useLocation()

    const teams = useMemo(
        () => allTeams && allTeams.filter(({ show }) => show),
        [allTeams],
    )

    const allTeamsById = useMemo(() => allTeams && keyBy(allTeams, 'id'), [
        allTeams,
    ])

    const reviews = useMemo(
        () =>
            allReviews &&
            allReviews.filter(({ status }) => status === 'published'),
        [allReviews],
    )

    const allReviewsById = useMemo(
        () => allReviews && keyBy(allReviews, 'id'),
        [allReviews],
    )

    const categories = useMemo(
        () => allCategories && allCategories.filter(({ show }) => show),
        [allCategories],
    )

    const mainCategories = useMemo(
        () =>
            categories ? categories.filter(({ type }) => type === 'main') : [],
        [categories],
    )

    const secondaryCategories = useMemo(
        () =>
            categories
                ? categories.filter(({ type }) => type === 'secondary')
                : [],
        [categories],
    )

    const allCategoriesById = useMemo(
        () => allCategories && keyBy(allCategories, 'id'),
        [allCategories],
    )

    const initialEntity = useMemo(() => getEntity({ config, forecast }), [
        config,
        forecast,
    ])

    const {
        entity,
        handleChangeEvent,
        handleChange,
        handleMultipleChange,
        isChanged,
        save,
        reset,
        isLoading: isSaving,
    } = useCRUD({
        entity: initialEntity,
        prepareBody: prepareUpdateBody,
        saveMethod: isNew ? 'POST' : 'PUT',
        url: `/content/forecasts${isNew ? '' : `/${String(forecast?.id)}`}`,
    })

    const contentLocale = forecast?.mainContent?.[0]?.locale

    const { slug, content, videoUrl } = entity || {}

    const urlLocale = useMemo(() => {
        let locale = new URLSearchParams(search).get('locale')

        return Object.keys(languages).includes(locale) ? locale : null
    }, [search])

    const [locale, setLocale] = useState(urlLocale || contentLocale || 'ru')

    useEffect(() => {
        if (contentLocale && !urlLocale) {
            setLocale(contentLocale)
        }
    }, [contentLocale, urlLocale])

    const handleContentChange = useCallback(
        async (e, lang) => {
            let modifiedContentData = entity
            modifiedContentData.content[lang].content = e

            handleChange(`content.${lang}.content`)
        },
        [content, handleChange],
    )

    const handleLocaleChange = useCallback(
        (e: SyntheticInputEvent<HTMLSelectElement>) => {
            const contentText = content[locale]?.content?.getCurrentContent?.()

            if (typeof contentText !== 'undefined') {
                const content = stateToHTML(contentText)
                handleChange(`content[${locale}].content`)(content)
            }

            window.history.replaceState(
                {},
                null,
                `/forecasts/${isNew ? 'new' : slug}?locale=${e.target.value}`,
            )
            setLocale(e.target.value)
        },
        [content, handleChange, isNew, locale, slug],
    )

    const history = useHistory()

    const goBack = useCallback(() => {
        history.push('/forecasts')
    }, [history])

    const handleSave = useCallback(async () => {
        await save()
        history.push(`/forecasts/${slug}?locale=${locale}`)
    }, [save, history, slug, locale])

    const handleTitleChange = useCallback(
        async (e, lang) => {
            const title = e.target.value.slice(0, 156)

            if (!isNew) {
                handleChange(`content.${lang}.title`)(title)
                return
            }

            let slug

            if (lang === 'ru' && !title) {
                slug = getSlug(content['uk']?.title || '')
            }

            if ((lang === 'ru' && title) || !content['ru']?.title) {
                slug = getSlug(title)
            }

            if (slug) {
                handleMultipleChange({
                    slug,
                    [`content.${lang}.title`]: title,
                })
                return
            }

            handleChange(`content.${lang}.title`)(title)
        },
        [content, handleChange, handleMultipleChange, isNew],
    )

    const handleWinTeamChange = useCallback(
        (e) => {
            const { value } = e.target

            if (value === 'draw') {
                handleMultipleChange({
                    hasDraw: true,
                    'teams.0.win': false,
                    'teams.1.win': false,
                })
                return
            }

            handleMultipleChange({
                hasDraw: false,
                [`teams.${value}.win`]: true,
                [`teams.${(+value + 1) % 2}.win`]: false,
            })
        },
        [handleMultipleChange],
    )

    const handleTeamChange = useCallback(
        (i) => (e, { id }) => handleChange(`teams.${i}.teamId`)(id),
        [handleChange],
    )

    const handleReviewChange = useCallback(
        (e, { id }) => handleChange('coefficient.reviewId')(id),
        [handleChange],
    )

    const handleLeagueChange = useCallback(
        (e, { id }) => handleChange('league')(id),
        [handleChange],
    )

    const handleReload = useCallback(() => refetch && refetch(), [refetch])
    const isVideoUrlError = useMemo(
        () => Boolean(videoUrl) && !youtubeEmbedUrlRegex.test(videoUrl),
        [videoUrl],
    )

    if (isLoading || isCategoriesLoading || isTeamsLoading) {
        return <Loader isFullHeight />
    }

    if (!forecast || !entity) {
        return <NotFound name="Forecasts" url="/forecasts" />
    }

    return (
        <Paper>
            <Container>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Sticky stickyStyle={stickyStyle}>
                            <ToolbarContainer>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    onClick={isNew ? handleSave : save}
                                    disabled={
                                        !slug ||
                                        !entity.eventDate ||
                                        !entity.league ||
                                        !entity.teams?.[0]?.teamId ||
                                        !entity.teams?.[1]?.teamId ||
                                        !entity.coefficient.value ||
                                        !entity.coefficient.reviewId ||
                                        !isChanged ||
                                        isSaving ||
                                        isVideoUrlError
                                    }
                                    endIcon={
                                        isSaving && (
                                            <CircularProgress size={20} />
                                        )
                                    }
                                >
                                    Save
                                </Button>
                                <Button
                                    size="large"
                                    color="secondary"
                                    onClick={isNew ? goBack : reset}
                                    disabled={
                                        !isNew && (!isChanged || isSaving)
                                    }
                                >
                                    Cancel
                                </Button>
                                {refetch && (
                                    <Tooltip title="Reload forecast (all changes will be cancelled)">
                                        <IconButton
                                            color="primary"
                                            onClick={handleReload}
                                        >
                                            <RefreshIcon />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </ToolbarContainer>
                        </Sticky>
                        <Typography variant="h5">{slug}</Typography>
                        {entity.forecastDate && (
                            <Typography variant="caption">
                                {formatDate(entity.forecastDate)}
                                {entity.author &&
                                    ` by ${
                                        [
                                            entity.author.firstName,
                                            entity.author.lastName,
                                        ].join(' ') || 'Unknown'
                                    }`}
                            </Typography>
                        )}
                        {entity.updatedAt && (
                            <Typography variant="caption" component="div">
                                Last updated at {formatDate(entity.updatedAt)}
                                {entity.updatedBy &&
                                    ` by ${
                                        [
                                            entity.updatedBy.firstName,
                                            entity.updatedBy.lastName,
                                        ].join(' ') || 'Unknown'
                                    }`}
                            </Typography>
                        )}
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <StatusSelect
                            value={entity.status}
                            onChange={handleChangeEvent('status')}
                            isDisabled={isSaving}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <FormControl fullWidth>
                            <InputLabel id="commentStatus-select" shrink>
                                Comment status
                            </InputLabel>
                            <Select
                                labelId="commentStatus-select"
                                value={entity.commentStatus}
                                onChange={handleChangeEvent('commentStatus')}
                                disabled={isSaving}
                            >
                                {['opened', 'closed'].map((status) => (
                                    <MenuItem value={status} key={status}>
                                        {status}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <DateTimePicker
                            fullWidth
                            autoOk
                            ampm={false}
                            disablePast
                            value={entity.eventDate}
                            onChange={handleChange('eventDate')}
                            label="Event date"
                            disabled={isSaving}
                            format="dd.MM.yyyy HH:mm"
                            error={!entity.eventDate}
                            helperText={!entity.eventDate && 'Is required'}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    {allCategories && (
                        <>
                            <Grid item xs={12} md={6} lg={4}>
                                <FormControl fullWidth disabled={isSaving}>
                                    <InputLabel
                                        id="categories-select-label"
                                        shrink
                                    >
                                        Categories
                                    </InputLabel>
                                    <Select
                                        labelId="categories-select-label"
                                        multiple
                                        onChange={handleChangeEvent('tags')}
                                        input={<Input />}
                                        value={entity.tags}
                                        renderValue={(selectedValues) =>
                                            selectedValues
                                                .map(
                                                    (id) =>
                                                        allCategoriesById[id]
                                                            ?.slug,
                                                )
                                                .join(', ')
                                        }
                                    >
                                        {mainCategories.map(({ id, slug }) => (
                                            <MenuItem key={id} value={id}>
                                                <Checkbox
                                                    checked={entity.tags.includes(
                                                        id,
                                                    )}
                                                />
                                                <ListItemText primary={slug} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>
                                <Autocomplete
                                    fullWidth
                                    disableClearable
                                    disabled={isSaving}
                                    options={secondaryCategories}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="League"
                                            error={!entity.league}
                                            helperText={
                                                !entity.league && 'Is required'
                                            }
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    )}
                                    getOptionLabel={({ slug }) => slug}
                                    onChange={handleLeagueChange}
                                    value={
                                        allCategoriesById[entity.league] || null
                                    }
                                />
                            </Grid>
                        </>
                    )}
                    <Grid item xs={12} md={6} lg={4}>
                        <TextField
                            value={entity.videoUrl}
                            fullWidth
                            helperText={
                                isVideoUrlError
                                    ? 'Invalid embed url (https://www.youtube.com/embed/xxxxx)'
                                    : 'Used in preview (https://www.youtube.com/embed/xxxxx)'
                            }
                            label="Video URL"
                            error={isVideoUrlError}
                            onChange={handleChangeEvent('videoUrl')}
                            disabled={isSaving}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    {teams && (
                        <Grid item xs={12}>
                            <RadioGroup
                                style={{ width: '100%' }}
                                value={
                                    entity.hasDraw
                                        ? 'draw'
                                        : entity.teams.findIndex(
                                              ({ win }) => win,
                                          )
                                }
                                onChange={handleWinTeamChange}
                            >
                                <Grid container spacing={4} alignItems="center">
                                    {times(2, (i) => (
                                        <Grid
                                            item
                                            xs={12}
                                            md={4}
                                            key={i}
                                            style={teamContainerStyles}
                                        >
                                            <FormControlLabel
                                                value={i}
                                                control={<Radio />}
                                                label=""
                                            />
                                            {allTeamsById[
                                                entity.teams?.[i]?.teamId
                                            ]?.image && (
                                                <img
                                                    src={`https://${
                                                        config.filesBucketName
                                                    }${
                                                        allTeamsById[
                                                            entity.teams?.[i]
                                                                ?.teamId
                                                        ]?.image
                                                    }`}
                                                    style={teamImageStyles}
                                                    alt="Team logo"
                                                />
                                            )}

                                            <Autocomplete
                                                fullWidth
                                                disableClearable
                                                disabled={isSaving}
                                                options={teams}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label={`Team ${i + 1}`}
                                                        error={
                                                            !entity.teams[i]
                                                                ?.teamId
                                                        }
                                                        helperText={
                                                            !entity.teams[i]
                                                                ?.teamId &&
                                                            'Is required'
                                                        }
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                                getOptionLabel={({ name }) =>
                                                    name
                                                }
                                                onChange={handleTeamChange(i)}
                                                value={
                                                    allTeamsById[
                                                        entity.teams?.[i]
                                                            ?.teamId
                                                    ] || null
                                                }
                                            />
                                        </Grid>
                                    ))}
                                    <Grid item xs={12} md={4}>
                                        <FormControlLabel
                                            value="draw"
                                            control={<Radio />}
                                            label="Draw"
                                        />
                                    </Grid>
                                </Grid>
                            </RadioGroup>
                        </Grid>
                    )}
                    <Grid item xs={12} sm={4} md={2}>
                        <TextField
                            fullWidth
                            type="number"
                            inputProps={{ min: 0, step: 0.1 }}
                            label="Coefficient"
                            InputLabelProps={{ shrink: true }}
                            value={entity.coefficient?.value}
                            onChange={handleChangeEvent('coefficient.value')}
                            disabled={isSaving}
                            error={!entity.coefficient?.value}
                            helperText={
                                !entity.coefficient?.value && 'Is required'
                            }
                        />
                    </Grid>
                    {reviews && (
                        <Grid item xs={12} sm={8} md={4}>
                            <Autocomplete
                                fullWidth
                                disableClearable
                                disabled={isSaving}
                                options={reviews}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Bookmaker, casino"
                                        error={!entity.coefficient?.reviewId}
                                        helperText={
                                            !entity.coefficient?.reviewId &&
                                            'Is required'
                                        }
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                )}
                                getOptionLabel={({ slug }) => slug}
                                onChange={handleReviewChange}
                                value={
                                    allReviewsById[
                                        entity.coefficient?.reviewId
                                    ] || null
                                }
                            />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Typography variant="caption" color="textSecondary">
                            Image
                        </Typography>
                    </Grid>
                    <ImageUpload
                        prefix={`images/forecasts/${slug}`}
                        value={entity.images.main}
                        onChange={handleChange('images.main')}
                        disabled={!slug || isSaving}
                        errorMessage={!slug ? 'Enter title first' : undefined}
                    />
                    <Grid
                        item
                        container
                        xs={12}
                        spacing={2}
                        alignItems="center"
                    >
                        <Grid item>
                            <Typography variant="h5">Content</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                            <FormControl fullWidth>
                                <InputLabel id="language-select">
                                    Language
                                </InputLabel>
                                <Select
                                    labelId="language-select"
                                    value={locale}
                                    onChange={handleLocaleChange}
                                    disabled={isSaving}
                                >
                                    {Object.keys(languages).map((locale) => (
                                        <MenuItem value={locale} key={locale}>
                                            {languages[locale]?.label || locale}
                                            {!isNew &&
                                                !initialEntity.content[
                                                    locale
                                                ] &&
                                                ' (missed 😢)'}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h5">SEO</Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                label="Meta title"
                                InputLabelProps={{ shrink: true }}
                                value={content[locale]?.metaTitle || ''}
                                onChange={handleChangeEvent(
                                    `content.${locale}.metaTitle`,
                                )}
                                disabled={isSaving}
                                helperText="Leave blank if you are not sure what it is"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                label="Meta description"
                                InputLabelProps={{ shrink: true }}
                                value={content[locale]?.metaDescription || ''}
                                onChange={handleChangeEvent(
                                    `content.${locale}.metaDescription`,
                                )}
                                disabled={isSaving}
                                helperText="Leave blank if you are not sure what it is"
                                multiline
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        {['en', 'ru', 'uk'].map((lang) => {
                            return (
                                <TextField
                                    key={lang}
                                    fullWidth
                                    label={'Title ' + lang}
                                    InputLabelProps={{ shrink: true }}
                                    value={content[lang]?.title || ''}
                                    onChange={(event) =>
                                        handleTitleChange(event, lang)
                                    }
                                    disabled={isSaving}
                                    error={!slug}
                                    helperText={!slug && 'Is required'}
                                />
                            )
                        })}
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            {content[locale]?.title || ''}
                        </Typography>
                        {content[locale]?.createdAt && (
                            <Typography variant="caption">
                                {formatDate(content[locale]?.createdAt)}
                            </Typography>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        {/* {slug && <ContentEditor
                            imagePrefix={`images/forecasts/${slug}`}
                            value={content[locale]?.content || ""}
                            onChange={handleChange(`content.${locale}.content`)}
                        />} */}

                        {['en', 'ru', 'uk'].map((lang) => {
                            return (
                                <EditorComponent
                                    key={lang}
                                    lang={lang}
                                    value={content[lang]?.content || ''}
                                    onChange={(event) =>
                                        handleContentChange(event, lang)
                                    }
                                ></EditorComponent>
                            )
                        })}
                    </Grid>
                </Grid>
            </Container>
        </Paper>
    )
}

type ForecastEditPageProps = {
    match: Match,
}

const ForecastEditPage = withErrorHandler(
    ({ match }: ForecastEditPageProps) => {
        const fetchProps = useAPI<{ forecast: Forecast }>({
            url: `/content/forecasts/${String(match.params.slug)}`,
        })

        return (
            <ReviewsProvider limit={100}>
                <TeamsProvider>
                    <CategoriesProvider entity="forecast">
                        <ForecastPage {...fetchProps} />
                    </CategoriesProvider>
                </TeamsProvider>
            </ReviewsProvider>
        )
    },
)

const ForecastCreatePage = withErrorHandler(() => {
    return (
        <ReviewsProvider limit={100}>
            <TeamsProvider>
                <CategoriesProvider entity="forecast">
                    <ForecastPage
                        data={{ forecast: getEntity({ forecast: {} }) }}
                        isLoading={false}
                        isNew
                    />
                </CategoriesProvider>
            </TeamsProvider>
        </ReviewsProvider>
    )
})

export { ForecastCreatePage, ForecastEditPage }
