// @flow

type Props = {
    url: string,
    body?: Object,
    method: "GET" | "POST" | "PUT" | "PATCH" | "DELETE",
};

const apiCall = async ({ url, body, method }: Props): Promise<Response> => {
    const token = localStorage.getItem("token");
    return fetch(`${String(window.env.REACT_APP_API_URL)}${url}`, {
        ...(body && { body: JSON.stringify(body) }),
        method,
        ...(token && {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        }),
    });
};

export default apiCall;
