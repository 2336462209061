// @flow
import { $getRoot, $getSelection } from 'lexical'
import React, { useState, useCallback, useMemo, useEffect } from 'react'
import { type Match, useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components/macro'
import fpKeyBy from 'lodash/fp/keyBy'
import compose from 'lodash/fp/compose'
import fpMap from 'lodash/fp/map'
import fpPick from 'lodash/fp/pick'
import keyBy from 'lodash/keyBy'
import mapValues from 'lodash/mapValues'
import { DateTimePicker } from '@material-ui/pickers'

import '../../shared/components/ContentEditor/styles.css'

import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import RefreshIcon from '@material-ui/icons/Refresh'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import CircularProgress from '@material-ui/core/CircularProgress'
import Input from '@material-ui/core/Input'
import Checkbox from '@material-ui/core/Checkbox'
import ListItemText from '@material-ui/core/ListItemText'

import formatDate from '../../utils/formatDate'
import Loader from '../../shared/components/Loader'
import Editor from '../../shared/components/ContentEditor'
import useAPI from '../../shared/hooks/useAPI'
import { type Post } from './context/posts'
import { type Config, useConfig } from '../context/config'
import withErrorHandler from '../../shared/hocs/withErrorHandler'
import ConfirmationModal from '../../shared/components/modals/ConfirmationModal'
import {
    useCategories,
    Provider as CategoriesProvider,
} from '../context/categories'
import useCRUD from '../../shared/hooks/useCRUD'
import ImageUpload from '../../shared/components/ImageUpload'
import EditorComponent from '../../shared/components/Editor'
import stateToHTML from '../../utils/stateToHTML'
import Sticky from 'react-sticky-el'
import NotFound from '../../shared/components/NotFound'
import type { RefetchType } from '../../shared/hooks/useAPI'
import StatusSelect from '../../shared/components/StatusSelect'
import { globalLanguages } from '../../contants'
import getSlug from '../../utils/getSlug'

const Container = styled.div`
    padding: 15px;
`

const ToolbarContainer = styled.div`
    > * {
        margin-right: 5px;
    }
`

type Props = {
    isNew?: boolean,
    data: { post: ?Post },
    isLoading: boolean,
    refetch?: RefetchType,
}

const languages = globalLanguages

const stickyStyle = {
    backgroundColor: 'white',
    paddingBottom: 15,
    paddingTop: 77,
    zIndex: 2,
}

const getEntity = ({ post, config }: { post: ?Post, config: ?Config }) =>
    post
        ? {
              author: post.author,
              commentStatus: post.commentStatus || 'opened',
              content:
                  post.content ||
                  compose(
                      fpKeyBy('locale'),
                      fpMap(
                          fpPick(['locale', 'title', 'content', 'createdAt']),
                      ),
                  )(post.mainContent),
              images: mapValues(
                  post.images,
                  (url) => `https://${config.filesBucketName}${url}`,
              ),
              postDate: post.postDate && new Date(post.postDate),
              slug: post.slug,
              status: post.status || 'created',
              tags: post.tags || [],
              type: post.type || 'post',
              videoUrl: post.videoUrl || '',
          }
        : null

const youtubeEmbedUrlRegex = /https:\/\/www\.youtube\.com\/embed\/(.+)/

const prepareUpdateBody = ({ content, images, postDate, ...post }) => {
    Object.keys(content).forEach((key) => {
        const html = document.createElement('div')
        html.innerHTML = content[key].content
        if (html.querySelector('[data-f-id]')) {
            html.querySelector('[data-f-id]').remove()
        }
        content[key].content = html.innerHTML
    })

    return {
        content: mapValues(content, ({ createdAt, ...localeContent }) => ({
            ...localeContent,
            content: !localeContent.content ? '' : localeContent.content,
        })),
        post: {
            ...post,
            images: mapValues(images, (url) => new URL(url).pathname),
            postDate: (postDate || new Date()).toISOString(),
            videoUrl: post.videoUrl || null,
        },
    }
}

const PostPage = ({ data, isLoading, refetch, isNew = false }: Props) => {
    const { config } = useConfig()
    const { categories, isLoading: isCategoriesLoading } = useCategories()
    const post = data?.post

    const { search } = useLocation()

    const initialEntity = useMemo(() => getEntity({ config, post }), [
        config,
        post,
    ])

    const {
        entity,
        handleChangeEvent,
        handleChange,
        handleMultipleChange,
        isChanged,
        save,
        reset,
        isLoading: isSaving,
    } = useCRUD({
        entity: initialEntity,
        prepareBody: prepareUpdateBody,
        saveMethod: isNew ? 'POST' : 'PUT',
        url: `/content/posts${isNew ? '' : `/${String(post?.id)}`}`,
    })

    const localStorageKey = isNew ? `post_new` : entity?.slug

    const contentLocale = post?.mainContent?.[0]?.locale

    const { slug, content, videoUrl } = entity || {}

    useEffect(() => {
        if (isChanged) {
            localStorage.setItem(
                localStorageKey,
                JSON.stringify({ ...post, ...entity }),
            )
        }
    }, [entity, content, post, isChanged])

    const urlLocale = useMemo(() => {
        let locale = new URLSearchParams(search).get('locale')

        return Object.keys(languages).includes(locale) ? locale : null
    }, [search])

    const [locale, setLocale] = useState(urlLocale || contentLocale || 'ru')

    useEffect(() => {
        if (contentLocale && !urlLocale) {
            setLocale(contentLocale)
        }
    }, [contentLocale, urlLocale])

    const handleLocaleChange = useCallback(
        (e: SyntheticInputEvent<HTMLSelectElement>) => {
            const contentText = content[locale]?.content?.getCurrentContent?.()

            if (typeof contentText !== 'undefined') {
                const content = stateToHTML(contentText)
                handleChange(`content[${locale}].content`)(content)
            }

            window.history.replaceState(
                {},
                null,
                `/posts/${isNew ? 'new' : slug}?locale=${e.target.value}`,
            )
            setLocale(e.target.value)
        },
        [content, handleChange, isNew, locale, slug],
    )

    const history = useHistory()

    const goBack = useCallback(() => {
        history.push('/posts')
    }, [history])

    const handleSave = useCallback(async () => {
        await save()
        history.push(`/posts/${slug}?locale=${locale}`)
    }, [save, history, slug, locale])

    const handleTitleChange = useCallback(
        async (e, lang) => {
            const title = e.target.value.slice(0, 156)

            if (!isNew) {
                handleChange(`content.${lang}.title`)(title)
                return
            }

            let slug

            if (lang === 'ru' && !title) {
                slug = getSlug(content['uk']?.title || '')
            }

            if ((lang === 'ru' && title) || !content['ru']?.title) {
                slug = getSlug(title)
            }

            if (slug) {
                handleMultipleChange({
                    slug,
                    [`content.${lang}.title`]: title,
                })
                return
            }

            handleChange(`content.${lang}.title`)(title)
        },
        [content, handleChange, handleMultipleChange, isNew],
    )

    const handleContentChange = useCallback(
        async (e, lang) => {
            let modifiedContentData = entity
            modifiedContentData.content[lang].content = e

            localStorage.setItem(
                localStorageKey,
                JSON.stringify(modifiedContentData),
            )

            handleChange(`content.${lang}.content`)
        },
        [content, handleChange],
    )

    const handleReload = useCallback(() => refetch && refetch(), [refetch])

    const categoriesById = keyBy(categories, 'id')

    const handleCategoriesChange = useCallback(
        (e) => {
            const selectedCategoryIds = []
            const queue = e.target.value

            while (queue.length !== 0) {
                const categoryId = queue.shift()
                const category = categoriesById[categoryId]

                if (!category) {
                    continue
                }
                selectedCategoryIds.push(categoryId)

                if (!category.parentCategoryId) {
                    continue
                }
                queue.push(
                    ...(categories || [])
                        .filter(({ id }) => id === category.parentCategoryId)
                        .map(({ id }) => id),
                )
            }

            handleChange('tags')([...new Set(selectedCategoryIds)])
        },
        [categories, categoriesById, handleChange],
    )

    const isVideoUrlError = useMemo(
        () => Boolean(videoUrl) && !youtubeEmbedUrlRegex.test(videoUrl),
        [videoUrl],
    )

    if (isLoading || isCategoriesLoading) {
        return <Loader isFullHeight />
    }

    if (!post || !entity) {
        return <NotFound name="Posts" url="/posts" />
    }

    return (
        <Paper>
            <Container>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Sticky stickyStyle={stickyStyle}>
                            <ToolbarContainer>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    onClick={isNew ? handleSave : save}
                                    endIcon={
                                        isSaving && (
                                            <CircularProgress size={20} />
                                        )
                                    }
                                >
                                    Save
                                </Button>
                                <Button
                                    size="large"
                                    color="secondary"
                                    onClick={isNew ? goBack : reset}
                                    disabled={
                                        !isNew && (!isChanged || isSaving)
                                    }
                                >
                                    Cancel
                                </Button>
                                {refetch && (
                                    <Tooltip title="Reload post (all changes will be cancelled)">
                                        <IconButton
                                            color="primary"
                                            onClick={handleReload}
                                        >
                                            <RefreshIcon />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </ToolbarContainer>
                        </Sticky>
                        <Typography variant="h5">{slug}</Typography>
                        {entity.createdAt && (
                            <Typography variant="caption">
                                {formatDate(entity.createdAt)}
                                {entity.author &&
                                    ` by ${
                                        [
                                            entity.author.firstName,
                                            entity.author.lastName,
                                        ].join(' ') || 'Unknown'
                                    }`}
                            </Typography>
                        )}
                    </Grid>
                    {categories && (
                        <Grid item xs={12} md={6} lg={3}>
                            <FormControl fullWidth disabled={isSaving}>
                                <InputLabel id="categories-select-label" shrink>
                                    Categories
                                </InputLabel>
                                <Select
                                    labelId="categories-select-label"
                                    multiple
                                    onChange={handleCategoriesChange}
                                    input={<Input />}
                                    value={entity.tags}
                                    renderValue={(selectedValues) =>
                                        selectedValues
                                            .map(
                                                (id) =>
                                                    categoriesById[id]?.slug,
                                            )
                                            .join(', ')
                                    }
                                >
                                    {categories.map(({ id, slug }) => (
                                        <MenuItem key={id} value={id}>
                                            <Checkbox
                                                checked={entity.tags.includes(
                                                    id,
                                                )}
                                            />
                                            <ListItemText primary={slug} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    )}
                    <Grid item xs={12} md={6} lg={3}>
                        <StatusSelect
                            value={entity.status}
                            onChange={handleChangeEvent('status')}
                            isDisabled={isSaving}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <FormControl fullWidth>
                            <InputLabel id="commentStatus-select" shrink>
                                Comment status
                            </InputLabel>
                            <Select
                                labelId="commentStatus-select"
                                value={entity.commentStatus}
                                onChange={handleChangeEvent('commentStatus')}
                                disabled={isSaving}
                            >
                                {['opened', 'closed'].map((status) => (
                                    <MenuItem value={status} key={status}>
                                        {status}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <DateTimePicker
                            fullWidth
                            autoOk
                            ampm={false}
                            disablePast
                            value={entity.postDate}
                            onChange={handleChange('postDate')}
                            label="Post date"
                            disabled={isSaving}
                            format="dd.MM.yyyy HH:mm"
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            value={entity.videoUrl}
                            fullWidth
                            helperText={
                                isVideoUrlError
                                    ? 'Invalid embed url (https://www.youtube.com/embed/xxxxx)'
                                    : 'Used in preview (https://www.youtube.com/embed/xxxxx)'
                            }
                            label="Video URL"
                            error={isVideoUrlError}
                            onChange={handleChangeEvent('videoUrl')}
                            disabled={isSaving}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="caption" color="textSecondary">
                            Image
                        </Typography>
                    </Grid>
                    <ImageUpload
                        prefix={`images/posts/${slug}`}
                        value={entity.images.main}
                        onChange={handleChange('images.main')}
                        disabled={!slug || isSaving}
                        errorMessage={!slug ? 'Enter title first' : undefined}
                    />

                    {/* <Grid item xs={12}>
                        <ImageUpload
                            prefix={`images/posts/${slug}`}
                            value={entity.images.url}
                            onChange={handleChange("images.url")}
                            disabled={!slug || isSaving}
                            errorMessage={!slug ? "Enter title first" : undefined}
                        />
                        <TextField
                            fullWidth
                            label="Width"
                            value={entity.images.width || ""}
                            onChange={handleChange("images.width")}
                            disabled={!slug || isSaving}
                        />

                    </Grid> */}

                    <Grid item xs={12}>
                        {['en', 'ru', 'uk'].map((lang) => {
                            return (
                                <TextField
                                    key={lang}
                                    style={{ marginBottom: '20px' }}
                                    fullWidth
                                    label={'Title ' + lang}
                                    InputLabelProps={{ shrink: true }}
                                    value={content[lang]?.title || ''}
                                    onChange={(event) =>
                                        handleTitleChange(event, lang)
                                    }
                                    disabled={isSaving}
                                    error={!slug}
                                    helperText={!slug && 'Is required'}
                                />
                            )
                        })}
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            {content[locale]?.title || ''}
                        </Typography>
                        {content[locale]?.createdAt && (
                            <Typography variant="caption">
                                {formatDate(content[locale]?.createdAt)}
                            </Typography>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        {['en', 'ru', 'uk'].map((lang) => {
                            return (
                                <EditorComponent
                                    key={lang}
                                    lang={lang}
                                    value={content[lang]?.content || ''}
                                    onChange={(event) =>
                                        handleContentChange(event, lang)
                                    }
                                ></EditorComponent>
                            )
                        })}
                        {/* {slug && <ContentEditor
                            imagePrefix={`images/posts/${slug}`}
                            value={content[locale]?.content || ""}
                            onChange={handleContentChange}
                        />} */}

                        {/* handleChange(`content.${locale}.content`)} */}
                    </Grid>
                </Grid>
            </Container>
        </Paper>
    )
}

type PostEditPageProps = {
    match: Match,
}

const PostEditPage = withErrorHandler(({ match }: PostEditPageProps) => {
    const getLocalStorageData =
        JSON.parse(localStorage.getItem(match.params.slug)) || {}

    const isData =
        Object.keys(getLocalStorageData).length &&
        Object.keys(
            Object.values(getLocalStorageData)[1] ||
                Object.values(getLocalStorageData)[2] ||
                Object.values(getLocalStorageData)[3] ||
                Object.values(getLocalStorageData)[5] ||
                Object.values(getLocalStorageData)[7],
        ).length

    const [postData, setPostData] = useState(getLocalStorageData || {})
    const [showRestoreConfirmation, setShowRestoreConfirmation] = useState(true)
    const [isRestoreConfirmation, setIsRestoreConfirmation] = useState(false)

    const handleRestoreCancel = useCallback(() => {
        setShowRestoreConfirmation(false)
        setPostData({})

        window.localStorage.removeItem(match.params.slug)
    }, [])

    const handleRestoreConfirm = useCallback(() => {
        setShowRestoreConfirmation(false)
        setPostData(getLocalStorageData)
        setIsRestoreConfirmation(true)

        window.localStorage.removeItem(match.params.slug)
    }, [])

    const fetchProps = useAPI<{ post: Post }>({
        url: `/content/posts/${String(match.params.slug)}`,
    })

    if (isRestoreConfirmation) {
        fetchProps.data.post = postData
    }

    return (
        <CategoriesProvider entity="post">
            {isData !== 0 && (
                <ConfirmationModal
                    title={`You want to restore unsaved data?`}
                    isOpen={showRestoreConfirmation}
                    onCancel={handleRestoreCancel}
                    onConfirm={handleRestoreConfirm}
                    isLoading={false}
                />
            )}

            <PostPage {...fetchProps} />
        </CategoriesProvider>
    )
})

const PostCreatePage = withErrorHandler(() => {
    const getLocalStorageData =
        JSON.parse(localStorage.getItem('post_new')) || {}

    const isData =
        Object.keys(getLocalStorageData).length &&
        Object.keys(
            Object.values(getLocalStorageData)[1] ||
                Object.values(getLocalStorageData)[2] ||
                Object.values(getLocalStorageData)[3] ||
                Object.values(getLocalStorageData)[5] ||
                Object.values(getLocalStorageData)[7],
        ).length

    const [postData, setPostData] = useState(getLocalStorageData || {})
    const [showRestoreConfirmation, setShowRestoreConfirmation] = useState(true)

    const handleRestoreCancel = useCallback(() => {
        setShowRestoreConfirmation(false)
        setPostData({})

        window.localStorage.removeItem('post_new')
    }, [])

    const handleRestoreConfirm = useCallback(() => {
        setShowRestoreConfirmation(false)
        setPostData(getLocalStorageData)

        window.localStorage.removeItem('post_new')
    }, [])

    return (
        <CategoriesProvider entity="post">
            {isData !== 0 && (
                <ConfirmationModal
                    title={`You want to restore unsaved data?`}
                    isOpen={showRestoreConfirmation}
                    onCancel={handleRestoreCancel}
                    onConfirm={handleRestoreConfirm}
                    isLoading={false}
                />
            )}

            <PostPage
                data={{
                    post: getEntity({ post: postData }),
                }}
                isLoading={false}
                isNew
            />
        </CategoriesProvider>
    )
})

export { PostCreatePage, PostEditPage }
