import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const NotFoundIcon = (props) => (
    <SvgIcon {...props} viewBox="0 -40 472 472">
        <path d="m472 92.199219v-84.65625c0-4.417969-3.585938-7.542969-8-7.542969h-456c-4.417969 0-8 3.125-8 7.542969v376.914062c0 4.417969 3.582031 7.542969 8 7.542969h456c4.417969 0 8-3.125 8-7.542969v-292.105469c0-.027343 0-.050781 0-.078124 0-.027344 0-.050782 0-.074219zm-16-76.199219v68h-440v-68zm-440 360v-276h440v276zm0 0" />
        <path d="m103.308594 75.144531c13.402344 0 24.308594-11.261719 24.308594-25.105469s-10.902344-25.101562-24.308594-25.101562-24.308594 11.261719-24.308594 25.101562c0 13.835938 10.90625 25.105469 24.308594 25.105469zm0-34.210937c4.582031 0 8.308594 4.082031 8.308594 9.097656 0 5.019531-3.726563 9.109375-8.308594 9.109375-4.578125 0-8.308594-4.082031-8.308594-9.101563 0-5.023437 3.730469-9.105468 8.308594-9.105468zm0 0" />
        <path d="m156.300781 75.144531c13.398438 0 24.304688-11.261719 24.304688-25.105469s-10.898438-25.101562-24.304688-25.101562-24.300781 11.261719-24.300781 25.101562c0 13.835938 10.898438 25.105469 24.300781 25.105469zm0-34.210937c4.578125 0 8.304688 4.082031 8.304688 9.097656 0 5.019531-3.726563 9.109375-8.304688 9.109375-4.582031 0-8.300781-4.082031-8.300781-9.101563 0-5.023437 3.722656-9.105468 8.300781-9.105468zm0 0" />
        <path d="m50.316406 75.144531c13.402344 0 24.304688-11.261719 24.304688-25.105469s-10.898438-25.101562-24.304688-25.101562-24.304687 11.261719-24.304687 25.101562c0 13.835938 10.902343 25.105469 24.304687 25.105469zm0-34.210937c4.582032 0 8.304688 4.082031 8.304688 9.097656 0 5.019531-3.722656 9.109375-8.304688 9.109375-4.578125 0-8.304687-4.085937-8.304687-9.109375 0-5.019531 3.726562-9.097656 8.304687-9.097656zm0 0" />
        <path d="m193.152344 234h-4.152344v-7.378906c0-3.296875-4.101562-4.699219-8-4.699219s-8 1.402344-8 4.699219v7.378906h-8.347656l17.699218-35.691406c.296876-.625.464844-1.304688.5-2 0-3.597656-5.101562-5.945313-7.800781-5.945313-2.761719-.074219-5.308593 1.484375-6.5 3.976563l-23.101562 45.292968c-.507813.910157-.78125 1.933594-.796875 2.980469-.121094 1.429688.382812 2.839844 1.378906 3.871094s2.386719 1.582031 3.820312 1.511719h23.148438v10.625c0 3.199218 4.101562 4.800781 8 4.800781s8-1.601563 8-4.800781v-10.621094h4.152344c2.398437 0 4.800781-3.398438 4.800781-7s-1.601563-7-4.800781-7zm0 0" />
        <path d="m236.449219 190.320312c-13.800781 0-24.449219 6.398438-24.449219 23.601563v26.5c0 17.199219 10.648438 23.597656 24.449219 23.597656s24.550781-6.398437 24.550781-23.597656v-26.5c0-17.199219-10.75-23.601563-24.550781-23.601563zm8.550781 50.101563c0 6.898437-3.449219 10-9.050781 10-5.597657 0-8.949219-3.101563-8.949219-10v-26.5c0-6.902344 3.351562-10 8.949219-10 5.601562 0 9.050781 3.097656 9.050781 10zm0 0" />
        <path d="m322.550781 234h-4.550781v-7.378906c0-3.296875-3.601562-4.699219-7.5-4.699219s-7.5 1.402344-7.5 4.699219v7.378906h-8.949219l17.699219-35.691406c.292969-.625.464844-1.304688.5-2 0-3.597656-5.101562-5.945313-7.800781-5.945313-2.761719-.074219-5.308594 1.484375-6.5 3.976563l-23.101563 45.292968c-.507812.910157-.78125 1.933594-.796875 2.980469-.121093 1.429688.382813 2.839844 1.378907 3.871094.996093 1.03125 2.386718 1.582031 3.820312 1.511719h23.75v10.625c0 3.199218 3.601562 4.800781 7.5 4.800781s7.5-1.601563 7.5-4.800781v-10.621094h4.550781c2.398438 0 4.800781-3.398438 4.800781-7s-1.601562-7-4.800781-7zm0 0" />
    </SvgIcon>
);

export default NotFoundIcon;
