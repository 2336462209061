// @flow

import React from "react";

import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";

type Props = {
    value: string,
    onChange?: Function,
    isDisabled?: boolean,
    options?: string[],
};

const StatusSelect = ({
    value,
    onChange,
    isDisabled,
    options = ["published", "created"],
}: Props = {}) => (
    <FormControl fullWidth>
        <InputLabel id="status-select" shrink>
            Status
        </InputLabel>
        <Select
            labelId="status-select"
            value={value}
            onChange={onChange}
            disabled={isDisabled}
        >
            {options.map((status) => (
                <MenuItem value={status} key={status}>
                    {status}
                </MenuItem>
            ))}
        </Select>
    </FormControl>
);

export default StatusSelect;
