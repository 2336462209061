// @flow

import React, { createContext, useCallback, useContext } from "react";
import noop from "lodash/noop";
import useAPI, { type PaginationProps } from "../../../shared/hooks/useAPI";
import type { Locale } from "../../Posts/context/posts";
import apiCall from "../../../utils/apiCall";

export type ReviewContent = {
    locale: Locale,
    title: string,
    description: string,
    metaTitle: string,
    metaDescription: string,
    content: string,
    createdAt: string,
    updated: string,
    cons: string[],
    props: string[],
};

export type User = {
    id: string,
    firstName: string,
    lastName: string,
    avatarUrl?: string,
};

export type ApplicationType = "android" | "ios" | "windows";

export type Application = {
    type: ApplicationType,
    link: string,
};

export type RatingType =
    | "reliability"
    | "bonuses"
    | "coefficients"
    | "withdrawal"
    | "bets_variety"
    | "support";

export type Rating = {
    type: RatingType,
    value: number,
};

export type Bonus = {
    link?: string,
    amount?: number,
    currency: "UAH",
};

export type License = {
    number?: string,
    issueDate?: string,
    issuedBy?: string,
    expireDate?: string,
};

export type LegalInfo = {
    name?: string,
    address?: string,
    ITN?: string,
    USREOU?: string,
};

export type Support = {
    phone?: string,
    email?: string,
};

export type Payment = {
    paymentId: string,
    withdrawal: {|
        commission: string,
        sumFrom?: number,
        sumTo?: number,
        sumCurrency?: "UAH" | "USD" | "EUR",
        periodFrom: number,
        periodTo: number,
        periodType: string,
    |},
    replenish: {|
        commission: string,
        sumFrom?: number,
        sumTo?: number,
        sumCurrency?: "UAH" | "USD" | "EUR",
        periodFrom: number,
        periodTo: number,
        periodType: string,
    |},
};

export type Review = {
    id: string,
    reviewDate: string,
    slug: string,
    status: "published" | "created",
    type: "casino" | "bookmaker",
    tags: string[],
    images: {
        logo: string,
    },
    websiteLink: string,
    mainContent: ReviewContent,
    author: User,
    ratings: Rating[],
    applications: Application[],
    bonus: Bonus,
    license?: License,
    legalInfo?: LegalInfo,
    support?: Support,
    payments?: Payment[],
    title: string,
    rating: number,
    order: number,
};

type ReviewsContext = {
    authors: ?(User[]),
    count: number,
    deleteReview: (Review) => Promise<void>,
    reviews: ?(Review[]),
    error: ?Error,
    isLoading: boolean,
    refetch: (PaginationProps) => Promise<void>,
};

const Context = createContext<ReviewsContext>({
    authors: null,
    count: 0,
    deleteReview: noop,
    error: null,
    isLoading: false,
    refetch: noop,
    reviews: null,
});

export const ReviewsProvider = ({
    children,
    limit,
    status,
}: {
    children: *,
    limit?: number,
    status: *,
}) => {
    const { data, isLoading, error, refetch } = useAPI<{
        count: number,
        list: Review[],
    }>({
        orderBy: "createdAt",
        orderDirection: "DESC",
        url: `/content/reviews/all${status ? `?status=${status}` : ""}`,
        ...(limit && {
            limit,
        }),
    });

    const { data: authors, isLoading: isAuthorsLoading } = useAPI<{
        list: User[],
    }>({
        url: "/content/reviews/authors",
    });

    const deleteReview = useCallback(
        async ({ slug }: Review) => {
            await apiCall({
                method: "DELETE",
                url: `/content/reviews/${slug}`,
            });

            refetch({});
        },
        [refetch]
    );

    return (
        <Context.Provider
            value={{
                authors: authors?.list,
                count: data?.count || 0,
                deleteReview,
                error,
                isLoading: isLoading || isAuthorsLoading,
                refetch,
                reviews: data?.list,
            }}
        >
            {children}
        </Context.Provider>
    );
};

const useReviews = () => useContext<ReviewsContext>(Context);

export { ReviewsProvider as Provider, useReviews };
