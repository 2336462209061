// @flow

import React, { useCallback, useState, useEffect } from 'react'
import styled from 'styled-components/macro'
import { Img } from 'react-image'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { CircularProgress } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'

import FileUpload from '../FileUpload'
import uploadFile from '../../../utils/uploadFile'
import { useConfig } from '../../../modules/context/config'

const ImageContainer = styled.div`
    > img {
        max-width: 100%;
        width: ${({ styles }) => styles?.width || 317}px;
        border-radius: ${({ styles }) =>
            styles?.borderRadius || Number.isFinite(styles?.borderRadius)
                ? styles?.borderRadius
                : 16}px;
    }
`

const LoaderContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    width: ${({ styles }) => styles?.width || 317}px;
    height: ${({ styles }) => styles?.height || 317}px;
    border-radius: ${({ styles }) =>
        styles?.borderRadius || Number.isFinite(styles?.borderRadius)
            ? styles?.borderRadius
            : 16}px;
    background-color: lightgrey;
    padding: 5px;
`

type Props = {
    onChange?: (string) => Promise<void> | void,
    value?: string,
    prefix?: string,
    disabled?: boolean,
    errorMessage?: string,
    styles?: {
        width?: number,
        height?: number,
        borderRadius?: number,
    },
    name?: string,
}

const ImageUpload = ({
    onChange,
    value,
    prefix,
    disabled,
    errorMessage,
    styles,
    name,
}: Props) => {
    const [error, setError] = useState(errorMessage)
    const [image, setImage] = useState(value)
    const [isLoading, setIsLoading] = useState(false)
    const { config } = useConfig()

    useEffect(() => {
        setImage(value)
    }, [value])

    useEffect(() => {
        setError(errorMessage)
    }, [errorMessage])

    const handleImageUpload = useCallback(
        async ({ body, type }) => {
            try {
                if (!onChange) {
                    setImage(body)
                    return
                }

                setIsLoading(true)

                const { url } = await uploadFile({
                    file: body,
                    filesBucketName: config.filesBucketName,
                    name:
                        name ||
                        `${prefix ? `${prefix}/` : ''}image-${Date.now()}`,
                    type,
                })
                setImage(url)
                onChange(url)
            } finally {
                setIsLoading(false)
            }
        },
        [config.filesBucketName, name, onChange, prefix],
    )

    if (!config) {
        return
    }

    return (
        <Grid item container xs={12} spacing={2}>
            <Grid item>
                {isLoading ? (
                    <LoaderContainer styles={styles}>
                        <CircularProgress size={30} />
                    </LoaderContainer>
                ) : image ? (
                    <Img
                        src={image}
                        unloader={
                            <LoaderContainer styles={styles}>
                                Image is broken or missed
                            </LoaderContainer>
                        }
                        container={(children) => (
                            <ImageContainer styles={styles}>
                                {children}
                            </ImageContainer>
                        )}
                    />
                ) : (
                    <LoaderContainer styles={styles}>No image</LoaderContainer>
                )}
            </Grid>
            <Grid item>
                <FileUpload
                    onChange={handleImageUpload}
                    accept="image/*"
                    onError={setError}
                >
                    {({ upload }) => (
                        <>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={upload}
                                disabled={disabled}
                            >
                                Upload
                            </Button>
                            {error && (
                                <div>
                                    <Typography color="error" variant="caption">
                                        {error}
                                    </Typography>
                                </div>
                            )}
                        </>
                    )}
                </FileUpload>
            </Grid>
        </Grid>
    )
}

export default ImageUpload
