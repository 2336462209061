// @flow

import styled from "styled-components/macro";
import React from "react";
import { Link } from "react-router-dom";
import pluralize from "pluralize";

import Typography from "@material-ui/core/Typography";

import NotFoundIcon from "../../icons/NotFoundIcon";

const NotFoundContainer = styled.div`
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    svg {
        margin: 15px;
    }

    > *:first-child {
        display: flex;
        align-items: center;
    }
`;

type Props = {
    url?: string,
    name: string,
};

const NotFound = ({ url, name }: Props) => (
    <NotFoundContainer>
        <Typography variant="h6">
            <NotFoundIcon fontSize="large" /> {pluralize.singular(name)} not
            found!
        </Typography>
        {url && (
            <Typography>
                See all <Link to={url}>{name}</Link>
            </Typography>
        )}
    </NotFoundContainer>
);

export default NotFound;
