// @flow

import React, { useCallback, useState } from "react";
import { DebounceInput } from "react-debounce-input";
import difference from "lodash/difference";

import Paper from "@material-ui/core/Paper";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import Checkbox from "@material-ui/core/Checkbox";

import SearchIcon from "@material-ui/icons/Search";

import { useUsers, Provider } from "./context/users";
import { useRoles, Provider as RolesProvider } from "./context/roles";
import Table, {
    type Column,
    dataTypes,
} from "../../shared/components/SimpleTable";
import SectionToolbar from "../../shared/components/SectionToolbar";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import { useCurrentUser } from "../Auth/context/currentUser";
import Loader from "../../shared/components/Loader";
import apiCall from "../../utils/apiCall";

const UsersActions = ({ entity }: { entity: * }) => {
    const { currentUser } = useCurrentUser();
    const { roles: userRoles } = useRoles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [oldRoles, setOldRoles] = useState(entity.roles);
    const [roles, setRoles] = useState(entity.roles);

    const handleClick = useCallback(
        (event) => {
            setAnchorEl(event.currentTarget);
        },
        [setAnchorEl]
    );

    const handleClose = useCallback(() => {
        const grantedRoles = difference(roles, oldRoles);

        if (grantedRoles.length) {
            apiCall({
                body: { roles: grantedRoles },
                method: "POST",
                url: `/users/${entity.id}/grant-roles`,
            });
        }

        const revokedRoles = difference(oldRoles, roles);

        if (revokedRoles.length) {
            apiCall({
                body: { roles: revokedRoles },
                method: "POST",
                url: `/users/${entity.id}/revoke-roles`,
            });
        }

        if (revokedRoles.length || grantedRoles.length)
            alert(
                `Roles were successfully updated!\nGranted roles: ${
                    grantedRoles.join(", ") || "-"
                }\nRevoked roles: ${revokedRoles.join(", ") || "-"}`
            );

        setOldRoles(roles);
        setAnchorEl(null);
    }, [entity.id, oldRoles, roles]);

    const handleRolesChange = useCallback((e) => {
        setRoles(e.target.value);
    }, []);

    return (
        currentUser?.roles?.includes("superAdmin") && (
            <div>
                <Button size="small" color="primary" onClick={handleClick}>
                    Change roles
                </Button>

                {Boolean(anchorEl) && (
                    <Select
                        open={Boolean(anchorEl)}
                        labelId={`user-${entity.id}-roles-select`}
                        multiple
                        value={roles}
                        input={<Input />}
                        renderValue={(selected) => selected.join(", ")}
                        onClose={handleClose}
                        onChange={handleRolesChange}
                    >
                        {!userRoles ? (
                            <Loader />
                        ) : (
                            userRoles.map((role) => (
                                <MenuItem key={role} value={role}>
                                    <Checkbox checked={roles.includes(role)} />
                                    <ListItemText primary={role} />
                                </MenuItem>
                            ))
                        )}
                    </Select>
                )}
            </div>
        )
    );
};

const columns: Column[] = [
    {
        label: "Id",
        name: "id",
    },
    {
        label: "Account type",
        name: "accountType",
    },
    {
        label: "Email",
        name: "email",
    },
    {
        dataType: dataTypes.BOOLEAN,
        label: "Is verified",
        name: "isVerified",
    },
    {
        label: "Social email",
        name: "socialEmail",
    },
    {
        label: "Name",
        name: "name",
    },
    {
        label: "First Name",
        name: "firstName",
    },
    {
        label: "Last name",
        name: "lastName",
    },
    {
        formatter: ({ roles }) => roles.join(", "),
        isSortable: false,
        label: "Roles",
        name: "roles",
    },
    {
        label: "Country",
        name: "countryCode",
    },
    {
        dataType: dataTypes.DATE_TIME,
        label: "Created At",
        name: "createdAt",
    },
];

const UsersSection = () => {
    const { users, count, isLoading, refetch } = useUsers();

    const handleSearch = useCallback(
        (e) =>
            refetch({
                searchValue: e.target.value || undefined,
            }),
        [refetch]
    );

    return (
        <Paper>
            <SectionToolbar
                title={<>Users {!isLoading && <>({count})</>}</>}
                refetch={refetch}
            >
                <TextField
                    label="Search"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                        inputComponent: DebounceInput,
                        inputProps: { debounceTimeout: 500 },
                        onChange: handleSearch,
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </SectionToolbar>
            <Table
                columns={columns}
                data={users}
                count={count}
                refetch={refetch}
                isLoading={isLoading}
                actionsComponent={UsersActions}
            />
        </Paper>
    );
};

export default () => (
    <Provider>
        <RolesProvider>
            <UsersSection />
        </RolesProvider>
    </Provider>
);
