/* eslint-disable sort-keys */
// import React from 'react'
import FroalaEditor from 'react-froala-wysiwyg'
import 'froala-editor/css/froala_style.min.css'
import 'froala-editor/css/froala_editor.pkgd.min.css'
import 'froala-editor/js/plugins.pkgd.min.js'

import React from 'react'
import uploadFile from '../../../utils/uploadFile'
import { configs } from 'eslint-plugin-prettier'
class EditorComponent extends React.Component {
    config = {
        imageUploadURL: `${window.env.REACT_APP_API_URL}/files/editor`,
        imageUploadParams: {
            isPrivate: false,
        },
        linkAlwaysNoFollow: false,
        linkAttributes: {
            rel: 'rel',
        },
        linkNoOpener: false,
        linkNoReferrer: false,
        requestHeaders: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        toolbarButtons: [
            'undo',
            'redo',
            'alignLeft',
            'alignCenter',
            'formatOLSimple',
            'alignRight',
            'alignJustify',
            'subscript',
            'superscript',
            'fontSize',
            'bold',
            'italic',
            'underline',
            'strikeThrough',
            'clearFormatting',
            'formatOL',
            'formatUL',
            'paragraphFormat',
            'paragraphStyle',
            'lineHeight',
            'outdent',
            'indent',
            'quote',
            'insertLink',
            'insertImage',
            'insertVideo',
            'insertTable',
        ],

        // eslint-disable-next-line sort-keys
        events: {
            'image.beforeUpload': function (files) {
                let editor = this
                if (files.length) {
                    var file = files[0]
                    const reader = new FileReader()
                    reader.onload = function (e) {
                        var base4Str = e.target.result

                        editor.image.insert(
                            base4Str,
                            null,
                            null,
                            editor.image.get(),
                        )
                    }

                    reader.readAsDataURL(files[0])
                }
                editor.popups.hideAll()
                return false
            },
        },
        // events: {
        //     'image.beforeUpload': function (images) {
        //         console.log(this, images)
        //         for (let i = 0; i < images; i++) {
        //             const file = images[i]
        //             console.log({ file })
        //         }
        //         uploadFile({
        //             file: images[0],
        //             name: images[0].name,
        //             type: images[0].mimetype,
        //             filesBucketName: config.imageUploadParams.name,
        //             isPrivate: false,
        //         }).then((result) => {
        //             console.log({ result })
        //         })
        //         return false
        //     },
        // },
    }
    model = ''
    constructor(props) {
        super()
        this.handleModelChange = this.handleModelChange.bind(this)
    }

    handleModelChange(model) {
        this.props.onChange(model)
    }

    render() {
        return (
            <div>
                <h3>Content {this.props.lang}</h3>
                <FroalaEditor
                    config={this.config}
                    model={this.props.value}
                    onModelChange={this.handleModelChange}
                />
            </div>
        )
    }
}

export default EditorComponent
