import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const WindowsIcon = (props) => (
    <SvgIcon {...props} viewBox="0 0 512 512">
        <path
            d="M201.302 49.754l-192 24.939A10.667 10.667 0 000 85.36v149.333c0 5.891 4.776 10.667 10.667 10.667h192c5.891 0 10.667-4.776 10.667-10.667V60.314a10.666 10.666 0 00-3.627-8 10.906 10.906 0 00-8.405-2.56z"
            fill="#f44336"
        />
        <path
            d="M508.374 13.36a10.665 10.665 0 00-8.405-2.667l-256 33.387a10.666 10.666 0 00-9.301 10.667v179.947c0 5.891 4.776 10.667 10.667 10.667h256c5.891 0 10.667-4.776 10.667-10.667V21.36a10.674 10.674 0 00-3.628-8z"
            fill="#4caf50"
        />
        <path
            d="M202.667 266.693h-192C4.776 266.693 0 271.469 0 277.36v149.333a10.667 10.667 0 009.301 10.667l192 25.045c.455.031.911.031 1.365 0 5.891 0 10.667-4.776 10.667-10.667V277.36c.001-5.891-4.775-10.667-10.666-10.667z"
            fill="#2196f3"
        />
        <path
            d="M501.334 266.693h-256c-5.891 0-10.667 4.776-10.667 10.667v179.947a10.667 10.667 0 009.301 10.667l256 33.387c.455.031.911.031 1.365 0 5.891 0 10.667-4.776 10.667-10.667V277.36c0-5.891-4.775-10.667-10.666-10.667z"
            fill="#ffc107"
        />
    </SvgIcon>
);

export default WindowsIcon;
