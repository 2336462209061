// @flow

import React, { createContext, useContext } from "react";
import noop from "lodash/noop";

import useAPI from "../../shared/hooks/useAPI";
import type { PaginationProps } from "../../shared/hooks/useAPI";

export type Locale = "ru" | "uk";

export type CategoryContent = {
    categoryId: string,
    locale: Locale,
    content: string,
    title: string,
    metaTitle: string,
    metaDescription: string,
    h1Title: string,
    footer: string,
    forecastMetaTitle: string,
    forecastMetaDescription: string,
    forecastH1Title: string,
    forecastFooter: string,
    createdAt: string,
    updatedAt: ?string,
};

type CategoryEntity = "post" | "review" | "forecast" | "knowledge-base";
type CategoryShowIn = "footer" | "sidebar" | "header";

export type User = {
    id: string,
    firstName: string,
    lastName: string,
    avatarUrl?: string,
};

export type Category = {
    id: string,
    slug: string,
    show: boolean,
    order: number,
    createdAt: string,
    updatedAt: ?string,
    mainContent: CategoryContent[],
    entities: CategoryEntity[],
    createdBy: User,
    updatedBy: ?User,
    type: "main" | "secondary",
    parentCategoryId?: string,
    showIn?: CategoryShowIn[],
};

type CategoriesContext = {
    count: number,
    categories: ?(Category[]),
    error: ?Error,
    isLoading: boolean,
    refetch: (PaginationProps) => Promise<void>,
};

const Context = createContext<CategoriesContext>({
    categories: null,
    count: 0,
    error: null,
    isLoading: false,
    refetch: noop,
});

const CategoriesProvider = ({
    children,
    entity,
}: {
    children: *,
    entity?: "post" | "review" | "forecast" | "knowledge-base",
}) => {
    const { data, isLoading, error, refetch } = useAPI<{
        count: number,
        list: Category[],
    }>({
        orderBy: "createdAt",
        orderDirection: "DESC",
        url: `/content/categories/all${entity ? `/${entity}` : ""}`,
    });

    return (
        <Context.Provider
            value={{
                categories: data?.list,
                count: data?.count || 0,
                error,
                isLoading,
                refetch,
            }}
        >
            {children}
        </Context.Provider>
    );
};

const useCategories = () => useContext<CategoriesContext>(Context);

export { CategoriesProvider as Provider, useCategories };
