// @flow

import React, { useCallback, useState, memo } from "react";
import styled from "styled-components/macro";

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";

import SendIcon from "@material-ui/icons/Send";
import AttachFileIcon from "@material-ui/icons/AttachFile";

import FileUpload from "../FileUpload";
import Attachments from "../Attachments";

const Form = styled.form`
    display: flex;
    align-items: center;

    > :first-child {
        flex: 1;
        margin-right: 10px;
    }
`;

const ButtonsContainer = styled.div`
    > :not(:last-child) {
        margin-right: 10px;
    }
`;

type Props = {
    onSend?: ({ attachments: *[], message: string }) => void,
    disabled?: string,
    helperText?: string,
};

const MessagesInput = ({ onSend, disabled, helperText }: Props) => {
    const [message, setMessage] = useState("");
    const [attachments, setAttachments] = useState([]);
    const [isSending, setIsSending] = useState(false);
    const handleChange = useCallback((e) => {
        setMessage(e.target.value);
    }, []);

    const handleSend = useCallback(
        async (e) => {
            setIsSending(true);
            if (e.preventDefault) {
                e.preventDefault();
            }
            try {
                if (onSend) {
                    await onSend({ attachments, message });
                }
                setMessage("");
                setAttachments([]);
            } finally {
                setIsSending(false);
            }
        },
        [attachments, message, onSend]
    );

    const handleKeyPress = useCallback(
        async (e) => {
            if (e.key !== "Enter") {
                return;
            }

            await handleSend({ target: { value: message } });
        },
        [handleSend, message]
    );
    const handleDeleteAttachment = useCallback(
        (index) => () => {
            setAttachments(attachments.filter((_, i) => i !== index));
        },
        [attachments]
    );

    const setError = useCallback(() => {}, []);
    const handleAttachmentsAdd = useCallback((attachments) => {
        setAttachments(
            attachments.map(({ body, ...other }) => ({
                ...other,
                url: body,
            }))
        );
    }, []);

    return (
        <>
            <Form onSubmit={handleSend}>
                <TextField
                    onKeyPress={handleKeyPress}
                    placeholder="Type message..."
                    multiline
                    onChange={handleChange}
                    value={message}
                    disabled={disabled}
                    helperText={helperText}
                />
                <Box alignItems="center" display="flex">
                    {isSending && <CircularProgress size={30} />}
                    {!isSending && (
                        <ButtonsContainer>
                            <FileUpload
                                onChange={handleAttachmentsAdd}
                                accept="image/*,application/pdf"
                                onError={setError}
                                multiple
                            >
                                {({ upload }) => (
                                    <IconButton onClick={upload}>
                                        <AttachFileIcon />
                                    </IconButton>
                                )}
                            </FileUpload>

                            <Button
                                size="small"
                                color="primary"
                                endIcon={<SendIcon>send</SendIcon>}
                                variant="contained"
                                type="submit"
                                disabled={
                                    (!message.trim() && !attachments.length) ||
                                    disabled
                                }
                            >
                                Send
                            </Button>
                        </ButtonsContainer>
                    )}
                </Box>
            </Form>
            <Attachments
                attachments={attachments}
                onDelete={handleDeleteAttachment}
            />
        </>
    );
};

export default memo<Props>(MessagesInput);
