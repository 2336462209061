import React from "react";
import { BrowserRouter, Redirect, Route } from "react-router-dom";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

import MainApp from "./MainApp";
import { Provider as CurrentUserProvider } from "./modules/Auth/context/currentUser";
import { Provider as ConfigProvider } from "./modules/context/config";

const SignInRender = ({ location }) => {
    const query = new URLSearchParams(location.search);

    localStorage.setItem("token", query.get("token"));

    return <Redirect to={query.get("path") || "/"} />;
};

const App = () => {
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <BrowserRouter>
                <ConfigProvider>
                    <CurrentUserProvider>
                        <MainApp />
                        <Route exact path="/sign-in" render={SignInRender} />
                    </CurrentUserProvider>
                </ConfigProvider>
            </BrowserRouter>
        </MuiPickersUtilsProvider>
    );
};

export default App;
