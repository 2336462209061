// @flow

import { useCallback, useEffect, useState } from "react";
import pickBy from "lodash/pickBy";
import identity from "lodash/identity";
import apiCall from "../../utils/apiCall";

export type PaginationProps = {
    limit?: number,
    offset?: number,
    searchValue?: string,
    orderBy?: string,
    orderDirection?: "ASC" | "DESC",
};

export type RefetchType = (?PaginationProps) => Promise<void>;

export type APIResponse<T> = {
    data: ?T,
    isLoading: boolean,
    error: ?Error,
    refetch: RefetchType,
};

type APIProps = {
    ...PaginationProps,
    url: string,
};

const useAPI = <T>(props: APIProps): APIResponse<T> => {
    const { url, limit, offset, searchValue, orderBy, orderDirection } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState();
    const [fetchProps, setFetchProps] = useState({
        limit,
        offset,
        orderBy,
        orderDirection,
        searchValue,
    });

    const fetchData = useCallback(
        async (props: PaginationProps = {}) => {
            const token = localStorage.getItem("token");

            if (!token) {
                return;
            }

            try {
                setError(null);
                setIsLoading(true);
                setData(null);

                const qs = new URLSearchParams(
                    //$FlowFixMe
                    Object.entries(pickBy(props, identity))
                );

                const [urlPart, urlParams = ""] = url.split("?");
                [...new URLSearchParams(urlParams)].forEach(([name, value]) => {
                    if (!qs.has(name)) {
                        qs.append(name, value);
                    }
                });

                const response = await apiCall({
                    method: "GET",
                    url: `${urlPart}${
                        [...qs].length ? `?${qs.toString()}` : ""
                    }`,
                });

                if (!response.ok) {
                    setIsLoading(false);
                    return;
                }

                const result = await response.json();

                setData(result);
            } catch (e) {
                setError(e);
                alert(e.message);
            } finally {
                setIsLoading(false);
            }
        },
        [url]
    );

    const refetch = useCallback(
        (props: PaginationProps) => {
            const newFetchProps = {
                ...fetchProps,
                ...props,
            };

            setFetchProps(newFetchProps);

            return fetchData(newFetchProps);
        },
        [fetchProps, fetchData]
    );

    useEffect(() => {
        fetchData({
            limit,
            offset,
            orderBy,
            orderDirection,
            searchValue,
        });
    }, [fetchData, limit, offset, orderBy, orderDirection, searchValue]);

    return {
        data,
        error,
        isLoading,
        refetch,
    };
};

export default useAPI;
