import React, { useCallback, useState, useMemo } from "react";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import ImageUpload from "../../shared/components/ImageUpload";
import { useCurrentUser } from "../Auth/context/currentUser";

const CDNPage = () => {
    const { currentUser } = useCurrentUser();
    const [name, setName] = useState("");
    const handleChange = useCallback((url) => {
        prompt("Image uploaded", url);
    }, []);

    const handleNameChange = useCallback((e) => {
        setName(e.target.value);
    }, []);

    const isDisabled = useMemo(
        () => !currentUser.roles?.includes("superAdmin"),
        [currentUser]
    );

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="h6">Upload images to CDN</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    fullWidth
                    label="Name"
                    InputLabelProps={{ shrink: true }}
                    helperText="Is not required"
                    onChange={handleNameChange}
                    value={name}
                    disabled={isDisabled}
                />
            </Grid>
            <Grid item xs={12}>
                <ImageUpload
                    onChange={handleChange}
                    disabled={isDisabled}
                    name={name}
                />
            </Grid>
        </Grid>
    );
};

export { CDNPage };
