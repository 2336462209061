// @flow

import React, { createContext, useContext } from "react";
import noop from "lodash/noop";
import useAPI, { type PaginationProps } from "../../../shared/hooks/useAPI";

export type User = {
    id: string,
    firstName: string,
    lastName: string,
    avatarUrl?: string,
};

export type Payment = {
    id: string,
    title: string,
    image?: string,
    createdAt: string,
    updatedAt: string,
    createdBy: User,
    updatedBy?: User,
};

type PaymentsContext = {
    count: number,
    payments: ?(Payment[]),
    error: ?Error,
    isLoading: boolean,
    refetch: (PaginationProps) => Promise<void>,
};

const Context = createContext<PaymentsContext>({
    count: 0,
    error: null,
    isLoading: false,
    payments: null,
    refetch: noop,
});

const PaymentsProvider = ({ children }: { children: * }) => {
    const { data, isLoading, error, refetch } = useAPI<{
        count: number,
        list: Payment[],
    }>({
        url: "/content/reviews/payments",
    });

    return (
        <Context.Provider
            value={{
                count: data?.count || 0,
                error,
                isLoading,
                payments: data?.list,
                refetch,
            }}
        >
            {children}
        </Context.Provider>
    );
};

const usePayments = () => useContext<PaymentsContext>(Context);

export { PaymentsProvider as Provider, usePayments };
