// @flow

import React, { useCallback } from "react";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import GoogleIcon from "../../../shared/icons/GoogleIcon";

import styled from "styled-components/macro";

const Container = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
`;

type Props = {
    error?: string,
};

const SignIn = ({ error }: Props) => {
    const handleAuth = useCallback(
        (provider) => () => {
            window.location.assign(
                `${String(window.env.REACT_APP_AUTH_URL)}/${provider}`
            );
        },
        []
    );

    return (
        <Container>
            <Grid container alignItems="center" spacing={2}>
                <Grid container item justify="center" xs={12}>
                    <Typography variant="caption">Login with:</Typography>
                </Grid>
                <Grid container item justify="center" xs={12}>
                    <Button
                        onClick={handleAuth("google")}
                        variant="contained"
                        color="default"
                        endIcon={<GoogleIcon />}
                        size="large"
                    >
                        Google
                    </Button>
                </Grid>
                {error && (
                    <Grid container item justify="center" xs={12}>
                        <Typography variant="caption" color="error">
                            {error}
                        </Typography>
                    </Grid>
                )}
            </Grid>
        </Container>
    );
};

export default SignIn;
