// @flow

import React, { createContext, useContext } from "react";
import noop from "lodash/noop";

import useAPI from "../../../shared/hooks/useAPI";
import type { PaginationProps } from "../../../shared/hooks/useAPI";

type RolesContext = {
    error: ?Error,
    isLoading: boolean,
    roles: ?(string[]),
    refetch: (PaginationProps) => Promise<void>,
};

const Context = createContext<RolesContext>({
    error: null,
    isLoading: false,
    refetch: noop,
    roles: null,
});

const RolesProvider = ({ children }: { children: * }) => {
    const { data, isLoading, error, refetch } = useAPI<{
        roles: string[],
    }>({
        url: "/users/roles",
    });

    return (
        <Context.Provider
            value={{
                error,
                isLoading,
                refetch,
                roles: data?.roles,
            }}
        >
            {children}
        </Context.Provider>
    );
};

const useRoles = () => useContext<RolesContext>(Context);

export { RolesProvider as Provider, useRoles };
