// @flow

import React, { useCallback, useState } from "react";
import map from "lodash/map";
import { useHistory, useRouteMatch } from "react-router-dom";
import { DebounceInput } from "react-debounce-input";

import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Box from "@material-ui/core/Box";

import CreateIcon from "@material-ui/icons/Create";

import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";

import SearchIcon from "@material-ui/icons/Search";

import { useCategories, Provider, type Category } from "../context/categories";
import Table, {
    type Column,
    dataTypes,
} from "../../shared/components/SimpleTable";
import SectionToolbar from "../../shared/components/SectionToolbar";
import CategoriesHierarchy from "./CategoriesHierarchy";
import Loader from "../../shared/components/Loader";

const columns: Column[] = [
    {
        label: "Slug",
        name: "slug",
    },
    {
        dataType: dataTypes.BOOLEAN,
        label: "Is shown",
        name: "show",
    },
    {
        label: "Type",
        name: "type",
    },
    {
        label: "Order",
        name: "order",
    },
    {
        formatter: ({ entities }) => (entities || []).join(", "),
        isSortable: false,
        label: "Entities",
        name: "entities",
    },
    {
        formatter: ({ mainContent }) =>
            map(mainContent, "locale").sort().join(", "),
        isSortable: false,
        label: "Languages",
        name: "locales",
    },
    {
        dataType: dataTypes.DATE_TIME,
        label: "Created At",
        name: "createdAt",
    },
    {
        dataType: dataTypes.DATE_TIME,
        label: "Updated At",
        name: "updatedAt",
    },
];

const CategoriesSection = () => {
    const history = useHistory();
    const { categories, count, isLoading, refetch } = useCategories();
    const handleEdit = useCallback(
        ({ slug }: Category) => {
            history.push(`/categories/${slug}`);
        },
        [history]
    );

    const handleCreate = useCallback(() => {
        history.push("/categories/new");
    }, [history]);

    const handleSearch = useCallback(
        (e) =>
            refetch({
                searchValue: e.target.value || undefined,
            }),
        [refetch]
    );

    const match = useRouteMatch();

    const [tab, setTab] = useState(match.params.view === "hierarchy" ? 1 : 0);
    const handleTabChange = useCallback((e, newTab) => {
        window.history.replaceState(
            window.history.state,
            window.document.title,
            `/categories${newTab === 0 ? "" : "/hierarchy"}`
        );
        setTab(newTab);
    }, []);

    return (
        <Paper>
            <SectionToolbar
                title={<>Categories {!isLoading && <>({count})</>}</>}
                refetch={refetch}
            >
                <TextField
                    label="Search"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                        inputComponent: DebounceInput,
                        inputProps: { debounceTimeout: 500 },
                        onChange: handleSearch,
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <Button
                    variant="contained"
                    color="primary"
                    endIcon={<CreateIcon />}
                    onClick={handleCreate}
                >
                    Create category
                </Button>
            </SectionToolbar>
            <Tabs
                value={tab}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleTabChange}
            >
                <Tab label="Edit" />
                <Tab label="Hierarchy" />
            </Tabs>
            <Box display={tab === 0 ? "block" : "none"}>
                <Table
                    isEditable
                    columns={columns}
                    data={categories}
                    count={count}
                    refetch={refetch}
                    isLoading={isLoading}
                    onEdit={handleEdit}
                    withPagination={false}
                />
            </Box>
            <Box p={2} display={tab === 1 ? "block" : "none"}>
                {!categories ? (
                    <Loader isFullHeight />
                ) : (
                    <CategoriesHierarchy categories={categories} />
                )}
            </Box>
        </Paper>
    );
};

export default () => (
    <Provider>
        <CategoriesSection />
    </Provider>
);
