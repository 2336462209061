// @flow

import React, { memo } from "react";
import styled from "styled-components/macro";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";

import CloseIcon from "@material-ui/icons/Close";

import PDFIcon from "../../icons/PDFIcon";

const ImagePreview = styled.img`
    max-height: 100px;
    max-width: 200px;
    border-radius: 16px;
`;

const AttachmentsContainer = styled.div`
    overflow: auto;
    max-width: 100%;
    display: flex;
    flex-wrap: nowrap;
    margin-top: 20px;

    > :not(:last-child) {
        margin-right: 10px;
    }
`;

const AttachmentDelete = styled.div`
    position: absolute;
    top: 0;
    right: 0;
`;

type Props = {
    onDelete?: (number) => void,
    attachments: *[],
};

const Attachments = ({ onDelete, attachments }: Props) => {
    return (
        <AttachmentsContainer>
            {attachments.map(({ url, type, name }, i) => (
                <Box
                    display="inline-flex"
                    flexDirection="column"
                    position="relative"
                    width="min-content"
                    key={name || i}
                >
                    {onDelete && (
                        <AttachmentDelete onClick={onDelete(i)}>
                            <IconButton color="secondary">
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        </AttachmentDelete>
                    )}
                    {type.startsWith("image/") ? (
                        <a href={url} target="_blank" rel="noopener noreferrer">
                            <ImagePreview src={url} alt={name} />
                        </a>
                    ) : (
                        <PDFIcon style={{ height: 100, width: "unset" }} />
                    )}
                    {name}
                </Box>
            ))}
        </AttachmentsContainer>
    );
};

export default memo<Props>(Attachments);
