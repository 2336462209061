// @flow

import React, { useState, useCallback } from "react";
import styled from "styled-components";

import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import FormControl from "@material-ui/core/FormControl";

import StatusSelect from "../StatusSelect";
import type { PaginationProps } from "../../hooks/useAPI";
import type { Category, User } from "../../../modules/context/categories";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import { DebounceInput } from "react-debounce-input";

type Props = {
    refetch: (PaginationProps) => Promise<void>,
    isLoading?: boolean,
    authors?: ?(User[]),
    categories?: ?(Category[]),
};

const Container = styled.div`
    min-width: 200px;
`;

const TableToolbar = ({
    refetch,
    isLoading,
    authors = [],
    categories = [],
}: Props) => {
    const [status, setStatus] = useState("all");
    const [author, setAuthor] = useState(null);
    const [selectedCategories, setSelectedCategories] = useState([]);

    const handleStatusChange = useCallback(
        (e) => {
            const newStatus = e.target.value;
            setStatus(newStatus);
            refetch({ status: newStatus === "all" ? undefined : newStatus });
        },
        [refetch]
    );

    const handleAuthorChange = useCallback(
        (e, author) => {
            setAuthor(author?.id);
            refetch({ author: author?.id });
        },
        [refetch]
    );

    const handleCategoriesChange = useCallback(
        (e) => {
            const newTags = e.target.value;
            setSelectedCategories(newTags);
            refetch({ tags: newTags.length ? newTags.join(",") : undefined });
        },
        [refetch]
    );

    const handleSearch = useCallback(
        (e) =>
            refetch({
                slug: e.target.value || undefined,
            }),
        [refetch]
    );

    return (
        <Grid container spacing={2}>
            <Grid item>
                <Container>
                    <Autocomplete
                        fullWidth
                        disabled={isLoading}
                        options={authors}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Author"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        )}
                        getOptionLabel={({ firstName, lastName }) =>
                            [firstName, lastName].join(" ")
                        }
                        value={authors.find(({ id }) => id === author)}
                        onChange={handleAuthorChange}
                    />
                </Container>
            </Grid>
            <Grid item>
                <Container>
                    <StatusSelect
                        value={status}
                        options={["all", "created", "published"]}
                        onChange={handleStatusChange}
                    />
                </Container>
            </Grid>
            <Grid item>
                <Container>
                    <FormControl fullWidth>
                        <InputLabel id="categories-select-label" shrink>
                            Categories
                        </InputLabel>
                        <Select
                            labelId="categories-select-label"
                            multiple
                            onChange={handleCategoriesChange}
                            input={<Input />}
                            value={selectedCategories}
                            renderValue={(selectedValues) =>
                                selectedValues.join(", ")
                            }
                        >
                            {categories.map(({ slug }) => (
                                <MenuItem key={slug} value={slug}>
                                    <Checkbox
                                        checked={selectedCategories.includes(
                                            slug
                                        )}
                                    />
                                    <ListItemText primary={slug} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Container>
            </Grid>
            <Grid item>
                <TextField
                    label="Search"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                        inputComponent: DebounceInput,
                        inputProps: { debounceTimeout: 500 },
                        onChange: handleSearch,
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>
        </Grid>
    );
};

export default TableToolbar;
