// @flow
import slugify from "slugify";

export default (string: String) => {
    let cutString = '';

    if (string.length > 110) {
        cutString = string.slice(0, 110)

        return slugify(string, { 
            lower: true,
            remove: /[/!«»№;%:,?*()–—{}^&#ь]/g,
            replacement: '-'
         })
        .replaceAll('.', '-')
        .substring(0, cutString.lastIndexOf(' '))
    }

    return slugify(string, { 
        lower: true,
        remove: /[/!«»№;%:,?*(){}–—^&#ь]/g,
        replacement: '-'
     })
    .replaceAll('.', '-')
}
