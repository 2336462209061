// @flow

import React from "react";
import styled from "styled-components";

import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import RefreshIcon from "@material-ui/icons/Refresh";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@material-ui/core/Grid";

const ToolbarContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    align-items: center;
`;

const TitleContainer = styled.div`
    flex-grow: 1;
    display: flex;
    align-items: center;

    > * {
        margin-right: 5px;
    }
`;

type Props = {
    title: React$Element<*>,
    children?: React$Element<*> | React$Element<*>[],
    refetch?: Function,
};

const SectionToolbar = ({ children, title, refetch }: Props) => (
    <Toolbar>
        <ToolbarContainer>
            <TitleContainer>
                <Typography variant="h6">{title}</Typography>
                {refetch && (
                    <Tooltip title="Reload">
                        <IconButton color="primary" onClick={() => refetch({})}>
                            <RefreshIcon />
                        </IconButton>
                    </Tooltip>
                )}
            </TitleContainer>
            <div>
                <Grid container spacing={2}>
                    {React.Children.toArray(children).map((child, i) => (
                        <Grid item key={i}>
                            {child}
                        </Grid>
                    ))}
                </Grid>
            </div>
        </ToolbarContainer>
    </Toolbar>
);

export default SectionToolbar;
