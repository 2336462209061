// @flow

import React from "react";
import styled from "styled-components/macro";
import CircularProgress from "@material-ui/core/CircularProgress";

const LoaderContainer = styled.div`
    display: flex;
    flex-grow: 1;
    height: ${({ isFullHeight }) => (isFullHeight ? "100%" : "initial")};
    justify-content: center;
    align-items: center;
    padding: 25px;
`;

type Props = {
    isFullHeight?: boolean,
};

const Loader = ({ isFullHeight = false }: Props = {}) => (
    <LoaderContainer isFullHeight={isFullHeight}>
        <CircularProgress />
    </LoaderContainer>
);

export default Loader;
