// @flow

import { useCallback, useState, useEffect } from "react";
import set from "lodash/set";
import cloneDeep from "lodash/cloneDeep";
import apiCall from "../../utils/apiCall";

type Props<T> = {
    url: string,
    entity: T,
    prepareBody?: (T, T) => *,
    saveMethod?: "POST" | "PUT" | "PATCH",
};

const useCRUD = <T>({
    url,
    entity: initialEntity,
    prepareBody,
    saveMethod,
}: Props<T>) => {
    const [entity, setEntity] = useState<T>(initialEntity);
    const [oldEntity, setOldEntity] = useState<T>(initialEntity);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isChanged, setIsChanged] = useState<boolean>(false);
    const [data, setData] = useState();

    useEffect(() => {
        setEntity(initialEntity);
        setOldEntity(initialEntity);
    }, [initialEntity]);

    const handleMultipleChange = useCallback(
        (opts: { [key: string]: * }) => {
            const newEntity = cloneDeep(entity);

            Object.keys(opts).forEach((name) => {
                set(newEntity, name, opts[name]);
            });

            setIsChanged(true);
            setEntity(newEntity);
        },
        [setEntity, entity]
    );

    const handleChange = useCallback(
        (name: string) => (value: *) => {
            const newEntity =
                name.slice(-7) === "content" ? entity : cloneDeep(entity);

            set(newEntity, name, value);
            setIsChanged(true);
            setEntity(newEntity);
        },
        [setEntity, entity]
    );

    const handleChangeEvent = useCallback(
        (name: string) => ({ target }: SyntheticInputEvent<HTMLInputElement>) =>
            handleChange(name)(
                target.type === "checkbox" ? target.checked : target.value
            ),
        [handleChange]
    );

    const reset = useCallback(() => {
        setIsChanged(false);
        setEntity(initialEntity);
    }, [setEntity, initialEntity]);

    const save = useCallback(async () => {
        setIsLoading(true);

        try {
            const response = await apiCall({
                body: prepareBody
                    ? await prepareBody(entity, oldEntity)
                    : entity,
                method: saveMethod || "POST",
                url,
            });

            if (!response.ok) {
                const message = await response.text();
                setIsLoading(false);
                alert(`Saving failed: ${message}`);
                return;
            }

            setIsLoading(false);
            setIsChanged(false);
            setOldEntity(entity);
            const data = response.json();
            setData(data);

            window.localStorage.removeItem(entity?.slug);
            window.localStorage.removeItem('post_new');
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(`CRUD ${url} update error: `, e);
            setIsLoading(false);
            alert(e.message);
        }
    }, [prepareBody, entity, oldEntity, saveMethod, url]);

    return {
        data,
        entity,
        handleChange,
        handleChangeEvent,
        handleMultipleChange,
        isChanged,
        isLoading,
        reset,
        save,
    };
};

export default useCRUD;
