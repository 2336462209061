// @flow

import React, { createContext, useContext } from "react";
import noop from "lodash/noop";
import useAPI, { type PaginationProps } from "../../../shared/hooks/useAPI";

export type User = {
    id: string,
    firstName: string,
    lastName: string,
    avatarUrl?: string,
};

export type Team = {
    id: string,
    slug: string,
    show: boolean,
    image: string,
    name: string,
    createdAt: string,
    updatedAt: string,
    createdBy: User,
    updatedBy?: User,
    localizedName?: {
        uk: string,
        ru: string,
    },
};

type TeamsContext = {
    count: number,
    teams: ?(Team[]),
    error: ?Error,
    isLoading: boolean,
    refetch: (PaginationProps) => Promise<void>,
};

const Context = createContext<TeamsContext>({
    count: 0,
    error: null,
    isLoading: false,
    refetch: noop,
    teams: null,
});

const TeamsProvider = ({ children }: { children: * }) => {
    const { data, isLoading, error, refetch } = useAPI<{
        count: number,
        list: Team[],
    }>({
        url: "/content/teams/all",
    });

    return (
        <Context.Provider
            value={{
                count: data?.count || 0,
                error,
                isLoading,
                refetch,
                teams: data?.list,
            }}
        >
            {children}
        </Context.Provider>
    );
};

const useTeams = () => useContext<TeamsContext>(Context);

export { TeamsProvider as Provider, useTeams };
