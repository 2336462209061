// @flow

import React, { createContext, useContext } from "react";
import noop from "lodash/noop";

import useAPI from "../../../shared/hooks/useAPI";
import type { PaginationProps } from "../../../shared/hooks/useAPI";

type User = {
    id: string,
};

type UsersContext = {
    count: number,
    error: ?Error,
    isLoading: boolean,
    users: ?(User[]),
    refetch: (PaginationProps) => Promise<void>,
};

const Context = createContext<UsersContext>({
    count: 0,
    error: null,
    isLoading: false,
    refetch: noop,
    users: null,
});

const UsersProvider = ({ children, roles }: { children: * }) => {
    const { data, isLoading, error, refetch } = useAPI<{
        count: number,
        list: User[],
    }>({
        orderBy: "createdAt",
        orderDirection: "DESC",
        url: roles ? `/users?roles[]=${roles.join("&roles[]=")}` : "/users",
    });

    return (
        <Context.Provider
            value={{
                count: data?.count || 0,
                error,
                isLoading,
                refetch,
                users: data?.list,
            }}
        >
            {children}
        </Context.Provider>
    );
};

const useUsers = () => useContext<UsersContext>(Context);

export { UsersProvider as Provider, useUsers };
