// @flow

import React, { useState, useCallback, useEffect, Fragment } from "react";
import clsx from "clsx";
import styled from "styled-components";
import { Route, useHistory, Switch, useLocation } from "react-router-dom";
import io from "socket.io-client";
import PubSub from "pubsub-js";

import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import AppBar from "@material-ui/core/AppBar";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Collapse from "@material-ui/core/Collapse";
import Avatar from "@material-ui/core/Avatar";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import DescriptionIcon from "@material-ui/icons/Description";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import RateReviewIcon from "@material-ui/icons/RateReview";
import PersonIcon from "@material-ui/icons/Person";
import CategoryIcon from "@material-ui/icons/Category";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import SportsSoccerIcon from "@material-ui/icons/SportsSoccer";
import BonusIcon from "@material-ui/icons/CardGiftcard";
import BannerIcon from "@material-ui/icons/ViewCarousel";
import StorageIcon from "@material-ui/icons/Storage";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import BarChartIcon from "@material-ui/icons/BarChart";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";

import SignIn from "./modules/Auth/pages/SignIn";
import {
    Section as Posts,
    PostCreatePage,
    PostEditPage,
} from "./modules/Posts";
import {
    Section as KnowledgeBases,
    KnowledgeBaseCreatePage,
    KnowledgeBaseEditPage,
} from "./modules/KnowledgeBases";
import Users from "./modules/Users";
import {
    Section as Reviews,
    PaymentsSection as Payments,
    ReviewEditPage,
    ReviewCreatePage,
    PaymentCreatePage,
    PaymentEditPage,
} from "./modules/Reviews";
import {
    Section as Categories,
    CategoryCreatePage,
    CategoryEditPage,
} from "./modules/Categories";
import {
    Section as Forecasts,
    TeamsSection as Teams,
    TeamCreatePage,
    TeamEditPage,
    ForecastCreatePage,
    ForecastEditPage,
} from "./modules/Forecasts";
import { BonusEditPage } from "./modules/Bonuses";
import { BannerEditPage } from "./modules/Promotions";
import { CDNPage } from "./modules/CDN";
import { StatsPage } from "./modules/Stats";
import { Section as Complaints, ComplaintEditPage } from "./modules/Complaints";

import { useCurrentUser } from "./modules/Auth/context/currentUser";
import { useConfig } from "./modules/context/config";
import Loader from "./shared/components/Loader";
import Spacer from "./shared/components/Spacer";

type Section = {
    icon?: React$Element<*>,
    name: string,
    path: string,
    title: string,
    component?: React$Component<*> | Function,
    sections?: Section[],
};

const sections: Section[] = [
    {
        component: StatsPage,
        icon: <BarChartIcon />,
        name: "statistics",
        path: "/stats",
        title: "Statistics",
    },
    {
        component: Users,
        icon: <PersonIcon />,
        name: "users",
        path: "/users",
        title: "Users",
    },
    {
        component: Posts,
        icon: <DescriptionIcon />,
        name: "posts",
        path: "/posts",
        title: "Posts",
    },
    {
        component: KnowledgeBases,
        icon: <StorageIcon />,
        name: "knowledge-bases",
        path: "/knowledge-bases",
        title: "Knowledge base",
    },
    {
        icon: <RateReviewIcon />,
        name: "reviewsBase",
        path: "/reviews",
        sections: [
            {
                component: Reviews,
                name: "reviews",
                path: "/reviews",
                title: "Reviews",
            },
            {
                component: Payments,
                name: "payments",
                path: "/reviews/payments",
                title: "Payments",
            },
        ],
        title: "Reviews",
    },
    {
        component: Complaints,
        icon: <AssignmentTurnedInIcon />,
        name: "сomplaints",
        path: "/complaints",
        title: "Complaints",
    },
    {
        component: Forecasts,
        icon: <SportsSoccerIcon />,
        name: "forecasts",
        path: "/forecasts",
        sections: [
            {
                component: Forecasts,
                name: "forecasts",
                path: "/forecasts",
                title: "Forecasts",
            },
            {
                component: Teams,
                name: "teams",
                path: "/forecasts/teams",
                title: "Teams",
            },
        ],
        title: "Forecasts",
    },
    {
        component: Categories,
        icon: <CategoryIcon />,
        name: "categories",
        path: "/categories",
        title: "Categories",
    },
    {
        component: BonusEditPage,
        icon: <BonusIcon />,
        name: "bonus",
        path: "/bonus",
        title: "Bonus",
    },
    {
        component: BannerEditPage,
        icon: <BannerIcon />,
        name: "banner",
        path: "/banner",
        title: "Banner",
    },
    {
        component: CDNPage,
        icon: <CloudUploadIcon />,
        name: "cdn",
        path: "/cdn",
        title: "CDN",
    },
];

const ProfileContainer = styled.div`
    display: flex;
    align-items: center;

    > * {
        margin-right: 10px;
    }
`;

const subMenuItemStyle = {
    paddingLeft: 40,
};

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    appBar: {
        transition: theme.transitions.create(["margin", "width"], {
            duration: theme.transitions.duration.leavingScreen,
            easing: theme.transitions.easing.sharp,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        transition: theme.transitions.create(["margin", "width"], {
            duration: theme.transitions.duration.enteringScreen,
            easing: theme.transitions.easing.easeOut,
        }),
        width: `calc(100% - ${drawerWidth}px)`,
    },
    content: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        height: "100%",
        marginLeft: 0,
        maxWidth: "100%",
        padding: theme.spacing(3),
        transition: theme.transitions.create("margin", {
            duration: theme.transitions.duration.leavingScreen,
            easing: theme.transitions.easing.sharp,
        }),
    },
    contentShift: {
        marginLeft: drawerWidth,
        transition: theme.transitions.create("margin", {
            duration: theme.transitions.duration.enteringScreen,
            easing: theme.transitions.easing.easeOut,
        }),
        width: "calc(100% - 240px)",
    },
    drawerHeader: {
        alignItems: "center",
        display: "flex",
        justifyContent: "flex-end",
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        padding: theme.spacing(0, 1),
    },
    drawerPaper: {
        width: drawerWidth,
    },
    hide: {
        display: "none",
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    root: {
        display: "flex",
        height: "100%",
    },
}));

const MainApp = () => {
    const { currentUser, isLoading } = useCurrentUser();
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    useEffect(() => {
        setIsOpen(!isMobile);
    }, [isMobile]);

    useEffect(() => {
        const socket = io(window.env.REACT_APP_SOCKET_URL, {
            query: {
                token: localStorage.getItem("token"),
            },
            transports: ["websocket"],
        });

        socket.on("message", ({ code, data }) => {
            // eslint-disable-next-line no-console
            console.info("New socket message", { code, data });
            PubSub.publish(code, data);
        });
    }, []);

    const [openedSection, setOpenedSection] = useState();
    const [isOpen, setIsOpen] = useState(!isMobile);
    const history = useHistory();

    const handleDrawerOpen = useCallback(() => {
        setIsOpen(true);
    }, []);

    const handleDrawerClose = useCallback(() => {
        setIsOpen(false);
    }, []);

    const goTo = useCallback(
        (path) => () => {
            history.push(path);

            if (isMobile) {
                handleDrawerClose();
            }
        },
        [history, isMobile, handleDrawerClose]
    );

    const logout = useCallback(() => {
        localStorage.removeItem("token");
        window.location.reload();
    }, []);

    const location = useLocation();

    const { isLoading: isConfigLoading } = useConfig();

    if (isLoading || isConfigLoading) {
        return <Loader isFullHeight />;
    }

    if (!currentUser) {
        return <SignIn />;
    }

    if (
        !["admin", "superAdmin"].some((role) =>
            currentUser.roles?.includes(role)
        )
    ) {
        return <SignIn error="Not enough rights. 😭" />;
    }

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: isOpen,
                })}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        edge="start"
                        onClick={handleDrawerOpen}
                        className={clsx(isOpen && classes.hide)}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Spacer />
                    <ProfileContainer>
                        <Typography>{currentUser?.name}</Typography>
                        <Avatar
                            alt={currentUser?.name}
                            src={currentUser?.avatarUrl}
                        />
                    </ProfileContainer>
                    <Button color="inherit" onClick={logout}>
                        Logout
                    </Button>
                </Toolbar>
            </AppBar>
            <main
                className={clsx(classes.content, {
                    [classes.contentShift]: !isMobile && isOpen,
                })}
            >
                <div className={classes.drawerHeader} />
                <Switch>
                    <Route exact path="/posts/new" component={PostCreatePage} />
                    <Route exact path="/posts/:slug" component={PostEditPage} />
                    <Route
                        exact
                        path="/knowledge-bases/new"
                        component={KnowledgeBaseCreatePage}
                    />
                    <Route
                        exact
                        path="/knowledge-bases/:slug"
                        component={KnowledgeBaseEditPage}
                    />
                    <Route
                        exact
                        path="/reviews/payments/new"
                        component={PaymentCreatePage}
                    />
                    <Route
                        exact
                        path="/reviews/payments/:id"
                        component={PaymentEditPage}
                    />
                    <Route
                        exact
                        path="/forecasts/teams/new"
                        component={TeamCreatePage}
                    />
                    <Route
                        exact
                        path="/forecasts/teams/:slug"
                        component={TeamEditPage}
                    />
                    {sections
                        .reduce((total, { sections, ...section }) => {
                            total.push(section, ...(sections || []));

                            return total;
                        }, [])
                        .map(
                            ({ path, component, name }) =>
                                component && (
                                    <Route
                                        component={component}
                                        exact
                                        key={name}
                                        path={path}
                                    />
                                )
                        )}
                    <Route
                        exact
                        path="/reviews/new"
                        component={ReviewCreatePage}
                    />
                    <Route
                        exact
                        path="/reviews/:slug"
                        component={ReviewEditPage}
                    />
                    <Route
                        exact
                        path="/categories/new"
                        component={CategoryCreatePage}
                    />
                    <Route
                        exact
                        path="/categories/:view(hierarchy)"
                        component={Categories}
                    />
                    <Route
                        exact
                        path="/categories/:slug"
                        component={CategoryEditPage}
                    />
                    <Route
                        exact
                        path="/forecasts/new"
                        component={ForecastCreatePage}
                    />
                    <Route
                        exact
                        path="/forecasts/:slug"
                        component={ForecastEditPage}
                    />
                    <Route
                        exact
                        path="/complaints/:id"
                        component={ComplaintEditPage}
                    />
                    <Route path="/" component={StatsPage} />
                </Switch>
            </main>
            <Drawer
                variant="persistent"
                anchor={isMobile ? "top" : "left"}
                open={isOpen}
                {...(!isMobile && {
                    classes: { paper: classes.drawerPaper },
                })}
            >
                <div className={classes.drawerHeader}>
                    <IconButton onClick={handleDrawerClose}>
                        <ChevronLeftIcon />
                    </IconButton>
                </div>
                <Divider />
                <List>
                    {sections.map(({ icon, name, title, path, sections }) => (
                        <Fragment key={name}>
                            <ListItem
                                selected={location.pathname === path}
                                button
                                onClick={
                                    sections && sections.length > 0
                                        ? () =>
                                              setOpenedSection(
                                                  name === openedSection
                                                      ? undefined
                                                      : name
                                              )
                                        : goTo(path)
                                }
                            >
                                {icon && <ListItemIcon>{icon}</ListItemIcon>}
                                <ListItemText primary={title} />
                                {sections && sections.length ? (
                                    openedSection === name ? (
                                        <ExpandLess />
                                    ) : (
                                        <ExpandMore />
                                    )
                                ) : null}
                            </ListItem>
                            {sections &&
                                sections.map((section) => (
                                    <Collapse
                                        key={section.name}
                                        timeout="auto"
                                        in={openedSection === name}
                                    >
                                        <List component="div" disablePadding>
                                            <ListItem
                                                onClick={goTo(section.path)}
                                                style={subMenuItemStyle}
                                                selected={
                                                    window.location.pathname ===
                                                    section.path
                                                }
                                                button
                                            >
                                                {section.icon && (
                                                    <ListItemIcon>
                                                        {section.icon}
                                                    </ListItemIcon>
                                                )}
                                                <ListItemText
                                                    primary={section.title}
                                                />
                                            </ListItem>
                                        </List>
                                    </Collapse>
                                ))}
                        </Fragment>
                    ))}
                </List>
            </Drawer>
        </div>
    );
};

export default MainApp;
