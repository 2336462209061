// @flow

import React, { useState, useCallback, memo } from "react";
import format from "date-fns/format";
import get from "lodash/get";
import Sticky from "react-sticky-el";
import styled from "styled-components/macro";

import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import MuiTableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";

import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

import Loader from "../Loader";
import { withStyles } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import ConfirmationModal from "../modals/ConfirmationModal";

export const dataTypes = {
    BOOLEAN: "BOOLEAN",
    DATE_TIME: "DATE_TIME",
};

const dataTypesFormatters: { [string]: Function } = {
    [dataTypes.DATE_TIME]: (value) =>
        value ? format(new Date(value), "dd MMM yyyy HH:mm:ss OOOO") : "",
    [dataTypes.BOOLEAN]: (value) => <Checkbox checked={value} disabled />,
};

const TableContainer = styled.div`
    max-width: 100%;
    overflow-x: scroll;
`;

const NoDataContainer = styled.div`
    padding: 30px;
    display: flex;
    justify-content: center;
`;

const ActionsContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    > *:not(last-child) {
        margin-right: 5px;
    }
`;

export type ColumnType = "DATE_TIME" | "BOOLEAN";

export type Column = {
    name: string,
    label: string,
    dataType?: ColumnType,
    isSortable?: boolean,
    formatter?: Function,
};

type Props = {
    columns: Column[],
    count: number,
    data: ?(Object[]),
    isLoading: boolean,
    refetch: Function,
    onEdit?: Function,
    actionsComponent?: React$ComponentType<*>,
    withPagination?: boolean,
    onItemDelete?: ?(Object, SyntheticEvent<*>) => void | Promise<void>,
};

const TableRow = withStyles({
    hover: {
        cursor: "pointer",
    },
})(MuiTableRow);

const SimpleTable = ({
    actionsComponent: ActionsComponent,
    columns,
    data = [],
    count,
    refetch,
    isLoading,
    onEdit,
    withPagination = true,
    onItemDelete,
}: Props = {}) => {
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [page, setPage] = useState(0);
    const [orderDirection, setOrderDirection] = useState("DESC");
    const [orderBy, setOrderBy] = useState("createdAt");

    const handlePageChange = useCallback(
        (e, newPage) => {
            setPage(newPage);
            refetch({ offset: newPage * rowsPerPage, orderBy, orderDirection });
        },
        [refetch, rowsPerPage, orderBy, orderDirection]
    );

    const handleRowsPerPageChange = useCallback(
        (e) => {
            const newRowsPerPage = parseInt(e.target.value, 10);
            setRowsPerPage(newRowsPerPage);
            setPage(0);
            refetch({
                limit: newRowsPerPage,
                offset: 0,
                orderBy,
                orderDirection,
            });
        },
        [orderBy, orderDirection, refetch]
    );

    const handleSort = useCallback(
        (columnName) => () => {
            const newOrderDirection =
                orderBy === columnName && orderDirection === "ASC"
                    ? "DESC"
                    : "ASC";
            setOrderDirection(newOrderDirection);
            setOrderBy(columnName);
            setPage(0);

            refetch({
                offset: 0,
                orderBy: columnName,
                orderDirection: newOrderDirection,
            });
        },
        [orderBy, orderDirection, refetch]
    );

    const [entityToDelete, setEntityToDelete] = useState(null);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    const handleDelete = useCallback(
        (entity) => () => {
            if (!onItemDelete) {
                return;
            }
            setShowDeleteConfirmation(true);
            setEntityToDelete(entity);
        },
        [onItemDelete]
    );

    const handleDeleteCancel = useCallback(() => {
        setShowDeleteConfirmation(false);
        setEntityToDelete(null);
    }, []);

    const handleConfirm = useCallback(
        async (e) => {
            setIsDeleting(true);
            if (onItemDelete) {
                try {
                    await onItemDelete(entityToDelete, e);
                } catch (e) {}
            }
            setIsDeleting(false);
            handleDeleteCancel();
        },
        [entityToDelete, handleDeleteCancel, onItemDelete]
    );

    return (
        <>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            {columns.map(({ name, label, isSortable }) => (
                                <TableCell
                                    key={name}
                                    sortDirection={
                                        orderBy === name &&
                                        isSortable !== false &&
                                        count > 0
                                            ? orderDirection.toLowerCase()
                                            : false
                                    }
                                >
                                    {isSortable !== false && count > 0 ? (
                                        <TableSortLabel
                                            active={orderBy === name}
                                            direction={
                                                orderBy === name
                                                    ? orderDirection.toLowerCase()
                                                    : "desc"
                                            }
                                            onClick={handleSort(name)}
                                        >
                                            {label}
                                        </TableSortLabel>
                                    ) : (
                                        label
                                    )}
                                </TableCell>
                            ))}
                            <TableCell key="actions">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data?.map((entity) => (
                            <TableRow
                                key={`entity-${entity.id}`}
                                hover={Boolean(onEdit)}
                                onDoubleClick={(e) =>
                                    onEdit && onEdit(entity, e)
                                }
                            >
                                {columns.map(
                                    ({ name, dataType, formatter }) => (
                                        <TableCell
                                            key={`entity-${entity.id}-${name}`}
                                        >
                                            {typeof formatter === "function"
                                                ? formatter(entity)
                                                : dataType &&
                                                  dataTypesFormatters[dataType]
                                                ? dataTypesFormatters[dataType](
                                                      get(entity, name)
                                                  )
                                                : get(entity, name)}
                                        </TableCell>
                                    )
                                )}
                                <TableCell>
                                    <ActionsContainer>
                                        {ActionsComponent && (
                                            <ActionsComponent entity={entity} />
                                        )}
                                        {onEdit && (
                                            <Tooltip title="Edit">
                                                <IconButton
                                                    onClick={(e) =>
                                                        onEdit &&
                                                        onEdit(entity, e)
                                                    }
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                        {onItemDelete && (
                                            <Tooltip title="Delete">
                                                <IconButton
                                                    color="secondary"
                                                    onClick={handleDelete(
                                                        entity
                                                    )}
                                                >
                                                    <DeleteForeverIcon />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                    </ActionsContainer>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                {!isLoading && !data?.length && (
                    <NoDataContainer>
                        <Typography variant="subtitle2">
                            No data yet...{" "}
                            <span role="img" aria-label="Sad">
                                😞
                            </span>
                        </Typography>
                    </NoDataContainer>
                )}
            </TableContainer>
            {isLoading && <Loader />}
            {!isLoading && withPagination && (
                <Sticky mode="bottom">
                    <Paper>
                        <TablePagination
                            count={count}
                            rowsPerPageOptions={[20, 50, 100]}
                            rowsPerPage={rowsPerPage}
                            onChangeRowsPerPage={handleRowsPerPageChange}
                            page={page}
                            onChangePage={handlePageChange}
                        />
                    </Paper>
                </Sticky>
            )}
            {showDeleteConfirmation && entityToDelete && (
                <ConfirmationModal
                    title={`You want to delete "${entityToDelete.slug}"`}
                    isOpen={showDeleteConfirmation}
                    onCancel={handleDeleteCancel}
                    onConfirm={handleConfirm}
                    isLoading={isDeleting}
                />
            )}
        </>
    );
};

export default memo<Props>(SimpleTable);
