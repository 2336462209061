// @flow

import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import map from "lodash/map";

import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";

import CreateIcon from "@material-ui/icons/Create";

import {
    useKnowledgeBases,
    Provider,
    type KnowledgeBase,
} from "./context/knowledge-bases";
import {
    useCategories,
    Provider as CategoriesProvider,
} from "../context/categories";
import Table, {
    type Column,
    dataTypes,
} from "../../shared/components/SimpleTable";
import SectionToolbar from "../../shared/components/SectionToolbar";
import { useCurrentUser } from "../Auth/context/currentUser";
import TableToolbar from "../../shared/components/TableToolbar";

const columns: Column[] = [
    {
        label: "Id",
        name: "id",
    },
    {
        label: "Slug",
        name: "slug",
    },
    {
        formatter: ({ mainContent }) =>
            map(mainContent, "locale").sort().join(", "),
        isSortable: false,
        label: "Languages",
        name: "locales",
    },
    {
        formatter: ({ author }) =>
            [author?.firstName, author?.lastName].join(" "),
        isSortable: false,
        label: "Author",
        name: "author",
    },
    {
        label: "Status",
        name: "status",
    },
    {
        dataType: dataTypes.DATE_TIME,
        label: "Created At",
        name: "createdAt",
    },
    {
        dataType: dataTypes.DATE_TIME,
        label: "Updated At",
        name: "updatedAt",
    },
    {
        formatter: ({ updatedBy }) =>
            [updatedBy?.firstName, updatedBy?.lastName].join(" "),
        isSortable: false,
        label: "Updated By",
        name: "updatedBy",
    },
];

const KnowledgeBasesSection = () => {
    const history = useHistory();
    const { currentUser } = useCurrentUser();
    const {
        authors,
        knowledgeBases,
        count,
        isLoading,
        refetch,
        deleteKnowledgeBase,
    } = useKnowledgeBases();
    const { categories } = useCategories();

    const handleEdit = useCallback(
        ({ slug }: KnowledgeBase) => {
            history.push(`/knowledge-bases/${slug}`);
        },
        [history]
    );

    const handleCreate = useCallback(() => {
        history.push("/knowledge-bases/new");
    }, [history]);

    return (
        <Paper>
            <SectionToolbar
                title={<>Knowledge bases {!isLoading && <>({count})</>}</>}
                refetch={refetch}
            >
                <TableToolbar
                    refetch={refetch}
                    authors={authors}
                    categories={categories}
                />
                <Button
                    variant="contained"
                    color="primary"
                    endIcon={<CreateIcon />}
                    onClick={handleCreate}
                >
                    Create article
                </Button>
            </SectionToolbar>
            <Table
                isEditable
                columns={columns}
                data={knowledgeBases}
                count={count}
                refetch={refetch}
                isLoading={isLoading}
                onEdit={handleEdit}
                onItemDelete={
                    currentUser && currentUser.roles.includes("superAdmin")
                        ? deleteKnowledgeBase
                        : undefined
                }
            />
        </Paper>
    );
};

export default () => (
    <Provider>
        <CategoriesProvider entity="knowledge-base">
            <KnowledgeBasesSection />
        </CategoriesProvider>
    </Provider>
);
