// @flow

import React, { useRef, useCallback } from "react";
import bytes from "bytes";

type Props = {
    children: *,
    onChange?: ({
        body: string,
        name: string,
        type: string,
    }) => Promise<void> | void,
    disabled?: boolean,
    accept?: string,
    onError?: (string) => void,
    maxSize?: number,
    multiple?: boolean,
};

const FileUpload = ({
    children,
    onChange,
    disabled,
    accept,
    maxSize = 2097152,
    onError,
    multiple = false,
}: Props) => {
    const inputRef = useRef(null);
    const upload = useCallback(() => {
        // eslint-disable-next-line no-unused-expressions
        inputRef?.current?.click();
    }, [inputRef]);

    const handleChange = useCallback(
        (e) => {
            const files = e.target.files;
            const result = [];

            for (const file of files) {
                if (file.size > maxSize && onError) {
                    onError(
                        `Exceeded size limit ${bytes(file.size)} / ${bytes(
                            maxSize
                        )}`
                    );
                    return;
                }

                const reader = new FileReader();
                reader.onload = async () => {
                    if (onChange && typeof reader.result === "string") {
                        let type = file.type;

                        result.push({
                            body: reader.result,
                            name: file.name,
                            type,
                        });

                        if (result.length === files.length) {
                            onChange(multiple ? result : result[0]);
                        }
                    }
                };

                reader.readAsDataURL(file);
            }
        },
        [maxSize, multiple, onChange, onError]
    );

    return (
        <>
            <input
                type="file"
                hidden
                ref={inputRef}
                onChange={handleChange}
                disabled={disabled}
                accept={accept}
                multiple={multiple}
            />
            {children({ upload })}
        </>
    );
};

export default FileUpload;
