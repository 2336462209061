// @flow

import apiCall from "./apiCall";

type Props = {
    file: ArrayBuffer | string,
    name: string,
    type: string,
    filesBucketName: string,
    isPrivate: boolean,
};

const uploadFile = async ({
    file,
    name,
    type,
    filesBucketName,
    isPrivate,
}: Props) => {
    const response = await apiCall({
        body: {
            file,
            isPrivate: Boolean(isPrivate),
            name,
            type,
        },
        method: "POST",
        url: "/files/image",
    });

    const data = await response.json();

    return { ...data, url: `https://${filesBucketName}/${data.key}` };
};

export default uploadFile;
