// @flow
import React, { useCallback, useMemo, useState } from "react";
import { type Match, useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import fpKeyBy from "lodash/fp/keyBy";
import compose from "lodash/fp/compose";
import fpMap from "lodash/fp/map";
import fpPick from "lodash/fp/pick";
import capitalize from "lodash/capitalize";
import mapValues from "lodash/mapValues";
import Sticky from "react-sticky-el";

import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import RefreshIcon from "@material-ui/icons/Refresh";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import formatDate from "../../utils/formatDate";
import Loader from "../../shared/components/Loader";
import useAPI from "../../shared/hooks/useAPI";
import {
    type Category,
    Provider as CategoriesProvider,
    useCategories,
} from "../context/categories";
import withErrorHandler from "../../shared/hocs/withErrorHandler";
import useCRUD from "../../shared/hooks/useCRUD";
import NotFound from "../../shared/components/NotFound";
import type { RefetchType } from "../../shared/hooks/useAPI";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Divider from "@material-ui/core/Divider";
import useContent from "../../shared/hooks/useContent";
import keyBy from "lodash/keyBy";
import Autocomplete from "@material-ui/lab/Autocomplete";
import getSlug from "../../utils/getSlug";

const Container = styled.div`
    padding: 15px;
`;

const ToolbarContainer = styled.div`
    > * {
        margin-right: 5px;
    }
`;

type Props = {
    isNew?: boolean,
    data: { category: ?Category },
    isLoading: boolean,
    refetch?: RefetchType,
};

const stickyStyle = {
    backgroundColor: "white",
    paddingBottom: 15,
    paddingTop: 77,
    zIndex: 2,
};

const entities = ["post", "review", "forecast", "knowledge-base"];
const showInSelect = ["sidebar", "header", "footer"];

const getEntity = ({ category }: { category: ?Category }) =>
    category
        ? {
              content: compose(
                  fpKeyBy("locale"),
                  fpMap((localeContent) =>
                      fpPick(
                          [
                              "locale",
                              "title",
                              "h1Title",
                              "footer",
                              "metaTitle",
                              "metaDescription",
                              "content",
                              "createdAt",
                              "forecastH1Title",
                              "forecastFooter",
                              "forecastMetaTitle",
                              "forecastMetaDescription",
                          ],
                          localeContent
                      )
                  )
              )(category.mainContent),
              createdAt: category.createdAt,
              createdBy: category.createdBy,
              entities: category.entities || ["post"],
              order: category.order || 0,
              parentCategoryId: category.parentCategoryId,
              show: typeof category.show === "boolean" ? category.show : true,
              showIn:
                  category.showIn ||
                  (category?.type === "main" ? ["sidebar"] : []),
              slug: category.slug,
              type: category.type || "main",
              updatedAt: category.updatedAt,
              updatedBy: category.updatedBy,
          }
        : null;

const prepareUpdateBody = ({ content, ...category } = {}) => ({
    category: {
        ...category,
        order: parseInt(category.order) || 0,
    },
    content: mapValues(content, ({ createdAt, ...localeContent }) => ({
        ...localeContent,
        content: localeContent.content || "",
    })),
});

const CategoryPage = ({ data, isLoading, refetch, isNew = false }: Props) => {
    const category = data?.category;

    const initialEntity = useMemo(() => getEntity({ category }), [category]);

    const {
        entity,
        handleChangeEvent,
        handleChange,
        handleMultipleChange,
        isChanged,
        save,
        reset,
        isLoading: isSaving,
    } = useCRUD({
        entity: initialEntity,
        prepareBody: prepareUpdateBody,
        saveMethod: isNew ? "POST" : "PUT",
        url: `/content/categories${isNew ? "" : `/${String(category?.id)}`}`,
    });

    const {
        categories: allCategories,
        isLoading: isCategoriesLoading,
    } = useCategories();
    const { slug, content } = entity || {};

    const categories = useMemo(
        () =>
            allCategories &&
            (isNew
                ? allCategories
                : allCategories.filter((category) => category.slug !== slug)),
        [isNew, allCategories, slug]
    );

    const categoriesById = useMemo(
        () => categories && keyBy(categories, "id"),
        [categories]
    );

    const history = useHistory();

    const goBack = useCallback(() => {
        history.push("/categories");
    }, [history]);

    const { languageChangeComponent, locale } = useContent<Category>({
        entity,
        isNew,
        isSaving,
    });

    const handleSave = useCallback(async () => {
        await save();
        history.push(`/categories/${slug}?locale=${locale}`);
    }, [save, history, slug, locale]);

    const [isSlugChanged, setIsSlugChanged] = useState(false);

    const handleTitleChange = useCallback(
        async (e) => {
            const title = e.target.value.slice(0, 156);

            if (!isNew) {
                handleChange(`content.${locale}.title`)(title);
                return;
            }

            let slug;

            if (locale === "ru" && !title) {
                slug = getSlug(content["uk"]?.title || "");
            }

            if ((locale === "ru" && title) || !content["ru"]?.title) {
                slug = getSlug(title);
            }

            if (slug && !isSlugChanged) {
                handleMultipleChange({
                    slug,
                    [`content.${locale}.title`]: title,
                });
                return;
            }

            handleChange(`content.${locale}.title`)(title);
        },
        [
            content,
            handleChange,
            handleMultipleChange,
            isNew,
            isSlugChanged,
            locale,
        ]
    );

    const handleReload = useCallback(() => refetch && refetch(), [refetch]);

    const handleParentCategoryChange = useCallback(
        (e, category) => {
            if (category) {
                handleMultipleChange({
                    parentCategoryId: category.id,
                    type: "secondary",
                });
                return;
            }

            handleChange("parentCategoryId")(category?.id || null);
        },
        [handleChange, handleMultipleChange]
    );

    const handleSlugChange = useCallback(
        (e) => {
            setIsSlugChanged(true);
            handleChangeEvent("slug")(e);
        },
        [handleChangeEvent]
    );

    if (isLoading || isCategoriesLoading) {
        return <Loader isFullHeight />;
    }

    if (!category || !entity) {
        return <NotFound name="Categories" url="/categories" />;
    }

    return (
        <Paper>
            <Container>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Sticky stickyStyle={stickyStyle}>
                            <ToolbarContainer>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    onClick={isNew ? handleSave : save}
                                    disabled={
                                        (isNew && !slug) ||
                                        !isChanged ||
                                        isSaving
                                    }
                                    endIcon={
                                        isSaving && (
                                            <CircularProgress size={20} />
                                        )
                                    }
                                >
                                    Save
                                </Button>
                                <Button
                                    size="large"
                                    color="secondary"
                                    onClick={isNew ? goBack : reset}
                                    disabled={
                                        !isNew && (!isChanged || isSaving)
                                    }
                                >
                                    Cancel
                                </Button>
                                {refetch && (
                                    <Tooltip title="Reload category (all changes will be cancelled)">
                                        <IconButton
                                            color="primary"
                                            onClick={handleReload}
                                        >
                                            <RefreshIcon />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </ToolbarContainer>
                        </Sticky>
                        <Typography variant="h5">{slug}</Typography>
                        {entity.createdAt && (
                            <Typography variant="caption">
                                Created at {formatDate(entity.createdAt)}
                                {entity.createdBy &&
                                    ` by ${
                                        [
                                            entity.createdBy.firstName,
                                            entity.createdBy.lastName,
                                        ].join(" ") || "Unknown"
                                    }`}
                            </Typography>
                        )}
                        {entity.updatedAt && (
                            <Typography variant="caption" component="div">
                                Last updated at {formatDate(entity.updatedAt)}
                                {entity.updatedBy &&
                                    ` by ${
                                        [
                                            entity.updatedBy.firstName,
                                            entity.updatedBy.lastName,
                                        ].join(" ") || "Unknown"
                                    }`}
                            </Typography>
                        )}
                    </Grid>
                    {categories && (
                        <Grid item xs={12} md={6} lg={3}>
                            <Autocomplete
                                fullWidth
                                disabled={isSaving}
                                options={categories}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Parent category"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                )}
                                getOptionLabel={({ slug }) => slug}
                                value={categoriesById[entity.parentCategoryId]}
                                onChange={handleParentCategoryChange}
                            />
                        </Grid>
                    )}
                    <Grid item xs={12} md={6} lg={3}>
                        <FormControl fullWidth>
                            <InputLabel id="type-select" shrink>
                                Type
                            </InputLabel>
                            <Select
                                labelId="type-select"
                                value={entity.type}
                                onChange={handleChangeEvent("type")}
                                disabled={isSaving}
                            >
                                {(entity.parentCategoryId
                                    ? ["secondary"]
                                    : ["main", "secondary"]
                                ).map((type) => (
                                    <MenuItem value={type} key={type}>
                                        {capitalize(type)}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <FormControl fullWidth>
                            <InputLabel id="showIn-select" shrink>
                                Show in:
                            </InputLabel>
                            <Select
                                labelId="showIn-select-label"
                                value={entity.showIn}
                                multiple
                                onChange={handleChangeEvent("showIn")}
                                input={<Input />}
                                renderValue={(selectedValues) =>
                                    selectedValues.join(", ")
                                }
                                disabled={isSaving || category.type !== "main"}
                            >
                                {showInSelect.map((location) => (
                                    <MenuItem value={location} key={location}>
                                        <Checkbox
                                            checked={entity.showIn.includes(
                                                location
                                            )}
                                        />
                                        <ListItemText primary={location} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <FormControl fullWidth disabled={isSaving}>
                            <InputLabel id="entities-select-label" shrink>
                                Entities
                            </InputLabel>
                            <Select
                                labelId="entities-select-label"
                                multiple
                                onChange={handleChangeEvent("entities")}
                                input={<Input />}
                                value={entity.entities}
                                renderValue={(selectedValues) =>
                                    selectedValues.join(", ")
                                }
                            >
                                {entities.map((name) => (
                                    <MenuItem key={name} value={name}>
                                        <Checkbox
                                            checked={entity.entities.includes(
                                                name
                                            )}
                                        />
                                        <ListItemText primary={name} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} md={3} lg={3}>
                        <TextField
                            fullWidth
                            label="Order"
                            type="number"
                            InputLabelProps={{ shrink: true }}
                            value={entity.order}
                            onChange={handleChangeEvent("order")}
                            disabled={isSaving}
                        />
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <TextField
                            fullWidth
                            label="Slug"
                            InputLabelProps={{ shrink: true }}
                            value={entity.slug}
                            onChange={handleSlugChange}
                            disabled={isSaving}
                            error={!entity.slug}
                            helperText={!entity.slug && "Is required"}
                        />
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color="primary"
                                    checked={entity.show}
                                    onChange={handleChangeEvent("show")}
                                    disabled={isSaving}
                                />
                            }
                            label="Is shown"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid
                        item
                        container
                        xs={12}
                        spacing={2}
                        alignItems="center"
                    >
                        <Grid item>
                            <Typography variant="h5">Locale: </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                            {languageChangeComponent}
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h5">Content</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Title"
                            InputLabelProps={{ shrink: true }}
                            value={content[locale]?.title || ""}
                            onChange={handleTitleChange}
                            disabled={isSaving}
                            error={!isSlugChanged && !slug}
                            helperText={
                                !isSlugChanged && !slug && "Is required"
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h5">SEO</Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="Meta title"
                            InputLabelProps={{ shrink: true }}
                            value={content[locale]?.metaTitle || ""}
                            onChange={handleChangeEvent(
                                `content.${locale}.metaTitle`
                            )}
                            disabled={isSaving}
                            helperText="Leave blank if you are not sure what it is"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="H1 title"
                            InputLabelProps={{ shrink: true }}
                            value={content[locale]?.h1Title || ""}
                            onChange={handleChangeEvent(
                                `content.${locale}.h1Title`
                            )}
                            disabled={isSaving}
                            helperText="Leave blank if you are not sure what it is"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="Meta description"
                            InputLabelProps={{ shrink: true }}
                            value={content[locale]?.metaDescription || ""}
                            onChange={handleChangeEvent(
                                `content.${locale}.metaDescription`
                            )}
                            disabled={isSaving}
                            helperText="Leave blank if you are not sure what it is"
                            multiline
                            rows={4}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="Footer"
                            InputLabelProps={{ shrink: true }}
                            value={content[locale]?.footer || ""}
                            onChange={handleChangeEvent(
                                `content.${locale}.footer`
                            )}
                            disabled={isSaving}
                            helperText="Leave blank if you are not sure what it is"
                            multiline
                            rows={4}
                        />
                    </Grid>
                    {entity.entities.includes("forecast") && (
                        <>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1">
                                    Forecast
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    label="Meta title"
                                    InputLabelProps={{ shrink: true }}
                                    value={
                                        content[locale]?.forecastMetaTitle || ""
                                    }
                                    onChange={handleChangeEvent(
                                        `content.${locale}.forecastMetaTitle`
                                    )}
                                    disabled={isSaving}
                                    helperText="Leave blank if you are not sure what it is"
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    label="H1 title"
                                    InputLabelProps={{ shrink: true }}
                                    value={
                                        content[locale]?.forecastH1Title || ""
                                    }
                                    onChange={handleChangeEvent(
                                        `content.${locale}.forecastH1Title`
                                    )}
                                    disabled={isSaving}
                                    helperText="Leave blank if you are not sure what it is"
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    label="Meta description"
                                    InputLabelProps={{ shrink: true }}
                                    value={
                                        content[locale]
                                            ?.forecastMetaDescription || ""
                                    }
                                    onChange={handleChangeEvent(
                                        `content.${locale}.forecastMetaDescription`
                                    )}
                                    disabled={isSaving}
                                    helperText="Leave blank if you are not sure what it is"
                                    multiline
                                    rows={4}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    label="Footer"
                                    InputLabelProps={{ shrink: true }}
                                    value={
                                        content[locale]?.forecastFooter || ""
                                    }
                                    onChange={handleChangeEvent(
                                        `content.${locale}.forecastFooter`
                                    )}
                                    disabled={isSaving}
                                    helperText="Leave blank if you are not sure what it is"
                                    multiline
                                    rows={4}
                                />
                            </Grid>
                        </>
                    )}
                </Grid>
            </Container>
        </Paper>
    );
};

type CategoryEditPageProps = {
    match: Match,
};

const CategoryEditPage = withErrorHandler(
    ({ match }: CategoryEditPageProps) => {
        const fetchProps = useAPI<{ category: Category }>({
            url: `/content/categories/${String(match.params.slug)}`,
        });

        return (
            <CategoriesProvider>
                <CategoryPage {...fetchProps} />
            </CategoriesProvider>
        );
    }
);

const CategoryCreatePage = withErrorHandler(() => {
    return (
        <CategoriesProvider>
            <CategoryPage
                data={{ category: getEntity({ category: {} }) }}
                isLoading={false}
                isNew
            />
        </CategoriesProvider>
    );
});

export { CategoryCreatePage, CategoryEditPage };
