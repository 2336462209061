// @flow

import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";

import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";

import CreateIcon from "@material-ui/icons/Create";

import Table, {
    type Column,
    dataTypes,
} from "../../shared/components/SimpleTable";
import SectionToolbar from "../../shared/components/SectionToolbar";
import type { Forecast } from "./context/forecasts";
import { useForecasts, Provider } from "./context/forecasts";
import map from "lodash/map";
import { useCurrentUser } from "../Auth/context/currentUser";
import TableToolbar from "../../shared/components/TableToolbar";
import {
    useCategories,
    Provider as CategoriesProvider,
} from "../context/categories";

const columns: Column[] = [
    {
        label: "Id",
        name: "id",
    },
    {
        label: "Slug",
        name: "slug",
    },
    {
        formatter: ({ mainContent }) =>
            map(mainContent, "locale").sort().join(", "),
        isSortable: false,
        label: "Languages",
        name: "locales",
    },
    {
        label: "Status",
        name: "status",
    },
    {
        label: "Comments status",
        name: "commentStatus",
    },
    {
        isSortable: false,
        label: "Coefficient",
        name: "coefficient.value",
    },
    {
        isSortable: false,
        label: "Bookmaker, casino",
        name: "coefficient.review.slug",
    },
    {
        dataType: dataTypes.DATE_TIME,
        label: "Event date",
        name: "eventDate",
    },
    {
        dataType: dataTypes.DATE_TIME,
        label: "Created At",
        name: "createdAt",
    },
    {
        formatter: ({ author }) =>
            [author?.firstName, author?.lastName].join(" "),
        isSortable: false,
        label: "Author",
        name: "author",
    },
    {
        dataType: dataTypes.DATE_TIME,
        label: "Updated At",
        name: "updatedAt",
    },
    {
        formatter: ({ updatedBy }) =>
            [updatedBy?.firstName, updatedBy?.lastName].join(" "),
        isSortable: false,
        label: "Updated By",
        name: "updatedBy",
    },
];

const ForecastsSection = () => {
    const {
        authors,
        forecasts,
        count,
        isLoading,
        refetch,
        deleteForecast,
    } = useForecasts();
    const { categories } = useCategories();
    const { currentUser } = useCurrentUser();
    const history = useHistory();

    const handleEdit = useCallback(
        ({ slug }: Forecast) => {
            history.push(`/forecasts/${slug}`);
        },
        [history]
    );

    const handleCreate = useCallback(() => {
        history.push("/forecasts/new");
    }, [history]);

    return (
        <Paper>
            <SectionToolbar
                title={<>Forecasts {!isLoading && <>({count})</>}</>}
                refetch={refetch}
            >
                <TableToolbar
                    refetch={refetch}
                    authors={authors}
                    categories={categories}
                />
                <Button
                    variant="contained"
                    color="primary"
                    endIcon={<CreateIcon />}
                    onClick={handleCreate}
                >
                    Create forecast
                </Button>
            </SectionToolbar>
            <Table
                columns={columns}
                data={forecasts}
                count={count}
                refetch={refetch}
                isLoading={isLoading}
                onEdit={handleEdit}
                onItemDelete={
                    currentUser && currentUser.roles.includes("superAdmin")
                        ? deleteForecast
                        : undefined
                }
            />
        </Paper>
    );
};

export default () => (
    <Provider>
        <CategoriesProvider entity="forecast">
            <ForecastsSection />
        </CategoriesProvider>
    </Provider>
);
