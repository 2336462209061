// @flow
import React, { useMemo } from "react";
import styled from "styled-components/macro";
import Sticky from "react-sticky-el";

import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import RefreshIcon from "@material-ui/icons/Refresh";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import formatDate from "../../utils/formatDate";
import Loader from "../../shared/components/Loader";
import useAPI from "../../shared/hooks/useAPI";
import withErrorHandler from "../../shared/hocs/withErrorHandler";
import useCRUD from "../../shared/hooks/useCRUD";
import type { RefetchType } from "../../shared/hooks/useAPI";
import ImageUpload from "../../shared/components/ImageUpload";
import { useConfig } from "../context/config";
import type { Config } from "../context/config";
import type { User } from "../Reviews/context/reviews";

const Container = styled.div`
    padding: 15px;
`;

const ToolbarContainer = styled.div`
    > * {
        margin-right: 5px;
    }
`;

type Banner = {
    id: string,
    ctaUrl: ?string,
    image: string,
    isEnabled: boolean,
    createdAt: string,
    updatedAt: string,
    createdBy: User,
    updatedBy: ?User,
};

type Props = {
    isNew?: boolean,
    data: ?{ list: Banner[] },
    isLoading: boolean,
    refetch?: RefetchType,
};

const stickyStyle = {
    backgroundColor: "white",
    paddingBottom: 15,
    paddingTop: 77,
    zIndex: 2,
};

const getEntity = ({ banner, config }: { banner: ?Banner, config: ?Config }) =>
    banner
        ? {
              ctaUrl: banner.ctaUrl,
              image:
                  banner.image &&
                  `https://${config.filesBucketName}${banner.image}`,
              isEnabled: Boolean(banner.isEnabled),
              updatedAt: banner.updatedAt,
              updatedBy: banner.updatedBy,
          }
        : {
              ctaUrl: "",
              isEnabled: false,
          };

const prepareUpdateBody = ({ image, ctaUrl, isEnabled } = {}) => ({
    banner: {
        ctaUrl,
        image: new URL(image).pathname,
        isEnabled,
    },
});

const BannerPage = ({ data, isLoading, refetch, isNew = false }: Props) => {
    const banner = data?.list?.[0];
    const { config } = useConfig();
    const initialEntity = useMemo(() => getEntity({ banner, config }), [
        banner,
        config,
    ]);

    const {
        entity,
        handleChangeEvent,
        handleChange,
        isChanged,
        save,
        reset,
        isLoading: isSaving,
    } = useCRUD<Banner>({
        entity: initialEntity,
        prepareBody: prepareUpdateBody,
        saveMethod: "PATCH",
        url: `/promo/banners`,
    });

    const { image, ctaUrl, isEnabled } = entity || {};

    if (isLoading) {
        return <Loader isFullHeight />;
    }

    return (
        <Paper>
            <Container>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Sticky stickyStyle={stickyStyle}>
                            <ToolbarContainer>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    onClick={save}
                                    endIcon={
                                        isSaving && (
                                            <CircularProgress size={20} />
                                        )
                                    }
                                    disabled={!image || !isChanged || isSaving}
                                >
                                    Save
                                </Button>
                                <Button
                                    size="large"
                                    color="secondary"
                                    onClick={reset}
                                    disabled={!isChanged || isSaving}
                                >
                                    Cancel
                                </Button>
                                {refetch && (
                                    <Tooltip title="Reload banner (all changes will be cancelled)">
                                        <IconButton
                                            color="primary"
                                            onClick={refetch}
                                        >
                                            <RefreshIcon />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </ToolbarContainer>
                        </Sticky>
                        {entity.updatedAt && (
                            <Typography variant="caption" component="div">
                                Last updated at {formatDate(entity.updatedAt)}
                                {entity.updatedBy &&
                                    ` by ${
                                        [
                                            entity.updatedBy.firstName,
                                            entity.updatedBy.lastName,
                                        ].join(" ") || "Unknown"
                                    }`}
                            </Typography>
                        )}
                    </Grid>
                    <Grid container item xs={12} lg={4} md={6}>
                        <Grid item xs={12}>
                            <Typography variant="caption" color="textSecondary">
                                Image
                            </Typography>
                        </Grid>
                        <ImageUpload
                            prefix={`images/banners`}
                            value={image}
                            onChange={handleChange("image")}
                            disabled={isSaving}
                            errorMessage={!image ? "Is required" : undefined}
                            styles={{
                                height: 495,
                                width: 312,
                            }}
                        />
                    </Grid>
                    <Grid item container spacing={2} xs={12} md={6} lg={8}>
                        <Grid item xs={12} md={8}>
                            <TextField
                                fullWidth
                                label="CTA Url"
                                InputLabelProps={{ shrink: true }}
                                value={ctaUrl}
                                onChange={handleChangeEvent("ctaUrl")}
                                disabled={isSaving}
                                helperText={
                                    !ctaUrl
                                        ? "Is required"
                                        : "Started with http:// or https:// or / in case if url related to our website"
                                }
                                error={!ctaUrl}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        color="primary"
                                        checked={isEnabled}
                                        onChange={handleChangeEvent(
                                            "isEnabled"
                                        )}
                                        disabled={isSaving}
                                    />
                                }
                                label="Is shown"
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Paper>
    );
};

const BannerEditPage = withErrorHandler(() => {
    const fetchProps = useAPI<{ list: Banner[], count: number }>({
        url: `/promo/banners/all`,
    });

    return <BannerPage {...fetchProps} />;
});

export { BannerEditPage };
