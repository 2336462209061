// @flow

import React, { createContext, useContext } from "react";
import noop from "lodash/noop";
import mapValues from "lodash/mapValues";
import keyBy from "lodash/keyBy";

import useAPI from "../../../shared/hooks/useAPI";
import type { PaginationProps } from "../../../shared/hooks/useAPI";

export const complaintStatuses = {
    NEW: "new",
    // eslint-disable-next-line sort-keys
    ACTIVE: "active",
    UNREASONABLE: "unreasonable",
    // eslint-disable-next-line sort-keys
    REJECTED: "rejected",
    // eslint-disable-next-line sort-keys
    FULFILLED: "fulfilled",
};

export const closedStatuses = [
    complaintStatuses.FULFILLED,
    complaintStatuses.REJECTED,
    complaintStatuses.UNREASONABLE,
];

export const complaintStatusesList = Object.values(complaintStatuses);

export type Author = {
    id: string,
    firstName: string,
    lastName: string,
    avatarUrl?: string,
};

export type Complaint = {
    id: string,
    status: $Values<complaintStatuses>,
    sum: number,
    currency: string,
    reviewId: string,
    show: boolean,
    content: string,
    finalComment: string,
    userIdOnOperatorSide: string,
    createdAt: string,
    updatedAt: string,
};

type ComplaintsContext = {
    count: number,
    complaints: ?(Complaint[]),
    error: ?Error,
    isLoading: boolean,
    refetch: (PaginationProps) => Promise<void>,
    countByStatuses: { [$Values<complaintStatuses>]: number },
};

const Context = createContext<ComplaintsContext>({
    complaints: null,
    count: 0,
    countByStatuses: null,
    error: null,
    isLoading: false,
    refetch: noop,
});

const ComplaintsProvider = ({
    children,
    statuses = [],
}: {
    children: *,
    statuses?: $Values<complaintStatuses>[],
}) => {
    const { data, isLoading, error, refetch } = useAPI<{
        count: number,
        list: Complaint[],
    }>({
        url: `/content/reviews/complaints/all?withReview=yes&statuses=${statuses.join(
            ","
        )}`,
    });

    return (
        <Context.Provider
            value={{
                complaints: data?.list,
                count: data?.count || 0,
                countByStatuses: mapValues(
                    keyBy(data?.statusAmount, "status"),
                    "sum"
                ),
                error,
                isLoading: isLoading,
                refetch,
            }}
        >
            {children}
        </Context.Provider>
    );
};

const useComplaints = () => useContext<ComplaintsContext>(Context);

export { ComplaintsProvider as Provider, useComplaints };
