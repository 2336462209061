import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import pickBy from "lodash/pickBy";
import identity from "lodash/identity";
import addDays from "date-fns/addDays";
import startOfISOWeek from "date-fns/startOfISOWeek";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import addMilliseconds from "date-fns/addMilliseconds";
import { DatePicker } from "@material-ui/pickers";

import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

import ClearIcon from "@material-ui/icons/Clear";

import useAPI from "../../shared/hooks/useAPI";
import Loader from "../../shared/components/Loader";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";

const periodFilters = {
    currentDay: {
        params: () => {
            const today = new Date(new Date().setHours(0, 0, 0, 0));
            const tomorrow = addDays(today, 1);
            return {
                endDate: tomorrow.toISOString(),
                startDate: today.toISOString(),
            };
        },
        title: "Current day",
    },
    prevDay: {
        params: () => {
            const today = new Date(new Date().setHours(0, 0, 0, 0));
            const yesterday = addDays(today, -1);
            return {
                endDate: today.toISOString(),
                startDate: yesterday.toISOString(),
            };
        },
        title: "Previous day",
    },
    // eslint-disable-next-line sort-keys
    currentWeek: {
        params: () => {
            const startOfWeek = startOfISOWeek(new Date());
            const startOfNextWeek = addDays(startOfWeek, 7);
            return {
                endDate: startOfNextWeek.toISOString(),
                startDate: startOfWeek.toISOString(),
            };
        },
        title: "Current week",
    },
    prevWeek: {
        params: () => {
            const dayOnPrevWeek = addDays(new Date(), -7);
            const startOfPrevWeek = startOfISOWeek(dayOnPrevWeek);
            const startOfWeek = startOfISOWeek(new Date());
            return {
                endDate: startOfWeek.toISOString(),
                startDate: startOfPrevWeek.toISOString(),
            };
        },
        title: "Previous week",
    },
    // eslint-disable-next-line sort-keys
    currentMonth: {
        params: () => {
            const startOfMonthDate = startOfMonth(new Date());
            const endOfMonthDate = endOfMonth(new Date());
            const startOfNextMonthDate = addMilliseconds(endOfMonthDate, 1);
            return {
                endDate: startOfNextMonthDate.toISOString(),
                startDate: startOfMonthDate.toISOString(),
            };
        },
        title: "Current month",
    },
    prevMonth: {
        params: () => {
            const startOfMonthDate = startOfMonth(new Date());
            const prevMonthDate = addDays(startOfMonthDate, -1);
            const startOfPrevMonthDate = startOfMonth(prevMonthDate);
            return {
                endDate: startOfMonthDate.toISOString(),
                startDate: startOfPrevMonthDate.toISOString(),
            };
        },
        title: "Previous month",
    },
};

const StatsPage = () => {
    const history = useHistory();
    const location = useLocation();

    const [period, setPeriod] = useState(() => {
        const param = new URLSearchParams(location.search || "").get("period");
        return periodFilters[param] || param === "custom"
            ? param
            : "currentDay";
    });
    const [startDate, setStartDate] = useState(() => {
        const param = new URLSearchParams(location.search || "").get(
            "startDate"
        );
        return Date.parse(param) ? new Date(param) : null;
    });
    const [endDate, setEndDate] = useState(() => {
        const param = new URLSearchParams(location.search || "").get("endDate");
        return Date.parse(param) ? new Date(param) : null;
    });
    const [params, setParams] = useState(
        new URLSearchParams(
            Object.entries(
                period === "custom"
                    ? pickBy(
                          {
                              endDate: endDate && endDate.toISOString(),
                              startDate: startDate && startDate.toISOString(),
                          },
                          identity
                      )
                    : periodFilters[period]?.params()
            )
        )
    );

    useEffect(() => {
        history.replace({
            pathname: "/stats",
            search: `?${new URLSearchParams(
                Object.entries({
                    period,
                    ...(period === "custom" &&
                        pickBy(
                            {
                                endDate: endDate && endDate.toISOString(),
                                startDate: startDate && startDate.toISOString(),
                            },
                            identity
                        )),
                })
            )}`,
        });

        if (period === "custom") {
            return;
        }
        setParams(
            new URLSearchParams(Object.entries(periodFilters[period].params()))
        );
    }, [endDate, history, period, startDate]);

    const handlePeriodChange = useCallback((e) => {
        setPeriod(e.target.value);
        setStartDate(null);
        setEndDate(null);
    }, []);

    const handleApplyDates = useCallback(() => {
        setParams(
            new URLSearchParams(
                Object.entries(
                    pickBy(
                        {
                            endDate:
                                endDate &&
                                new Date(
                                    endDate.setHours(0, 0, 0, 0)
                                ).toISOString(),
                            startDate:
                                startDate &&
                                new Date(
                                    startDate.setHours(0, 0, 0, 0)
                                ).toISOString(),
                        },
                        identity
                    )
                )
            )
        );
    }, [endDate, startDate]);

    const { data: postsStats, isLoading: isPostsStatsLoading } = useAPI({
        url: `/content/posts/stats?${params}`,
    });

    const { data: reviewsStats, isLoading: isReviewsStatsLoading } = useAPI({
        url: `/content/reviews/stats?${params}`,
    });

    const { data: forecastStats, isLoading: isForecastStatsLoading } = useAPI({
        url: `/content/forecasts/stats?${params}`,
    });

    const {
        data: knowledgeBaseStats,
        isLoading: isKnowledgeBaseStatsLoading,
    } = useAPI({
        url: `/content/knowledge-bases/stats?${params}`,
    });

    const data = [
        {
            data: postsStats,
            isLoading: isPostsStatsLoading,
            title: "Posts",
        },
        {
            data: reviewsStats,
            isLoading: isReviewsStatsLoading,
            title: "Reviews",
        },
        {
            data: forecastStats,
            isLoading: isForecastStatsLoading,
            title: "Forecasts",
        },
        {
            data: knowledgeBaseStats,
            isLoading: isKnowledgeBaseStatsLoading,
            title: "Knowledge base",
        },
    ];

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} spacing={2} container>
                <Grid item>
                    <FormControl style={{ minWidth: 300 }}>
                        <InputLabel id="period-select-label">Period</InputLabel>
                        <Select
                            labelId="period-select-label"
                            id="period-select"
                            value={period}
                            onChange={handlePeriodChange}
                        >
                            {Object.keys(periodFilters).map((periodName) => (
                                <MenuItem value={periodName} key={periodName}>
                                    {periodFilters[periodName].title}
                                </MenuItem>
                            ))}
                            <MenuItem value="custom">Custom dates</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                {period === "custom" && (
                    <>
                        <Grid item>
                            <DatePicker
                                InputLabelProps={{ shrink: true }}
                                format="dd.MM.yyyy"
                                variant="inline"
                                label="Start date"
                                value={startDate}
                                onChange={setStartDate}
                                disableFuture
                                autoOk
                                maxDate={endDate && addDays(endDate, -1)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setStartDate(null);
                                                }}
                                                size="small"
                                                disabled={!startDate}
                                            >
                                                <ClearIcon fontSize="small" />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <DatePicker
                                InputLabelProps={{ shrink: true }}
                                format="dd.MM.yyyy"
                                variant="inline"
                                label="End date"
                                value={endDate}
                                onChange={setEndDate}
                                disableFuture
                                autoOk
                                minDate={startDate && addDays(startDate, 1)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setEndDate(null);
                                                }}
                                                size="small"
                                                disabled={!endDate}
                                            >
                                                <ClearIcon fontSize="small" />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <Box
                                display="flex"
                                height="100%"
                                alignItems="center"
                            >
                                <Button
                                    variant="contained"
                                    onClick={handleApplyDates}
                                    color="primary"
                                    disabled={!startDate && !endDate}
                                >
                                    Apply
                                </Button>
                            </Box>
                        </Grid>
                    </>
                )}
            </Grid>
            {data.map(({ data, isLoading, title }) => (
                <Grid item xs={12} md={4} sm={6} key={title}>
                    <Paper>
                        <Box p={2} pb={0}>
                            <Typography variant="h6">{title}</Typography>
                        </Box>
                        {!isLoading && data ? (
                            data.length ? (
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Author</TableCell>
                                            <TableCell>Count</TableCell>
                                            <TableCell>Characters</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data.map(
                                            ({ author, count, chars }) => (
                                                <TableRow>
                                                    <TableCell>
                                                        {[
                                                            author.firstName,
                                                            author.lastName,
                                                        ].join(" ")}
                                                    </TableCell>
                                                    <TableCell>
                                                        {count}
                                                    </TableCell>
                                                    <TableCell>
                                                        {chars}
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        )}
                                    </TableBody>
                                </Table>
                            ) : (
                                <Box
                                    p={7}
                                    display="flex"
                                    justifyContent="center"
                                >
                                    <Typography variant="subtitle2">
                                        No data yet...{" "}
                                        <span role="img" aria-label="Sad">
                                            😞
                                        </span>
                                    </Typography>
                                </Box>
                            )
                        ) : (
                            <Loader />
                        )}
                    </Paper>
                </Grid>
            ))}
        </Grid>
    );
};

export { StatsPage };
