// @flow

import React, { useMemo, useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { globalLanguages } from "../../contants";

const languages = globalLanguages

type Props<T> = {
    entity: T,
    isNew: boolean,
    isSaving: boolean,
};

const useContent = <T>({ entity, isNew, isSaving }: Props<T>) => {
    const content = entity?.content;
    const contentLocale = content?.[0]?.locale;

    const { search, pathname } = useLocation();

    const urlLocale = useMemo(() => {
        let locale = new URLSearchParams(search).get("locale");

        return Object.keys(languages).includes(locale) ? locale : null;
    }, [search]);

    const [locale, setLocale] = useState(urlLocale || contentLocale || "ru");

    useEffect(() => {
        if (contentLocale && !urlLocale) {
            setLocale(contentLocale);
        }
    }, [contentLocale, urlLocale]);

    const handleLocaleChange = useCallback(
        (e: SyntheticInputEvent<HTMLSelectElement>) => {
            window.history.replaceState(
                {},
                null,
                `${pathname}?locale=${e.target.value}`
            );
            setLocale(e.target.value);
        },
        [pathname]
    );

    const languageChangeComponent = useMemo(
        () => (
            <FormControl fullWidth>
                <InputLabel id="language-select">Language</InputLabel>
                <Select
                    labelId="language-select"
                    value={locale}
                    onChange={handleLocaleChange}
                    disabled={isSaving}
                >
                    {Object.keys(languages).map((locale) => (
                        <MenuItem value={locale} key={locale}>
                            {languages[locale]?.label || locale}
                            {!isNew && !content?.[locale] && " (missed 😢)"}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        ),
        [locale, handleLocaleChange, isSaving, isNew, content]
    );

    return {
        handleLocaleChange,
        languageChangeComponent,
        locale,
    };
};

export default useContent;
