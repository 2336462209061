// @flow
import React, { useState, useCallback, useMemo, useEffect } from 'react'
import { type Match, useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components/macro'
import capitalize from 'lodash/capitalize'
import fpKeyBy from 'lodash/fp/keyBy'
import compose from 'lodash/fp/compose'
import fpMap from 'lodash/fp/map'
import fpPick from 'lodash/fp/pick'
import keyBy from 'lodash/keyBy'
import pickBy from 'lodash/pickBy'
import identity from 'lodash/identity'
import mapValues from 'lodash/mapValues'
import InputMask from 'react-input-mask'
import { DatePicker } from '@material-ui/pickers'
import Sticky from 'react-sticky-el'

import Rating from '@material-ui/lab/Rating'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import RefreshIcon from '@material-ui/icons/Refresh'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import CircularProgress from '@material-ui/core/CircularProgress'
import Input from '@material-ui/core/Input'
import Checkbox from '@material-ui/core/Checkbox'
import ListItemText from '@material-ui/core/ListItemText'
import Box from '@material-ui/core/Box'
import { lightGreen, green, red } from '@material-ui/core/colors'

import AndroidIcon from '@material-ui/icons/Android'
import AppleIcon from '@material-ui/icons/Apple'
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail'
import PhoneIcon from '@material-ui/icons/Phone'
import EventAvailableIcon from '@material-ui/icons/EventAvailable'
import EventBusyIcon from '@material-ui/icons/EventBusy'
import BusinessIcon from '@material-ui/icons/Business'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'

import formatDate from '../../utils/formatDate'
import Loader from '../../shared/components/Loader'
import ContentEditor from '../../shared/components/ContentEditor'
import useAPI from '../../shared/hooks/useAPI'
import { type Review } from './context/reviews'
import { type Config, useConfig } from '../context/config'
import withErrorHandler from '../../shared/hocs/withErrorHandler'
import {
    useCategories,
    Provider as CategoriesProvider,
} from '../context/categories'
import useCRUD from '../../shared/hooks/useCRUD'
import ImageUpload from '../../shared/components/ImageUpload'
import type { RatingType } from './context/reviews'
import WindowsIcon from '../../shared/icons/WindowsIcon'
import InputAdornment from '@material-ui/core/InputAdornment'
import FormHelperText from '@material-ui/core/FormHelperText'

import stateToHTML from '../../utils/stateToHTML'
import Payments from './Payments'
import NotFound from '../../shared/components/NotFound'
import type { RefetchType } from '../../shared/hooks/useAPI'
import StatusSelect from '../../shared/components/StatusSelect'
import { globalLanguages } from '../../contants'
import getSlug from '../../utils/getSlug'
import EditorComponent from '../../shared/components/Editor'

const Container = styled.div`
    padding: 15px;
`

const ToolbarContainer = styled.div`
    > * {
        margin-right: 5px;
    }
`

const RatingContainer = styled.div`
    display: flex;
    align-items: center;
`

type Props = {
    isNew?: boolean,
    data: { review: ?Review },
    isLoading: boolean,
    refetch?: RefetchType,
}

const languages = globalLanguages

const ratingTypes = {
    bookmaker: {
        RELIABILITY: 'reliability',
        // eslint-disable-next-line sort-keys
        BONUSES: 'bonuses',
        SUPPORT: 'support',
        // eslint-disable-next-line sort-keys
        COEFFICIENTS: 'coefficients',
        // eslint-disable-next-line sort-keys
        BETS_VARIETY: 'bets_variety',
        WITHDRAWAL: 'withdrawal',
    },
    casino: {
        RELIABILITY: 'reliability',
        // eslint-disable-next-line sort-keys
        BONUSES: 'bonuses',
        PROVIDERS: 'providers',
        // eslint-disable-next-line sort-keys
        GAMES_VARIETY: 'games_variety',
        SUPPORT: 'support',
        WITHDRAWAL: 'withdrawal',
    },
}

const applicationTypes = {
    ANDROID: 'android',
    IOS: 'ios',
    WINDOWS: 'windows',
}

const applications = {
    [applicationTypes.IOS]: {
        icon: <AppleIcon />,
        title: 'IOS app link',
    },
    [applicationTypes.ANDROID]: {
        icon: <AndroidIcon style={{ color: lightGreen[500] }} />,
        title: 'Android app link',
    },
    [applicationTypes.WINDOWS]: {
        icon: <WindowsIcon />,
        title: 'Windows app link',
    },
}

const regExpSource = [
    {
        id: 'reliability',
        keyword: new RegExp('(|A|s|-)[Нн]адежност?[^а-яА-Я]*?', 'gim'),
    },
    {
        id: 'bonuses',
        keyword: new RegExp('(|A|s|-)[Бб]онус?[^а-яА-Я]*?', 'gim'),
    },
    {
        id: 'support',
        keyword: new RegExp('(|A|s|-)[Пп]оддерж?[^а-яА-Я]*?', 'gim'),
    },
    {
        id: 'coefficients',
        keyword: new RegExp('(|A|s|-)[Кк]оэффициен?[^а-яА-Я]*?', 'gim'),
    },
    {
        id: 'bets_variety',
        keyword: new RegExp('(|A|s|-)[Сс]тав?[^а-яА-Я]*?', 'gim'),
    },
    {
        id: 'withdrawal',
        keyword: new RegExp('(|A|s|-)[Вв]ывод?[^а-яА-Я]*?', 'gim'),
    },
    {
        id: 'reliability',
        keyword: new RegExp('(|A|s|-)[Нн]адійн?[^а-яА-Я]*?', 'gim'),
    },
    {
        id: 'support',
        keyword: new RegExp('(|A|s|-)[Пп]ідтрим?[^а-яА-Я]*?', 'gim'),
    },
    {
        id: 'coefficients',
        keyword: new RegExp('(|A|s|-)[Кк]оефіціє?[^а-яА-Я]*?', 'gim'),
    },
    {
        id: 'withdrawal',
        keyword: new RegExp('(|A|s|-)[Вв]иведе?[^а-яА-Я]*?', 'gim'),
    },
    {
        id: 'providers',
        keyword: new RegExp('(|A|s|-)[Пп]ровайд?[^а-яА-Я]*?', 'gim'),
    },
    {
        id: 'games_variety',
        keyword: new RegExp('(|A|s|-)[Ии]гр?[^а-яА-Я]*?', 'gim'),
    },
    {
        id: 'games_variety',
        keyword: new RegExp('(|A|s|-)[Сс]лот?[^а-яА-Я]*?', 'gim'),
    },
    {
        id: 'games_variety',
        keyword: new RegExp('(|A|s|-)[Іі]гор?[^а-яА-Я]*?', 'gim'),
    },
    {
        id: 'reliability',
        keyword: new RegExp('(|A|s|-)[Rr]eliabilit?[^a-zA-Z]*?', 'gim'),
    },
    {
        id: 'bonuses',
        keyword: new RegExp('(|A|s|-)[Bb]onus?[^a-zA-Z]*?', 'gim'),
    },
    {
        id: 'support',
        keyword: new RegExp('(|A|s|-)[Ss]upport?[^a-zA-Z]*?', 'gim'),
    },
    {
        id: 'coefficients',
        keyword: new RegExp('(|A|s|-)[Cc]oefficient?[^a-zA-Z]*?', 'gim'),
    },
    {
        id: 'bets_variety',
        keyword: new RegExp('(|A|s|-)[Bb]et?[^a-zA-Z]*?', 'gim'),
    },
    {
        id: 'withdrawal',
        keyword: new RegExp('(|A|s|-)[Ww]ithdrawal?[^a-zA-Z]*?', 'gim'),
    },
    {
        id: 'games_variety',
        keyword: new RegExp('(|A|s|-)[Ss]lot?[^a-zA-Z]*?', 'gim'),
    },
    {
        id: 'providers',
        keyword: new RegExp('(|A|s|-)[Pp]rovider?[^a-zA-Z]*?', 'gim'),
    },
    {
        id: 'games_variety',
        keyword: new RegExp('(|A|s|-)[Gg]ame?[^a-zA-Z]*?', 'gim'),
    },
]

const stickyStyle = {
    backgroundColor: 'white',
    paddingBottom: 15,
    paddingTop: 77,
    zIndex: 2,
}

const getEntity = ({ review, config }: { review: ?Review, config: ?Config }) =>
    review
        ? {
              applications: keyBy(review.applications, 'type'),
              author: review.author,
              bonus: review.bonus,
              content: compose(
                  fpKeyBy('locale'),
                  fpMap((localeContent) => ({
                      cons: localeContent.cons.join('\n'),
                      pros: localeContent.pros.join('\n'),
                      ...fpPick(
                          [
                              'locale',
                              'title',
                              'content',
                              'createdAt',
                              'metaTitle',
                              'metaDescription',
                              'h1',
                          ],
                          localeContent,
                      ),
                  })),
              )(review.mainContent),
              images: mapValues(
                  review.images,
                  (url) => `https://${config.filesBucketName}${url}`,
              ),
              legalInfo: review.legalInfo,
              license: review.license,
              order: review.order,
              payments: (review.payments || []).map(
                  ({ replenish, withdrawal, paymentId, createdAt }) => ({
                      createdAt,
                      depositCommission: replenish?.commission,
                      depositCurrency: replenish?.sumCurrency,
                      depositPeriodFrom: replenish?.periodFrom,
                      depositPeriodTo: replenish?.periodTo,
                      depositPeriodType: replenish?.periodType || 'none',
                      depositSumFrom: replenish?.sumFrom,
                      depositSumTo: replenish?.sumTo,
                      paymentId,
                      withdrawalCommission: withdrawal?.commission,
                      withdrawalCurrency: withdrawal?.sumCurrency,
                      withdrawalPeriodFrom: withdrawal?.periodFrom,
                      withdrawalPeriodTo: withdrawal?.periodTo,
                      withdrawalPeriodType: withdrawal?.periodType || 'none',
                      withdrawalSumFrom: withdrawal?.sumFrom,
                      withdrawalSumTo: withdrawal?.sumTo,
                  }),
              ),
              ratings: keyBy(review.ratings, 'type'),
              reviewDate: review.reviewDate,
              slug: review.slug,
              status: review.status || 'created',
              support: review.support,
              tags: review.tags || [],
              title: review.title,
              type: review.type || 'bookmaker',
              websiteLink: review.websiteLink,
          }
        : null

const prepareUpdateBody = ({ content, images, payments, ...review }) => {
    Object.keys(content).forEach((key) => {
        const html = document.createElement('div')
        html.innerHTML = content[key].content
        if (html.querySelector('[data-f-id]')) {
            html.querySelector('[data-f-id]').remove()
        }
        content[key].content = html.innerHTML
    })
    return {
        applications: Object.keys(review.applications).map((type) => ({
            link: review.applications[type].link,
            type,
        })),
        content: mapValues(
            content,
            ({ createdAt, pros, cons, ...localeContent }) => ({
                ...localeContent,
                cons: cons ? cons.split(/[\n;]/).filter(Boolean) : [],
                content: !localeContent.content
                    ? ''
                    : typeof localeContent.content === 'string'
                    ? localeContent.content
                    : stateToHTML(localeContent.content.getCurrentContent()),
                pros: pros ? pros.split(/[\n;]/).filter(Boolean) : [],
            }),
        ),
        payments: payments.map(
            ({
                depositCommission,
                depositCurrency,
                depositSumFrom,
                depositSumTo,
                depositPeriodFrom,
                depositPeriodTo,
                depositPeriodType,
                image,
                paymentId,
                withdrawalCommission,
                withdrawalCurrency,
                withdrawalSumFrom,
                withdrawalSumTo,
                withdrawalPeriodFrom,
                withdrawalPeriodTo,
                withdrawalPeriodType,
            }) => ({
                image,
                paymentId,
                replenish: pickBy(
                    {
                        commission: depositCommission,
                        periodFrom: depositPeriodFrom,
                        periodTo: depositPeriodTo,
                        periodType:
                            depositPeriodType === 'none'
                                ? undefined
                                : depositPeriodType,
                        sumCurrency: depositCurrency,
                        sumFrom: depositSumFrom,
                        sumTo: depositSumTo,
                    },
                    identity,
                ),
                withdrawal: pickBy(
                    {
                        commission: withdrawalCommission,
                        periodFrom: withdrawalPeriodFrom,
                        periodTo: withdrawalPeriodTo,
                        periodType:
                            withdrawalPeriodType === 'none'
                                ? undefined
                                : withdrawalPeriodType,
                        sumCurrency: withdrawalCurrency,
                        sumFrom: withdrawalSumFrom,
                        sumTo: withdrawalSumTo,
                    },
                    identity,
                ),
            }),
        ),
        ratings: Object.keys(review.ratings).map((type) => ({
            type,
            value: review.ratings[type].value,
        })),
        review: {
            ...review,
            ...(review.bonus && {
                bonus: {
                    ...fpPick(['currency', 'amount', 'link'], review.bonus),
                    ...(review.bonus.amount && {
                        amount: parseFloat(review.bonus.amount),
                    }),
                },
            }),
            images: mapValues(images, (url) => new URL(url).pathname),
            order: parseFloat(review.order),
        },
    }
}

const ReviewPage = ({ data, isLoading, refetch, isNew = false }: Props) => {
    const { config } = useConfig()
    const { categories, isLoading: isCategoriesLoading } = useCategories()
    const review = data?.review

    const initialEntity = useMemo(() => getEntity({ config, review }), [
        config,
        review,
    ])

    const {
        entity,
        handleChangeEvent,
        handleChange,
        handleMultipleChange,
        isChanged,
        save,
        reset,
        isLoading: isSaving,
    } = useCRUD({
        entity: initialEntity,
        prepareBody: prepareUpdateBody,
        saveMethod: isNew ? 'POST' : 'PUT',
        url: `/content/reviews${isNew ? '' : `/${String(review?.id)}`}`,
    })

    const contentLocale = review?.mainContent?.[0]?.locale

    const { search } = useLocation()
    const history = useHistory()

    const urlLocale = useMemo(() => {
        let locale = new URLSearchParams(search).get('locale')

        return Object.keys(languages).includes(locale) ? locale : null
    }, [search])

    const [locale, setLocale] = useState(urlLocale || contentLocale || 'ru')

    useEffect(() => {
        if (contentLocale && !urlLocale) {
            setLocale(contentLocale)
        }
    }, [contentLocale, urlLocale])

    const { slug, title, content, payments } = entity || {}

    const handleLocaleChange = useCallback(
        (e: SyntheticInputEvent<HTMLSelectElement>) => {
            const contentText = content[locale]?.content?.getCurrentContent?.()

            if (typeof contentText !== 'undefined') {
                handleChange(`content[${locale}].content`)(
                    stateToHTML(contentText),
                )
            }

            window.history.replaceState(
                {},
                null,
                `/reviews/${isNew ? 'new' : slug}?locale=${e.target.value}`,
            )
            setLocale(e.target.value)
        },
        [content, locale, isNew, slug, handleChange],
    )

    const handleRatingChange = useCallback(
        (type: RatingType) => (e, newValue) =>
            handleChange(`ratings.${type}.value`)(newValue),
        [handleChange],
    )

    const goBack = useCallback(() => {
        history.push('/reviews')
    }, [history])

    const validate = useCallback(async () => {}, [entity])

    const handleSave = useCallback(async () => {
        const error = await validate()
        if (error) {
            alert(error)
            return
        }
        await save()

        if (isNew) {
            history.push(`/reviews/${slug}?locale=${locale}`)
        }
    }, [validate, save, isNew, history, slug, locale])

    const handleTitleChange = useCallback(
        async (e) => {
            const title = e.target.value

            if (!isNew) {
                handleChange('title')(title)
                return
            }

            const slug = getSlug(title || '')

            handleMultipleChange({
                slug,
                title,
            })
        },
        [handleChange, handleMultipleChange, isNew],
    )

    const handlePaymentUpdate = useCallback(
        (newData, { tableData } = {}) => {
            handleChange(`payments.${tableData.id}`)(newData)
        },
        [handleChange],
    )

    const handlePaymentDelete = useCallback(
        ({ tableData }) =>
            handleChange('payments')(
                payments.filter((_, i) => i !== tableData.id),
            ),
        [handleChange, payments],
    )

    const handleReload = useCallback(() => refetch && refetch(), [refetch])

    if (isLoading || isCategoriesLoading) {
        return <Loader isFullHeight />
    }

    if (!review || !entity) {
        return <NotFound name="Reviews" url="/reviews" />
    }

    const categoriesById = keyBy(categories, 'id')

    return (
        <Paper>
            <Container>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Sticky stickyStyle={stickyStyle}>
                            <ToolbarContainer>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    onClick={handleSave}
                                    disabled={
                                        (isNew && !slug) ||
                                        !isChanged ||
                                        isSaving
                                    }
                                    endIcon={
                                        isSaving && (
                                            <CircularProgress size={20} />
                                        )
                                    }
                                >
                                    Save
                                </Button>
                                <Button
                                    size="large"
                                    color="secondary"
                                    onClick={isNew ? goBack : reset}
                                    disabled={
                                        !isNew && (!isChanged || isSaving)
                                    }
                                >
                                    Cancel
                                </Button>
                                {refetch && (
                                    <Tooltip title="Reload review (all changes will be cancelled)">
                                        <IconButton
                                            color="primary"
                                            onClick={handleReload}
                                        >
                                            <RefreshIcon />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </ToolbarContainer>
                        </Sticky>
                        <Typography variant="h5">{slug}</Typography>
                        {entity.reviewDate && (
                            <Typography variant="caption">
                                {formatDate(entity.reviewDate)}
                                {entity.author &&
                                    ` by ${
                                        [
                                            entity.author.firstName,
                                            entity.author.lastName,
                                        ].join(' ') || 'Unknown'
                                    }`}
                            </Typography>
                        )}
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <TextField
                            fullWidth
                            label="Title"
                            InputLabelProps={{ shrink: true }}
                            value={title || ''}
                            onChange={handleTitleChange}
                            disabled={isSaving}
                            error={!title}
                            helperText={!title && 'Is required'}
                        />
                    </Grid>
                    {categories && (
                        <Grid item xs={12} md={6} lg={4}>
                            <FormControl fullWidth disabled={isSaving}>
                                <InputLabel id="categories-select-label" shrink>
                                    Categories
                                </InputLabel>
                                <Select
                                    labelId="categories-select-label"
                                    multiple
                                    onChange={handleChangeEvent('tags')}
                                    input={<Input />}
                                    value={entity.tags}
                                    renderValue={(selectedValues) =>
                                        selectedValues
                                            .map(
                                                (id) =>
                                                    categoriesById[id]?.slug,
                                            )
                                            .join(', ')
                                    }
                                >
                                    {categories.map(({ id, slug }) => (
                                        <MenuItem key={id} value={id}>
                                            <Checkbox
                                                checked={entity.tags.includes(
                                                    id,
                                                )}
                                            />
                                            <ListItemText primary={slug} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    )}
                    <Grid item xs={12} md={6} lg={4}>
                        <TextField
                            fullWidth
                            label="Website Link"
                            InputLabelProps={{ shrink: true }}
                            value={entity.websiteLink || ''}
                            onChange={handleChangeEvent('websiteLink')}
                            disabled={isSaving}
                            helperText="Started with http:// or https://"
                        />
                    </Grid>
                    <Grid item container spacing={2}>
                        <Grid item xs={12} md={6} lg={2}>
                            <StatusSelect
                                value={entity.status}
                                onChange={handleChangeEvent('status')}
                                isDisabled={isSaving}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={2}>
                            <FormControl fullWidth>
                                <InputLabel id="type-select" shrink>
                                    Type
                                </InputLabel>
                                <Select
                                    labelId="type-select"
                                    value={entity.type}
                                    onChange={handleChangeEvent('type')}
                                    disabled={isSaving}
                                >
                                    {['bookmaker', 'casino'].map((type) => (
                                        <MenuItem value={type} key={type}>
                                            {type}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} lg={4} md={6}>
                        <Grid item xs={12}>
                            <Typography variant="caption" color="textSecondary">
                                Image
                            </Typography>
                        </Grid>
                        <ImageUpload
                            prefix={`images/reviews/${slug}`}
                            value={entity.images.logo}
                            onChange={handleChange('images.logo')}
                            disabled={!title || isSaving}
                            errorMessage={
                                !title ? 'Enter title first' : undefined
                            }
                        />
                    </Grid>
                    <Grid container item xs={12} lg={8} md={6} spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h5">Ratings</Typography>
                        </Grid>
                        {Object.values(ratingTypes[entity.type]).map((type) => (
                            <Grid item xs={12} sm={6} key={type}>
                                <Box
                                    component="fieldset"
                                    mb={3}
                                    borderColor="transparent"
                                >
                                    <Typography component="legend">
                                        {capitalize(type.replace(/_/g, ' '))}
                                    </Typography>
                                    <RatingContainer>
                                        <Rating
                                            value={entity.ratings[type]?.value}
                                            onChange={handleRatingChange(type)}
                                            precision={0.5}
                                            name={type}
                                            disabled={isSaving}
                                        />
                                        <Box ml={2}>
                                            {entity.ratings[type]?.value}
                                        </Box>
                                    </RatingContainer>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h5">Bonus</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="Link"
                            InputLabelProps={{ shrink: true }}
                            value={entity.bonus?.link || ''}
                            onChange={handleChangeEvent('bonus.link')}
                            helperText={
                                !entity.bonus?.link && entity.bonus?.amount
                                    ? 'Is required'
                                    : 'Started with http:// or https://'
                            }
                            error={!entity.bonus?.link && entity.bonus?.amount}
                            disabled={isSaving}
                        />
                    </Grid>
                    <Grid item xs={6} md={1}>
                        <TextField
                            fullWidth
                            label="Amount"
                            InputLabelProps={{ shrink: true }}
                            value={entity.bonus?.amount || ''}
                            onChange={handleChangeEvent('bonus.amount')}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        {entity.bonus?.currency || 'UAH'}
                                    </InputAdornment>
                                ),
                            }}
                            inputProps={{
                                min: 0,
                                step: 0.5,
                            }}
                            type="number"
                            error={!entity.bonus?.amount && entity.bonus?.link}
                            helperText={
                                !entity.bonus?.amount &&
                                entity.bonus?.link &&
                                'Is required'
                            }
                            disabled={isSaving}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h5">License</Typography>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <TextField
                            fullWidth
                            label="Number"
                            InputLabelProps={{ shrink: true }}
                            value={entity.license?.number || ''}
                            onChange={handleChangeEvent('license.number')}
                            disabled={isSaving}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <DatePicker
                            fullWidth
                            autoOk
                            variant="inline"
                            label="Issue date"
                            InputLabelProps={{ shrink: true }}
                            value={entity.license?.issueDate || null}
                            onChange={handleChange('license.issueDate')}
                            openTo="year"
                            format="dd.MM.yyyy"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <EventAvailableIcon />
                                    </InputAdornment>
                                ),
                            }}
                            disabled={isSaving}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <TextField
                            fullWidth
                            label="Issued by"
                            InputLabelProps={{ shrink: true }}
                            value={entity.license?.issuedBy || ''}
                            onChange={handleChangeEvent('license.issuedBy')}
                            disabled={isSaving}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <DatePicker
                            fullWidth
                            autoOk
                            variant="inline"
                            label="Expire date"
                            InputLabelProps={{ shrink: true }}
                            value={entity.license?.expireDate || null}
                            onChange={handleChange('license.expireDate')}
                            openTo="year"
                            format="dd.MM.yyyy"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <EventBusyIcon />
                                    </InputAdornment>
                                ),
                            }}
                            disabled={isSaving}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h5">Legal info</Typography>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <TextField
                            fullWidth
                            label="Name"
                            InputLabelProps={{ shrink: true }}
                            value={entity.legalInfo?.name || ''}
                            onChange={handleChangeEvent('legalInfo.name')}
                            disabled={isSaving}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <TextField
                            fullWidth
                            label="Address"
                            InputLabelProps={{ shrink: true }}
                            value={entity.legalInfo?.address || ''}
                            onChange={handleChangeEvent('legalInfo.address')}
                            multiline
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <BusinessIcon />
                                    </InputAdornment>
                                ),
                            }}
                            disabled={isSaving}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <InputMask
                            mask="9999999999"
                            maskChar=""
                            value={entity.legalInfo?.ITN || ''}
                        >
                            {(inputProps) => (
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="ITN-input" shrink>
                                        ITN (ІНН)
                                    </InputLabel>
                                    <Input
                                        disabled={isSaving}
                                        id="ITN-input"
                                        onChange={handleChangeEvent(
                                            'legalInfo.ITN',
                                        )}
                                        {...inputProps}
                                    />
                                    <FormHelperText>
                                        Individual Taxpayer Number
                                    </FormHelperText>
                                </FormControl>
                            )}
                        </InputMask>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <InputMask
                            mask="99999999"
                            maskChar=""
                            value={entity.legalInfo?.USREOU || ''}
                        >
                            {(inputProps) => (
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="USREOU-input" shrink>
                                        USREOU (ЄДРПОУ)
                                    </InputLabel>
                                    <Input
                                        disabled={isSaving}
                                        id="USREOU-input"
                                        onChange={handleChangeEvent(
                                            'legalInfo.USREOU',
                                        )}
                                        {...inputProps}
                                    />
                                    <FormHelperText>
                                        Unified State register of Enterprises
                                        and Organizations of Ukraine
                                    </FormHelperText>
                                </FormControl>
                            )}
                        </InputMask>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h5">Support</Typography>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <TextField
                            fullWidth
                            label="Phone"
                            InputLabelProps={{ shrink: true }}
                            value={entity.support?.phone || ''}
                            onChange={handleChangeEvent('support.phone')}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <PhoneIcon />
                                    </InputAdornment>
                                ),
                            }}
                            disabled={isSaving}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <TextField
                            fullWidth
                            label="Email"
                            InputLabelProps={{ shrink: true }}
                            value={entity.support?.email || ''}
                            onChange={handleChangeEvent('support.email')}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <AlternateEmailIcon />
                                    </InputAdornment>
                                ),
                            }}
                            disabled={isSaving}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h5">Applications</Typography>
                    </Grid>
                    {Object.keys(applications).map((type) => (
                        <Grid item xs={12} md={4} key={type}>
                            <TextField
                                fullWidth
                                label={applications[type].title}
                                InputLabelProps={{ shrink: true }}
                                value={entity.applications[type]?.link}
                                onChange={handleChangeEvent(
                                    `applications.${type}.link`,
                                )}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            {applications[type].icon}
                                        </InputAdornment>
                                    ),
                                }}
                                disabled={isSaving}
                            />
                        </Grid>
                    ))}
                    <Grid item xs={12}>
                        <Payments
                            payments={entity.payments}
                            onAdd={handleChange(
                                `payments.${entity.payments.length}`,
                            )}
                            onDelete={handlePaymentDelete}
                            onUpdate={handlePaymentUpdate}
                        />
                    </Grid>
                    <Grid
                        item
                        container
                        xs={12}
                        spacing={2}
                        alignItems="center"
                    >
                        <Grid item>
                            <Typography variant="h5">Locale: </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                            <FormControl fullWidth>
                                <InputLabel id="language-select">
                                    Language
                                </InputLabel>
                                <Select
                                    labelId="language-select"
                                    value={locale}
                                    onChange={handleLocaleChange}
                                    disabled={isSaving}
                                >
                                    {Object.keys(languages).map((locale) => (
                                        <MenuItem value={locale} key={locale}>
                                            {languages[locale]?.label || locale}
                                            {!isNew &&
                                                !initialEntity.content[
                                                    locale
                                                ] &&
                                                ' (missed 😢)'}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="h5">SEO</Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="Meta title"
                            InputLabelProps={{ shrink: true }}
                            value={content[locale]?.metaTitle || ''}
                            onChange={handleChangeEvent(
                                `content.${locale}.metaTitle`,
                            )}
                            disabled={isSaving}
                            helperText="Leave blank if you are not sure what it is"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="Meta h1"
                            InputLabelProps={{ shrink: true }}
                            value={content[locale]?.h1 || ''}
                            onChange={handleChangeEvent(`content.${locale}.h1`)}
                            disabled={isSaving}
                            helperText="Leave blank if you are not sure what it is"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="Meta description"
                            InputLabelProps={{ shrink: true }}
                            value={content[locale]?.metaDescription || ''}
                            onChange={handleChangeEvent(
                                `content.${locale}.metaDescription`,
                            )}
                            disabled={isSaving}
                            helperText="Leave blank if you are not sure what it is"
                            multiline
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="h5">
                            Additional settings
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="Display priority"
                            InputLabelProps={{ shrink: true }}
                            value={entity.order}
                            onChange={handleChangeEvent(`order`)}
                            inputProps={{
                                min: 0,
                            }}
                            disabled={isSaving}
                            type="number"
                            helperText="Set display priority. A higher indicator equals a higher position"
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="h5">Content</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Title"
                            InputLabelProps={{ shrink: true }}
                            value={content[locale]?.title || ''}
                            onChange={handleChangeEvent(
                                `content.${locale}.title`,
                            )}
                            disabled={isSaving}
                            helperText={`Enter title on "${locale}" locale`}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="Pros"
                            InputLabelProps={{ shrink: true }}
                            value={content[locale]?.pros || ''}
                            onChange={handleChangeEvent(
                                `content.${locale}.pros`,
                            )}
                            disabled={isSaving}
                            multiline
                            rows={5}
                            helperText="Divide items by newline or ';'"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <CheckIcon
                                            style={{ color: green[500] }}
                                        />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="Cons"
                            InputLabelProps={{ shrink: true }}
                            value={content[locale]?.cons || ''}
                            onChange={handleChangeEvent(
                                `content.${locale}.cons`,
                            )}
                            disabled={isSaving}
                            multiline
                            rows={5}
                            helperText="Divide items by newline or ';'"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <CloseIcon
                                            style={{ color: red[500] }}
                                        />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            {content[locale]?.title || ''}
                        </Typography>
                        {content[locale]?.createdAt && (
                            <Typography variant="caption">
                                {formatDate(content[locale]?.createdAt)}
                            </Typography>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <EditorComponent
                            lang={locale}
                            value={content[locale]?.content || ''}
                            onChange={handleChange(`content.${locale}.content`)}
                            prefix={`images/reviews/${slug}`}
                        ></EditorComponent>
                    </Grid>
                </Grid>
            </Container>
        </Paper>
    )
}

type ReviewEditPageProps = {
    match: Match,
}

const ReviewEditPage = withErrorHandler(({ match }: ReviewEditPageProps) => {
    const fetchProps = useAPI<{ review: Review }>({
        url: `/content/reviews/${String(match.params.slug)}`,
    })

    return (
        <CategoriesProvider entity="review">
            <ReviewPage {...fetchProps} />
        </CategoriesProvider>
    )
})

const ReviewCreatePage = withErrorHandler(() => {
    return (
        <CategoriesProvider entity="review">
            <ReviewPage
                data={{ review: getEntity({ review: {} }) }}
                isLoading={false}
                isNew
            />
        </CategoriesProvider>
    )
})

export { ReviewCreatePage, ReviewEditPage }
