// @flow
import React, { useState, useCallback, useMemo, useEffect } from 'react'
import { type Match, useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components/macro'
import fpKeyBy from 'lodash/fp/keyBy'
import compose from 'lodash/fp/compose'
import fpMap from 'lodash/fp/map'
import fpPick from 'lodash/fp/pick'
import keyBy from 'lodash/keyBy'
import mapValues from 'lodash/mapValues'

import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import RefreshIcon from '@material-ui/icons/Refresh'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import CircularProgress from '@material-ui/core/CircularProgress'
import Input from '@material-ui/core/Input'
import Checkbox from '@material-ui/core/Checkbox'
import ListItemText from '@material-ui/core/ListItemText'

import formatDate from '../../utils/formatDate'
import Loader from '../../shared/components/Loader'
import ContentEditor from '../../shared/components/ContentEditor'
import useAPI from '../../shared/hooks/useAPI'
import { type KnowledgeBase } from './context/knowledge-bases'
import { type Config, useConfig } from '../context/config'
import withErrorHandler from '../../shared/hocs/withErrorHandler'
import {
    useCategories,
    Provider as CategoriesProvider,
} from '../context/categories'
import useCRUD from '../../shared/hooks/useCRUD'
import ImageUpload from '../../shared/components/ImageUpload'
import stateToHTML from '../../utils/stateToHTML'
import Sticky from 'react-sticky-el'
import NotFound from '../../shared/components/NotFound'
import type { RefetchType } from '../../shared/hooks/useAPI'
import StatusSelect from '../../shared/components/StatusSelect'
import { globalLanguages } from '../../contants'
import getSlug from '../../utils/getSlug'
import EditorComponent from '../../shared/components/Editor'

const Container = styled.div`
    padding: 15px;
`

const ToolbarContainer = styled.div`
    > * {
        margin-right: 5px;
    }
`

type Props = {
    isNew?: boolean,
    data: { knowledgeBase: ?KnowledgeBase },
    isLoading: boolean,
    refetch?: RefetchType,
}

const languages = globalLanguages

const stickyStyle = {
    backgroundColor: 'white',
    paddingBottom: 15,
    paddingTop: 77,
    zIndex: 2,
}

const getEntity = ({
    knowledgeBase,
    config,
}: {
    knowledgeBase: ?KnowledgeBase,
    config: ?Config,
}) =>
    knowledgeBase
        ? {
              author: knowledgeBase.author,
              content: compose(
                  fpKeyBy('locale'),
                  fpMap(fpPick(['locale', 'title', 'content', 'createdAt'])),
              )(knowledgeBase.mainContent),
              images: mapValues(
                  knowledgeBase.images,
                  (url) => `https://${config.filesBucketName}${url}`,
              ),
              slug: knowledgeBase.slug,
              status: knowledgeBase.status || 'created',
              tags: knowledgeBase.tags || [],
          }
        : null

const prepareUpdateBody = ({ content, images, ...knowledgeBase }) => {
    Object.keys(content).forEach((key) => {
        const html = document.createElement('div')
        html.innerHTML = content[key].content
        if (html.querySelector('[data-f-id]')) {
            html.querySelector('[data-f-id]').remove()
        }
        content[key].content = html.innerHTML
    })
    return {
        content: mapValues(content, ({ createdAt, ...localeContent }) => ({
            ...localeContent,
            content: !localeContent.content
                ? ''
                : typeof localeContent.content === 'string'
                ? localeContent.content
                : stateToHTML(localeContent.content.getCurrentContent()),
        })),
        knowledgeBase: {
            ...knowledgeBase,
            images: mapValues(images, (url) => new URL(url).pathname),
        },
    }
}

const KnowledgeBasePage = ({
    data,
    isLoading,
    refetch,
    isNew = false,
}: Props) => {
    const { config } = useConfig()
    const { categories, isLoading: isCategoriesLoading } = useCategories()
    const knowledgeBase = data?.knowledgeBase

    const { search } = useLocation()

    const initialEntity = useMemo(() => getEntity({ config, knowledgeBase }), [
        config,
        knowledgeBase,
    ])

    const {
        entity,
        handleChangeEvent,
        handleChange,
        handleMultipleChange,
        isChanged,
        save,
        reset,
        isLoading: isSaving,
    } = useCRUD({
        entity: initialEntity,
        prepareBody: prepareUpdateBody,
        saveMethod: isNew ? 'POST' : 'PUT',
        url: `/content/knowledge-bases${
            isNew ? '' : `/${String(knowledgeBase?.id)}`
        }`,
    })

    const contentLocale = knowledgeBase?.mainContent?.[0]?.locale

    const { slug, content } = entity || {}

    const urlLocale = useMemo(() => {
        let locale = new URLSearchParams(search).get('locale')

        return Object.keys(languages).includes(locale) ? locale : null
    }, [search])

    const [locale, setLocale] = useState(urlLocale || contentLocale || 'ru')

    useEffect(() => {
        if (contentLocale && !urlLocale) {
            setLocale(contentLocale)
        }
    }, [contentLocale, urlLocale])

    const handleLocaleChange = useCallback(
        (e: SyntheticInputEvent<HTMLSelectElement>) => {
            const contentText = content[locale]?.content?.getCurrentContent?.()

            if (typeof contentText !== 'undefined') {
                const content = stateToHTML(contentText)
                handleChange(`content[${locale}].content`)(content)
            }

            window.history.replaceState(
                {},
                null,
                `/knowledge-bases/${isNew ? 'new' : slug}?locale=${
                    e.target.value
                }`,
            )
            setLocale(e.target.value)
        },
        [content, handleChange, isNew, locale, slug],
    )

    const history = useHistory()

    const goBack = useCallback(() => {
        history.push('/knowledge-bases')
    }, [history])

    const handleSave = useCallback(async () => {
        await save()
        history.push(`/knowledge-bases/${slug}?locale=${locale}`)
    }, [save, history, slug, locale])

    const handleContentChange = useCallback(
        async (e, lang) => {
            let modifiedContentData = entity
            modifiedContentData.content[lang].content = e

            handleChange(`content.${lang}.content`)
        },
        [content, handleChange],
    )

    const handleTitleChange = useCallback(
        async (e, lang) => {
            const title = e.target.value.slice(0, 156)

            if (!isNew) {
                handleChange(`content.${lang}.title`)(title)
                return
            }

            let slug

            if (lang === 'ru' && !title) {
                slug = getSlug(content['uk']?.title || '')
            }

            if ((lang === 'ru' && title) || !content['ru']?.title) {
                slug = getSlug(title)
            }

            if (slug) {
                handleMultipleChange({
                    slug,
                    [`content.${lang}.title`]: title,
                })
                return
            }

            handleChange(`content.${lang}.title`)(title)
        },
        [content, handleChange, handleMultipleChange, isNew],
    )

    const handleReload = useCallback(() => refetch && refetch(), [refetch])

    if (isLoading || isCategoriesLoading) {
        return <Loader isFullHeight />
    }

    if (!knowledgeBase || !entity) {
        return (
            <NotFound name="Knowledge base articles" url="/knowledge-bases" />
        )
    }

    const categoriesById = keyBy(categories, 'id')

    return (
        <Paper>
            <Container>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Sticky stickyStyle={stickyStyle}>
                            <ToolbarContainer>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    onClick={isNew ? handleSave : save}
                                    disabled={
                                        (isNew && !slug) ||
                                        !isChanged ||
                                        isSaving
                                    }
                                    endIcon={
                                        isSaving && (
                                            <CircularProgress size={20} />
                                        )
                                    }
                                >
                                    Save
                                </Button>
                                <Button
                                    size="large"
                                    color="secondary"
                                    onClick={isNew ? goBack : reset}
                                    disabled={
                                        !isNew && (!isChanged || isSaving)
                                    }
                                >
                                    Cancel
                                </Button>
                                {refetch && (
                                    <Tooltip title="Reload article (all changes will be cancelled)">
                                        <IconButton
                                            color="primary"
                                            onClick={handleReload}
                                        >
                                            <RefreshIcon />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </ToolbarContainer>
                        </Sticky>
                        <Typography variant="h5">{slug}</Typography>
                        {entity.createdAt && (
                            <Typography variant="caption">
                                {formatDate(entity.createdAt)}
                                {entity.author &&
                                    ` by ${
                                        [
                                            entity.author.firstName,
                                            entity.author.lastName,
                                        ].join(' ') || 'Unknown'
                                    }`}
                            </Typography>
                        )}
                    </Grid>
                    {categories && (
                        <Grid item xs={12} md={6} lg={3}>
                            <FormControl fullWidth disabled={isSaving}>
                                <InputLabel id="categories-select-label" shrink>
                                    Categories
                                </InputLabel>
                                <Select
                                    labelId="categories-select-label"
                                    multiple
                                    onChange={handleChangeEvent('tags')}
                                    input={<Input />}
                                    value={entity.tags}
                                    renderValue={(selectedValues) =>
                                        selectedValues
                                            .map(
                                                (id) =>
                                                    categoriesById[id]?.slug,
                                            )
                                            .join(', ')
                                    }
                                >
                                    {categories.map(({ id, slug }) => (
                                        <MenuItem key={id} value={id}>
                                            <Checkbox
                                                checked={entity.tags.includes(
                                                    id,
                                                )}
                                            />
                                            <ListItemText primary={slug} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    )}
                    <Grid item xs={12} md={6} lg={3}>
                        <StatusSelect
                            value={entity.status}
                            onChange={handleChangeEvent('status')}
                            isDisabled={isSaving}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="caption" color="textSecondary">
                            Image
                        </Typography>
                    </Grid>
                    <ImageUpload
                        prefix={`images/knowledge-bases/${slug}`}
                        value={entity.images.main}
                        onChange={handleChange('images.main')}
                        disabled={!slug || isSaving}
                        errorMessage={!slug ? 'Enter title first' : undefined}
                    />
                    <Grid
                        item
                        container
                        xs={12}
                        spacing={2}
                        alignItems="center"
                    >
                        <Grid item>
                            <Typography variant="h5">Content</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                            <FormControl fullWidth>
                                <InputLabel id="language-select">
                                    Language
                                </InputLabel>
                                <Select
                                    labelId="language-select"
                                    value={locale}
                                    onChange={handleLocaleChange}
                                    disabled={isSaving}
                                >
                                    {Object.keys(languages).map((locale) => (
                                        <MenuItem value={locale} key={locale}>
                                            {languages[locale]?.label || locale}
                                            {!isNew &&
                                                !initialEntity.content[
                                                    locale
                                                ] &&
                                                ' (missed 😢)'}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        {['en', 'ru', 'uk'].map((lang) => {
                            return (
                                <TextField
                                    key={lang}
                                    style={{ marginBottom: '20px' }}
                                    fullWidth
                                    label={'Title ' + lang}
                                    InputLabelProps={{ shrink: true }}
                                    value={content[lang]?.title || ''}
                                    onChange={(event) =>
                                        handleTitleChange(event, lang)
                                    }
                                    disabled={isSaving}
                                    error={!slug}
                                    helperText={!slug && 'Is required'}
                                />
                            )
                        })}
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            {content[locale]?.title || ''}
                        </Typography>
                        {content[locale]?.createdAt && (
                            <Typography variant="caption">
                                {formatDate(content[locale]?.createdAt)}
                            </Typography>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        {['en', 'ru', 'uk'].map((lang) => {
                            return (
                                <EditorComponent
                                    key={lang}
                                    lang={lang}
                                    value={content[lang]?.content || ''}
                                    onChange={handleChange(
                                        `content.${lang}.content`,
                                    )}
                                ></EditorComponent>
                            )
                        })}
                    </Grid>
                </Grid>
            </Container>
        </Paper>
    )
}

type KnowledgeBaseEditPageProps = {
    match: Match,
}

const KnowledgeBaseEditPage = withErrorHandler(
    ({ match }: KnowledgeBaseEditPageProps) => {
        const fetchProps = useAPI<{ knowledgeBase: KnowledgeBase }>({
            url: `/content/knowledge-bases/${String(match.params.slug)}`,
        })

        return (
            <CategoriesProvider entity="knowledge-base">
                <KnowledgeBasePage {...fetchProps} />
            </CategoriesProvider>
        )
    },
)

const KnowledgeBaseCreatePage = withErrorHandler(() => {
    return (
        <CategoriesProvider entity="knowledge-base">
            <KnowledgeBasePage
                data={{ knowledgeBase: getEntity({ knowledgeBase: {} }) }}
                isLoading={false}
                isNew
            />
        </CategoriesProvider>
    )
})

export { KnowledgeBaseCreatePage, KnowledgeBaseEditPage }
