// @flow

import React, { memo, useCallback } from "react";
import { useHistory } from "react-router-dom";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import TreeItem from "@material-ui/lab/TreeItem";
import TreeView from "@material-ui/lab/TreeView";

import type { Category } from "../context/categories";
import { Typography } from "@material-ui/core";

type Props = { categories: Category[] };

const getHierarchy = ({ categories, parentId = null, onClick }) => {
    const levelCategories = categories.filter(({ parentCategoryId }) =>
        parentId ? parentCategoryId === parentId : !parentCategoryId
    );

    const otherCategories = categories.filter(({ parentCategoryId }) =>
        parentId ? parentCategoryId !== parentId : parentCategoryId
    );

    return levelCategories.length ? (
        <>
            {levelCategories.map(({ id, slug }) => (
                <TreeItem
                    nodeId={id}
                    label={slug}
                    onDoubleClick={onClick({ slug })}
                >
                    {getHierarchy({
                        categories: otherCategories,
                        onClick,
                        parentId: id,
                    })}
                </TreeItem>
            ))}
        </>
    ) : null;
};

const CategoriesHierarchy = ({ categories }: Props) => {
    const history = useHistory();
    const onClick = useCallback(
        ({ slug }) => () => {
            history.push(`/categories/${slug}`);
        },
        [history]
    );
    return (
        <>
            <Typography variant="caption">
                Double click to open Edit page
            </Typography>
            <TreeView
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
            >
                {getHierarchy({ categories, onClick, parentId: null })}
            </TreeView>
        </>
    );
};

export default memo<Props>(CategoriesHierarchy);
