// @flow

import React, { createContext, useCallback, useContext } from "react";
import noop from "lodash/noop";

import useAPI from "../../../shared/hooks/useAPI";
import type { PaginationProps } from "../../../shared/hooks/useAPI";
import apiCall from "../../../utils/apiCall";

export type Locale = "ru" | "uk";

export type PostContent = {
    locale: Locale,
    title: string,
    description: string,
    metaTitle: string,
    metaDescription: string,
    content: string,
    createdAt: string,
    updatedAt: string,
};

export type Author = {
    id: string,
    firstName: string,
    lastName: string,
    avatarUrl?: string,
};

export type Post = {
    id: string,
    slug: string,
    mainContent: PostContent[],
    postDate: string,
    tags: string[],
    status: "published" | "created",
    commentStatus: "opened" | "closed",
    order: number,
    author: Author,
    images: {
        main: string,
    },
    type: "post",
    videoUrl?: ?string,
};

type PostsContext = {
    authors: ?(Author[]),
    count: number,
    posts: ?(Post[]),
    error: ?Error,
    isLoading: boolean,
    refetch: (PaginationProps) => Promise<void>,
    deletePost: (Post) => Promise<void>,
};

const Context = createContext<PostsContext>({
    authors: null,
    count: 0,
    deletePost: noop,
    error: null,
    isLoading: false,
    posts: null,
    refetch: noop,
});

const PostsProvider = ({ children }: { children: * }) => {
    const { data, isLoading, error, refetch } = useAPI<{
        count: number,
        list: Post[],
    }>({
        url: "/content/posts/all",
    });

    const { data: authors, isLoading: isAuthorsLoading } = useAPI<{
        list: Author[],
    }>({
        url: "/content/posts/authors",
    });

    const deletePost = useCallback(
        async ({ slug }: Post) => {
            await apiCall({
                method: "DELETE",
                url: `/content/posts/${slug}`,
            });

            refetch({});
        },
        [refetch]
    );

    return (
        <Context.Provider
            value={{
                authors: authors?.list,
                count: data?.count || 0,
                deletePost,
                error,
                isLoading: isLoading || isAuthorsLoading,
                posts: data?.list,
                refetch,
            }}
        >
            {children}
        </Context.Provider>
    );
};

const usePosts = () => useContext<PostsContext>(Context);

export { PostsProvider as Provider, usePosts };
