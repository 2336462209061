// @flow
import React, { useCallback, useMemo } from "react";
import { type Match, useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import Sticky from "react-sticky-el";

import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import RefreshIcon from "@material-ui/icons/Refresh";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import formatDate from "../../utils/formatDate";
import Loader from "../../shared/components/Loader";
import useAPI from "../../shared/hooks/useAPI";
import { type Team } from "./context/teams";
import withErrorHandler from "../../shared/hocs/withErrorHandler";
import useCRUD from "../../shared/hooks/useCRUD";
import NotFound from "../../shared/components/NotFound";
import type { RefetchType } from "../../shared/hooks/useAPI";
import TextField from "@material-ui/core/TextField";
import ImageUpload from "../../shared/components/ImageUpload";
import { useConfig } from "../context/config";
import type { Config } from "../context/config";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import getSlug from "../../utils/getSlug";

const Container = styled.div`
    padding: 15px;
`;

const ToolbarContainer = styled.div`
    > * {
        margin-right: 5px;
    }
`;

type Props = {
    isNew?: boolean,
    data: { category: ?Team },
    isLoading: boolean,
    refetch?: RefetchType,
};

const stickyStyle = {
    backgroundColor: "white",
    paddingBottom: 15,
    paddingTop: 77,
    zIndex: 2,
};

const getEntity = ({ team, config }: { team: ?Team, config: ?Config }) =>
    team
        ? {
            createdAt: team.createdAt,
            createdBy: team.createdBy,
            image:
            team.image &&
            `https://${config.filesBucketName}${team.image}`,
            localizedName: team.localizedName,
            name: team.name || "",
            show: typeof team.show === "boolean" ? team.show : true,
            slug: team.slug || "",
            updatedAt: team.updatedAt,
            updatedBy: team.updatedBy,
          }
        : null;

const prepareUpdateBody = ({ image, name, localizedName, show, slug } = {}) => ({
    image: new URL(image).pathname,
    localizedName,
    name,   
    show,
    slug
});

const TeamPage = ({ data, isLoading, refetch, isNew = false }: Props) => {
    const team = data?.team;

    const { config } = useConfig();
    const initialEntity = useMemo(() => getEntity({ config, team }), [
        config,
        team,
    ]);

    const {
        entity,
        handleChangeEvent,
        handleChange,
        handleMultipleChange,
        isChanged,
        save,
        reset,
        isLoading: isSaving,
    } = useCRUD({
        entity: initialEntity,
        prepareBody: prepareUpdateBody,
        saveMethod: isNew ? "POST" : "PATCH",
        url: `/content/teams${isNew ? "" : `/${String(team?.id)}`}`,
    });

    const { name, image, localizedName, show, slug } = entity || {};

    const history = useHistory();

    const goBack = useCallback(() => {
        history.push("/forecasts/teams");
    }, [history]);

    const handleNameChange = useCallback(
        async (e) => {
            const name = e.target.value;
            if (!isNew) {
                handleChange("name")(name);
                return;
            }

            handleMultipleChange({
                name,
                slug: getSlug(name),
            });
        },
        [handleChange, handleMultipleChange, isNew]
    );

    const handleLocalizedNameChange = useCallback(
        async (e) => {
            const name = e.target.value;
            const locale = e.target.id;
            handleChange("localizedName")({ ...localizedName, [locale]: name });
        },
        [handleChange]
    );

    const handleSave = useCallback(async () => {
        await save();
        history.push(`/forecasts/teams`);
    }, [save, history]);

    const handleReload = useCallback(() => refetch && refetch(), [refetch]);

    if (isLoading) {
        return <Loader isFullHeight />;
    }

    if (!team || !entity) {
        return <NotFound name="Teams" url="/forecasts/teams" />;
    }

    return (
        <Paper>
            <Container>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Sticky stickyStyle={stickyStyle}>
                            <ToolbarContainer>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    onClick={isNew ? handleSave : save}
                                    disabled={
                                        !slug ||
                                        !image ||
                                        !isChanged ||
                                        isSaving
                                    }
                                    endIcon={
                                        isSaving && (
                                            <CircularProgress size={20} />
                                        )
                                    }
                                >
                                    Save
                                </Button>
                                <Button
                                    size="large"
                                    color="secondary"
                                    onClick={isNew ? goBack : reset}
                                    disabled={
                                        !isNew && (!isChanged || isSaving)
                                    }
                                >
                                    Cancel
                                </Button>
                                {refetch && (
                                    <Tooltip title="Reload team (all changes will be cancelled)">
                                        <IconButton
                                            color="primary"
                                            onClick={handleReload}
                                        >
                                            <RefreshIcon />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </ToolbarContainer>
                        </Sticky>
                        <Typography variant="h5">{name}</Typography>
                        {entity.createdAt && (
                            <Typography variant="caption">
                                Created at {formatDate(entity.createdAt)}
                                {entity.createdBy &&
                                    ` by ${
                                        [
                                            entity.createdBy.firstName,
                                            entity.createdBy.lastName,
                                        ].join(" ") || "Unknown"
                                    }`}
                            </Typography>
                        )}
                        {entity.updatedAt && (
                            <Typography variant="caption" component="div">
                                Last updated at {formatDate(entity.updatedAt)}
                                {entity.updatedBy &&
                                    ` by ${
                                        [
                                            entity.updatedBy.firstName,
                                            entity.updatedBy.lastName,
                                        ].join(" ") || "Unknown"
                                    }`}
                            </Typography>
                        )}
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            fullWidth
                            label="Name"
                            InputLabelProps={{ shrink: true }}
                            value={name}
                            onChange={handleNameChange}
                            disabled={isSaving}
                            error={!name}
                            helperText={!name && "Is required"}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            fullWidth
                            label="UK"
                            id="uk"
                            InputLabelProps={{ shrink: true }}
                            value={localizedName?.uk}
                            onChange={handleLocalizedNameChange}
                            disabled={isSaving}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            fullWidth
                            label="RU"
                            id="ru"
                            InputLabelProps={{ shrink: true }}
                            value={localizedName?.ru}
                            onChange={handleLocalizedNameChange}
                            disabled={isSaving}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            fullWidth
                            label="EN"
                            id="en"
                            InputLabelProps={{ shrink: true }}
                            value={localizedName?.en}
                            onChange={handleLocalizedNameChange}
                            disabled={isSaving}
                        />
                    </Grid>
                    <Grid container item xs={12} md={8}>
                        <Grid item xs={12}>
                            <Typography variant="caption" color="textSecondary">
                                Image
                            </Typography>
                        </Grid>
                        <ImageUpload
                            prefix={`images/teams/${slug}`}
                            value={image}
                            onChange={handleChange("image")}
                            disabled={!name || isSaving}
                            errorMessage={
                                !slug
                                    ? "Enter name first"
                                    : !image
                                    ? "Is required"
                                    : undefined
                            }
                            styles={{
                                borderRadius: 0,
                                height: 42,
                                width: 48,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color="primary"
                                    checked={show}
                                    onChange={handleChangeEvent("show")}
                                    disabled={isSaving}
                                />
                            }
                            label="Is shown"
                        />
                    </Grid>
                </Grid>
            </Container>
        </Paper>
    );
};

type TeamEditPageProps = {
    match: Match,
};

const TeamEditPage = withErrorHandler(({ match }: TeamEditPageProps) => {
    const fetchProps = useAPI<{ team: Team }>({
        url: `/content/teams/${String(match.params.slug)}`,
    });

    return <TeamPage {...fetchProps} />;
});

const TeamCreatePage = withErrorHandler(() => {
    return (
        <TeamPage
            data={{ team: getEntity({ team: {} }) }}
            isLoading={false}
            isNew
        />
    );
});

export { TeamCreatePage, TeamEditPage };
