// @flow

import React from "react";

const withErrorHandler = (
    Component: React$Component<*, *> | React$StatelessFunctionalComponent<*>
) => {
    class ErrorHandler extends React.Component<*, { hasError: boolean }> {
        state = {
            hasError: false,
        };

        componentDidCatch(error: Error, errorInfo: mixed) {
            // eslint-disable-next-line no-console
            console.error(error, errorInfo);
            this.setState({
                hasError: true,
            });
        }

        render() {
            return this.state.hasError ? (
                "Error"
            ) : (
                <Component {...this.props} />
            );
        }
    }

    return ErrorHandler;
};

export default withErrorHandler;
