// @flow
import React, { useMemo } from "react";
import styled from "styled-components/macro";
import Sticky from "react-sticky-el";

import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import RefreshIcon from "@material-ui/icons/Refresh";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import formatDate from "../../utils/formatDate";
import Loader from "../../shared/components/Loader";
import useAPI from "../../shared/hooks/useAPI";
import withErrorHandler from "../../shared/hocs/withErrorHandler";
import useCRUD from "../../shared/hooks/useCRUD";
import type { RefetchType } from "../../shared/hooks/useAPI";
import ImageUpload from "../../shared/components/ImageUpload";
import { useConfig } from "../context/config";
import type { Config } from "../context/config";
import TextField from "@material-ui/core/TextField";
import type { Locale } from "../Posts/context/posts";
import type { User } from "../Reviews/context/reviews";
import compose from "lodash/fp/compose";
import fpKeyBy from "lodash/fp/keyBy";
import fpMap from "lodash/fp/map";
import mapValues from "lodash/mapValues";
import InputAdornment from "@material-ui/core/InputAdornment";
import useContent from "../../shared/hooks/useContent";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const Container = styled.div`
    padding: 15px;
`;

const ToolbarContainer = styled.div`
    > * {
        margin-right: 5px;
    }
`;

type BonusContent = {
    content: string,
    locale: Locale,
    createdAt: string,
    updatedAt: string,
};

type Bonus = {
    id: string,
    image: string,
    link: string,
    amount: number,
    currency: "UAH",
    mainContent: BonusContent,
    show: boolean,
    createdAt: string,
    updatedAt: string,
    createdBy: User,
    updatedBy: ?User,
};

type Props = {
    isNew?: boolean,
    data: ?{ list: Bonus[] },
    isLoading: boolean,
    refetch?: RefetchType,
};

const stickyStyle = {
    backgroundColor: "white",
    paddingBottom: 15,
    paddingTop: 77,
    zIndex: 2,
};

const getEntity = ({ bonus, config }: { bonus: ?Bonus, config: ?Config }) =>
    bonus
        ? {
              amount: bonus.amount,
              content: compose(
                  fpKeyBy("locale"),
                  fpMap((localeContent) => ({
                      description: localeContent.content,
                      locale: localeContent.locale,
                  }))
              )(bonus.mainContent),
              createdAt: bonus.createdAt,
              createdBy: bonus.createdBy,
              currency: bonus.currency,
              image:
                  bonus.image &&
                  `https://${config.filesBucketName}${bonus.image}`,
              link: bonus.link,
              show: bonus.show,
              updatedAt: bonus.updatedAt,
              updatedBy: bonus.updatedBy,
          }
        : {};

const prepareUpdateBody = ({ amount, image, link, content, show } = {}) => ({
    bonus: {
        amount: parseFloat(amount),
        image: new URL(image).pathname,
        link,
        show,
    },
    content: mapValues(content, (localeContent) => ({
        content: localeContent.description || "",
        locale: localeContent.locale,
    })),
});

const BonusPage = ({ data, isLoading, refetch, isNew = false }: Props) => {
    const bonus = data?.list?.[0];
    const { config } = useConfig();
    const initialEntity = useMemo(() => getEntity({ bonus, config }), [
        bonus,
        config,
    ]);

    const {
        entity,
        handleChangeEvent,
        handleChange,
        isChanged,
        save,
        reset,
        isLoading: isSaving,
    } = useCRUD({
        entity: initialEntity,
        prepareBody: prepareUpdateBody,
        saveMethod: "PATCH",
        url: `/content/bonuses`,
    });

    const { image, amount, link, content, currency, show } = entity || {};

    const { languageChangeComponent, locale } = useContent<Bonus>({
        entity,
        isNew,
        isSaving,
    });

    if (isLoading) {
        return <Loader isFullHeight />;
    }

    return (
        <Paper>
            <Container>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Sticky stickyStyle={stickyStyle}>
                            <ToolbarContainer>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    onClick={save}
                                    endIcon={
                                        isSaving && (
                                            <CircularProgress size={20} />
                                        )
                                    }
                                    disabled={
                                        !image ||
                                        !link ||
                                        !amount ||
                                        !isChanged ||
                                        isSaving
                                    }
                                >
                                    Save
                                </Button>
                                <Button
                                    size="large"
                                    color="secondary"
                                    onClick={reset}
                                    disabled={!isChanged || isSaving}
                                >
                                    Cancel
                                </Button>
                                {refetch && (
                                    <Tooltip title="Reload bonus (all changes will be cancelled)">
                                        <IconButton
                                            color="primary"
                                            onClick={refetch}
                                        >
                                            <RefreshIcon />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </ToolbarContainer>
                        </Sticky>
                        {/*{entity.createdAt && (*/}
                        {/*    <Typography variant="caption">*/}
                        {/*        Created at {formatDate(entity.createdAt)}*/}
                        {/*        {entity.createdBy &&*/}
                        {/*            ` by ${*/}
                        {/*                [*/}
                        {/*                    entity.createdBy.firstName,*/}
                        {/*                    entity.createdBy.lastName,*/}
                        {/*                ].join(" ") || "Unknown"*/}
                        {/*            }`}*/}
                        {/*    </Typography>*/}
                        {/*)}*/}
                        {entity.updatedAt && (
                            <Typography variant="caption" component="div">
                                Last updated at {formatDate(entity.updatedAt)}
                                {entity.updatedBy &&
                                    ` by ${
                                        [
                                            entity.updatedBy.firstName,
                                            entity.updatedBy.lastName,
                                        ].join(" ") || "Unknown"
                                    }`}
                            </Typography>
                        )}
                    </Grid>
                    <Grid container item xs={12} lg={4} md={6}>
                        <Grid item xs={12}>
                            <Typography variant="caption" color="textSecondary">
                                Image
                            </Typography>
                        </Grid>
                        <ImageUpload
                            prefix={`images/bonuses`}
                            value={image}
                            onChange={handleChange("image")}
                            disabled={isSaving}
                            errorMessage={!image ? "Is required" : undefined}
                        />
                    </Grid>
                    <Grid item container spacing={2} xs={12} md={6} lg={8}>
                        <Grid item xs={12} sm={8} lg={10}>
                            <TextField
                                fullWidth
                                label="Link"
                                InputLabelProps={{ shrink: true }}
                                value={link}
                                onChange={handleChangeEvent("link")}
                                disabled={isSaving}
                                helperText={
                                    !link
                                        ? "Is required"
                                        : "Started with http:// or https://"
                                }
                                error={!link}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4} lg={2}>
                            <TextField
                                fullWidth
                                label="Amount"
                                InputLabelProps={{ shrink: true }}
                                value={amount}
                                onChange={handleChangeEvent("amount")}
                                disabled={isSaving}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            {currency || "UAH"}
                                        </InputAdornment>
                                    ),
                                }}
                                inputProps={{
                                    min: 0,
                                    step: 0.5,
                                }}
                                type="number"
                                helperText={!amount && "Is required"}
                                error={!amount}
                            />
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        color="primary"
                                        checked={show}
                                        onChange={handleChangeEvent("show")}
                                        disabled={isSaving}
                                    />
                                }
                                label="Is shown"
                            />
                        </Grid>
                        <Grid
                            item
                            container
                            xs={12}
                            spacing={2}
                            alignItems="center"
                        >
                            <Grid item>
                                <Typography variant="h5">Locale: </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                {languageChangeComponent}
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h5">Content</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Description"
                                InputLabelProps={{ shrink: true }}
                                value={content?.[locale]?.description || ""}
                                onChange={handleChangeEvent(
                                    `content.${locale}.description`
                                )}
                                disabled={isSaving}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Paper>
    );
};

const BonusEditPage = withErrorHandler(() => {
    const fetchProps = useAPI<{ list: Bonus[], count: number }>({
        url: `/content/bonuses/all`,
    });

    return <BonusPage {...fetchProps} />;
});

export { BonusEditPage };
