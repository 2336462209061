import { stateToHTML } from "draft-js-export-html";

const stateToHtmlOptions = {
    entityStyleFn: (entity) => {
        const entityType = entity.get("type").toLowerCase();
        if (entityType === "link") {
            const data = entity.getData();
            return {
                attributes: {
                    href: data.url,
                    rel: "nofollow",
                    target: data.targetOption,
                },
                element: "a",
            };
        }

        if (entityType === "embedded_link") {
            const data = entity.getData();
            return {
                attributes: {
                    allowfullscreen: true,
                    frameborder: 0,
                    height: data.height,
                    src: data.src,
                    width: data.width,
                },
                element: "iframe",
            };
        }
    },
};

export default (content) => {
    const html = stateToHTML(content, stateToHtmlOptions);

    const doc = new DOMParser().parseFromString(html, "text/html");
    const images = doc.querySelectorAll("img");

    images.forEach((image) => {
        if (image.parentNode?.tagName?.toLowerCase() !== "figure") {
            image.remove();
        }
    });

    const iframes = doc.querySelectorAll("iframe");

    iframes.forEach((iframe) => {
        iframe.textContent = "";
    });

    return doc.body.innerHTML;
};
