export const globalLanguages = {
    en: {
        label: 'English'
    },
    ru: {
        label: 'Russian',
    },
    uk: {
        label: 'Ukrainian',
    }
};
