// @flow

import React from "react";

import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Dialog from "@material-ui/core/Dialog";

type Props = {|
    isOpen: boolean,
    children: *,
    onClose: Function,
|};

const BaseModal = ({ isOpen, children, onClose }: Props) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    return (
        <Dialog open={isOpen} fullScreen={fullScreen} onClose={onClose}>
            {typeof children === "function"
                ? children({ fullScreen })
                : children}
        </Dialog>
    );
};

export default BaseModal;
