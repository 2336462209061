// @flow
import React, { useCallback, useMemo } from "react";
import { type Match, useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import slugify from "slugify";
import Sticky from "react-sticky-el";

import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import RefreshIcon from "@material-ui/icons/Refresh";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import formatDate from "../../utils/formatDate";
import Loader from "../../shared/components/Loader";
import useAPI from "../../shared/hooks/useAPI";
import { type Payment } from "./context/payments";
import withErrorHandler from "../../shared/hocs/withErrorHandler";
import useCRUD from "../../shared/hooks/useCRUD";
import NotFound from "../../shared/components/NotFound";
import type { RefetchType } from "../../shared/hooks/useAPI";
import TextField from "@material-ui/core/TextField";
import ImageUpload from "../../shared/components/ImageUpload";

const Container = styled.div`
    padding: 15px;
`;

const ToolbarContainer = styled.div`
    > * {
        margin-right: 5px;
    }
`;

type Props = {
    isNew?: boolean,
    data: { payment: ?Payment },
    isLoading: boolean,
    refetch?: RefetchType,
};

const stickyStyle = {
    backgroundColor: "white",
    paddingBottom: 15,
    paddingTop: 77,
    zIndex: 2,
};

const getEntity = ({ payment }: { payment: ?Payment }) =>
    payment
        ? {
              createdAt: payment.createdAt,
              createdBy: payment.createdBy,
              image: payment.image,
              title: payment.title || "",
              updatedAt: payment.updatedAt,
              updatedBy: payment.updatedBy,
          }
        : null;

const prepareUpdateBody = ({ image, title } = {}) => ({
    image,
    title,
});

const PaymentPage = ({ data, isLoading, refetch, isNew = false }: Props) => {
    const payment = data?.payment;

    const initialEntity = useMemo(() => getEntity({ payment }), [payment]);

    const {
        entity,
        handleChangeEvent,
        handleChange,
        isChanged,
        save,
        reset,
        isLoading: isSaving,
    } = useCRUD({
        entity: initialEntity,
        prepareBody: prepareUpdateBody,
        saveMethod: isNew ? "POST" : "PATCH",
        url: `/content/reviews/payments${
            isNew ? "" : `/${String(payment?.id)}`
        }`,
    });

    const { title, image } = entity || {};

    const history = useHistory();

    const goBack = useCallback(() => {
        history.push("/reviews/payments");
    }, [history]);

    const handleSave = useCallback(async () => {
        await save();
        history.push(`/reviews/payments`);
    }, [save, history]);

    const handleReload = useCallback(() => refetch && refetch(), [refetch]);

    if (isLoading) {
        return <Loader isFullHeight />;
    }

    if (!payment || !entity) {
        return <NotFound name="Review payments" url="/reviews/payments" />;
    }

    return (
        <Paper>
            <Container>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Sticky stickyStyle={stickyStyle}>
                            <ToolbarContainer>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    onClick={isNew ? handleSave : save}
                                    disabled={!title || !isChanged || isSaving}
                                    endIcon={
                                        isSaving && (
                                            <CircularProgress size={20} />
                                        )
                                    }
                                >
                                    Save
                                </Button>
                                <Button
                                    size="large"
                                    color="secondary"
                                    onClick={isNew ? goBack : reset}
                                    disabled={
                                        !isNew && (!isChanged || isSaving)
                                    }
                                >
                                    Cancel
                                </Button>
                                {refetch && (
                                    <Tooltip title="Reload payment (all changes will be cancelled)">
                                        <IconButton
                                            color="primary"
                                            onClick={handleReload}
                                        >
                                            <RefreshIcon />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </ToolbarContainer>
                        </Sticky>
                        <Typography variant="h5">{title}</Typography>
                        {entity.createdAt && (
                            <Typography variant="caption">
                                Created at {formatDate(entity.createdAt)}
                                {entity.createdBy &&
                                    ` by ${
                                        [
                                            entity.createdBy.firstName,
                                            entity.createdBy.lastName,
                                        ].join(" ") || "Unknown"
                                    }`}
                            </Typography>
                        )}
                        {entity.updatedAt && (
                            <Typography variant="caption" component="div">
                                Last updated at {formatDate(entity.updatedAt)}
                                {entity.updatedBy &&
                                    ` by ${
                                        [
                                            entity.updatedBy.firstName,
                                            entity.updatedBy.lastName,
                                        ].join(" ") || "Unknown"
                                    }`}
                            </Typography>
                        )}
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            fullWidth
                            label="Title"
                            InputLabelProps={{ shrink: true }}
                            value={title}
                            onChange={handleChangeEvent("title")}
                            disabled={isSaving}
                            error={!title}
                            helperText={!title && "Is required"}
                        />
                    </Grid>
                    <Grid container item xs={12} md={8}>
                        <Grid item xs={12}>
                            <Typography variant="caption" color="textSecondary">
                                Image
                            </Typography>
                        </Grid>
                        <ImageUpload
                            prefix={`images/payments/${slugify(title, {
                                lower: true,
                            })}`}
                            value={image}
                            onChange={handleChange("image")}
                            disabled={!title || isSaving}
                            errorMessage={
                                !title ? "Enter title first" : undefined
                            }
                            styles={{
                                borderRadius: 12,
                                height: 54,
                                width: 106,
                            }}
                        />
                    </Grid>
                </Grid>
            </Container>
        </Paper>
    );
};

type PaymentEditPageProps = {
    match: Match,
};

const PaymentEditPage = withErrorHandler(({ match }: PaymentEditPageProps) => {
    const fetchProps = useAPI<{ payment: Payment }>({
        url: `/content/reviews/payments/${String(match.params.id)}`,
    });

    return <PaymentPage {...fetchProps} />;
});

const PaymentCreatePage = withErrorHandler(() => {
    return (
        <PaymentPage
            data={{ payment: getEntity({ payment: {} }) }}
            isLoading={false}
            isNew
        />
    );
});

export { PaymentCreatePage, PaymentEditPage };
