// @flow

import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import map from "lodash/map";

import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";

import CreateIcon from "@material-ui/icons/Create";

import { usePosts, Provider, type Post } from "./context/posts";
import {
    useCategories,
    Provider as CategoriesProvider,
} from "../context/categories";
import Table, {
    type Column,
    dataTypes,
} from "../../shared/components/SimpleTable";
import SectionToolbar from "../../shared/components/SectionToolbar";
import TableToolbar from "../../shared/components/TableToolbar";

const columns: Column[] = [
    {
        label: "Id",
        name: "id",
    },
    {
        label: "Slug",
        name: "slug",
    },
    {
        formatter: ({ mainContent }) =>
            map(mainContent, "locale").sort().join(", "),
        isSortable: false,
        label: "Languages",
        name: "locales",
    },
    {
        formatter: ({ author }) =>
            [author?.firstName, author?.lastName].join(" "),
        isSortable: false,
        label: "Author",
        name: "author",
    },
    {
        label: "Status",
        name: "status",
    },
    {
        label: "Comments status",
        name: "commentStatus",
    },
    {
        dataType: dataTypes.DATE_TIME,
        label: "Post date",
        name: "postDate",
    },
    {
        dataType: dataTypes.DATE_TIME,
        label: "Created At",
        name: "createdAt",
    },
    {
        dataType: dataTypes.DATE_TIME,
        label: "Updated At",
        name: "updatedAt",
    },
    {
        formatter: ({ updatedBy }) =>
            [updatedBy?.firstName, updatedBy?.lastName].join(" "),
        isSortable: false,
        label: "Updated By",
        name: "updatedBy",
    },
];

const PostsSection = () => {
    const history = useHistory();
    const {
        authors,
        posts,
        count,
        isLoading,
        refetch,
        deletePost,
    } = usePosts();
    const { categories } = useCategories();

    const handleEdit = useCallback(
        ({ slug }: Post) => {
            history.push(`/posts/${slug}`);
        },
        [history]
    );

    const handleCreate = useCallback(() => {
        history.push("/posts/new");
    }, [history]);

    return (
        <Paper>
            <SectionToolbar
                title={<>Posts {!isLoading && <>({count})</>}</>}
                refetch={refetch}
            >
                <TableToolbar
                    refetch={refetch}
                    authors={authors}
                    categories={categories}
                />
                <Button
                    variant="contained"
                    color="primary"
                    endIcon={<CreateIcon />}
                    onClick={handleCreate}
                >
                    Create post
                </Button>
            </SectionToolbar>
            <Table
                isEditable
                columns={columns}
                data={posts}
                count={count}
                refetch={refetch}
                isLoading={isLoading}
                onEdit={handleEdit}
                onItemDelete={ deletePost }
            />
        </Paper>
    );
};

export default () => (
    <Provider>
        <CategoriesProvider entity="post">
            <PostsSection />
        </CategoriesProvider>
    </Provider>
);