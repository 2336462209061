// @flow

import React, { createContext, useCallback, useContext } from "react";
import noop from "lodash/noop";

import useAPI from "../../../shared/hooks/useAPI";
import type { PaginationProps } from "../../../shared/hooks/useAPI";
import apiCall from "../../../utils/apiCall";

export type Locale = "ru" | "uk";

export type KnowledgeBaseContent = {
    locale: Locale,
    title: string,
    description: string,
    metaTitle: string,
    metaDescription: string,
    content: string,
    createdAt: string,
    updatedAt: string,
};

export type Author = {
    id: string,
    firstName: string,
    lastName: string,
    avatarUrl?: string,
};

export type KnowledgeBase = {
    id: string,
    slug: string,
    mainContent: KnowledgeBaseContent[],
    tags: string[],
    status: "published" | "created",
    order: number,
    author: Author,
    images: {
        main: string,
    },
};

type KnowledgeBasesContext = {
    authors: ?(Author[]),
    count: number,
    knowledgeBases: ?(KnowledgeBase[]),
    error: ?Error,
    isLoading: boolean,
    refetch: (PaginationProps) => Promise<void>,
    deleteKnowledgeBase: (KnowledgeBase) => Promise<void>,
};

const Context = createContext<KnowledgeBasesContext>({
    authors: null,
    count: 0,
    deleteKnowledgeBase: noop,
    error: null,
    isLoading: false,
    knowledgeBases: null,
    refetch: noop,
});

const KnowledgeBasesProvider = ({ children }: { children: * }) => {
    const { data, isLoading, error, refetch } = useAPI<{
        count: number,
        list: KnowledgeBase[],
    }>({
        url: "/content/knowledge-bases/all",
    });

    const { data: authors, isLoading: isAuthorsLoading } = useAPI<{
        list: Author[],
    }>({
        url: "/content/knowledge-bases/authors",
    });

    const deleteKnowledgeBase = useCallback(
        async ({ slug }: KnowledgeBase) => {
            await apiCall({
                method: "DELETE",
                url: `/content/knowledge-bases/${slug}`,
            });

            refetch({});
        },
        [refetch]
    );

    return (
        <Context.Provider
            value={{
                authors: authors?.list,
                count: data?.count || 0,
                deleteKnowledgeBase,
                error,
                isLoading: isLoading || isAuthorsLoading,
                knowledgeBases: data?.list,
                refetch,
            }}
        >
            {children}
        </Context.Provider>
    );
};

const useKnowledgeBases = () => useContext<KnowledgeBasesContext>(Context);

export { KnowledgeBasesProvider as Provider, useKnowledgeBases };
