// @flow

import React from "react";
import styled from "styled-components/macro";

import BaseModal from "../BaseModal";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

const LoaderContainer = styled.div`
    margin-right: 5px;
    align-items: center;
    display: flex;
`;

type Props = {|
    isOpen: boolean,
    title: string,
    onConfirm?: Function,
    onCancel?: Function,
    isLoading?: boolean,
|};

const ConfirmationModal = ({
    isOpen,
    onCancel,
    onConfirm,
    isLoading,
    title,
}: Props) => {
    return (
        <BaseModal isOpen={isOpen} onClose={onCancel}>
            {({ fullScreen }) => (
                <>
                    <DialogTitle>Are you sure?</DialogTitle>
                    <DialogContent>{title}</DialogContent>
                    <DialogActions>
                        <Button
                            onClick={onCancel}
                            color="primary"
                            variant="contained"
                            fullWidth={fullScreen}
                        >
                            No
                        </Button>
                        <Button
                            onClick={onConfirm}
                            color="primary"
                            disabled={isLoading}
                            fullWidth={fullScreen}
                        >
                            {isLoading && (
                                <LoaderContainer>
                                    <CircularProgress size={20} />
                                </LoaderContainer>
                            )}
                            Yes
                        </Button>
                    </DialogActions>
                </>
            )}
        </BaseModal>
    );
};

export default ConfirmationModal;
