// @flow

import React, { forwardRef } from 'react'
import compose from 'lodash/fp/compose'
import fpKeyBy from 'lodash/fp/keyBy'
import fpMapValues from 'lodash/fp/mapValues'
import MaterialTable from 'material-table'
import styled from 'styled-components/macro'

import Typography from '@material-ui/core/Typography'
import AddBoxIcon from '@material-ui/icons/AddBox'

import Loader from '../../shared/components/Loader'
import useAPI from '../../shared/hooks/useAPI'
import type { Payment } from './context/reviews'

const AddButtonContainer = styled.div`
    align-items: center;
    display: flex;

    > *:not(last-child) {
        margin-right: 5px;
    }
`

const columns = ({ reviewPayments = [] }) => [
    {
        render: ({ paymentId }) => {
            const payment = reviewPayments.find(({ id }) => id === paymentId)
            return (
                payment && (
                    <img width={106} src={payment.image} alt={payment.title} />
                )
            )
        },
        title: 'Image',
    },
    {
        field: 'paymentId',
        lookup: compose(
            fpMapValues(({ title }) => title),
            fpKeyBy('id'),
        )(reviewPayments),
        title: 'Payment',
        validate: (rowData) => Boolean(rowData.paymentId) || 'Is required',
    },
    {
        field: 'depositSumFrom',
        title: 'Deposit Sum from',
        type: 'numeric',
    },
    {
        field: 'depositSumTo',
        title: 'Deposit Sum to',
        type: 'numeric',
    },
    {
        field: 'depositCurrency',
        initialEditValue: 'UAH',
        lookup: {
            EUR: 'EUR',
            UAH: 'UAH',
            USD: 'USD',
        },
        title: 'Deposit Currency',
    },
    {
        field: 'depositPeriodFrom',
        title: 'Deposit Period from',
        type: 'numeric',
    },
    {
        field: 'depositPeriodTo',
        title: 'Deposit Period to',
        type: 'numeric',
    },
    {
        field: 'depositPeriodType',
        initialEditValue: 'none',
        lookup: {
            none: '-',
            // eslint-disable-next-line sort-keys
            instant: 'Instant',
            minute: 'Minute',
            // eslint-disable-next-line sort-keys
            hour: 'Hour',
            // eslint-disable-next-line sort-keys
            day: 'Day',
            week: 'Week',
        },
        title: 'Deposit period type',
    },
    {
        field: 'depositCommission',
        title: 'Deposit commission',
    },
    {
        field: 'withdrawalSumFrom',
        title: 'Withdrawal Sum from',
        type: 'numeric',
    },
    {
        field: 'withdrawalSumTo',
        title: 'Withdrawal Sum to',
        type: 'numeric',
    },
    {
        field: 'withdrawalCurrency',
        initialEditValue: 'UAH',
        lookup: {
            EUR: 'EUR',
            UAH: 'UAH',
            USD: 'USD',
        },
        title: 'Withdrawal Currency',
    },
    {
        field: 'withdrawalPeriodFrom',
        title: 'Withdrawal Period from',
        type: 'numeric',
    },
    {
        field: 'withdrawalPeriodTo',
        title: 'Withdrawal Period to',
        type: 'numeric',
    },
    {
        field: 'withdrawalPeriodType',
        initialEditValue: 'none',
        lookup: {
            none: '-',
            // eslint-disable-next-line sort-keys
            instant: 'Instant',
            minute: 'Minute',
            // eslint-disable-next-line sort-keys
            hour: 'Hour',
            // eslint-disable-next-line sort-keys
            day: 'Day',
            week: 'Week',
        },
        title: 'Withdrawal period type',
    },
    {
        field: 'withdrawalCommission',
        title: 'Withdrawal commission',
    },
]

type Props = {|
    payments: *[],
    onAdd?: (*) => void,
    onUpdate?: (*, *) => void,
    onDelete?: (*) => void,
|}

const Payments = ({ payments, onAdd, onUpdate, onDelete }: Props) => {
    const { data, isLoading } = useAPI<{
        count: number,
        list: Payment[],
    }>({
        url: '/content/reviews/payments',
    })

    if (isLoading) {
        return <Loader />
    }

    return (
        <MaterialTable
            icons={{
                Add: forwardRef((props, ref) => (
                    <AddButtonContainer ref={ref} {...props}>
                        <Typography>Add method</Typography>
                        <AddBoxIcon />
                    </AddButtonContainer>
                )),
            }}
            columns={columns({ reviewPayments: data?.list })}
            data={payments}
            title={<Typography variant="h5">Payments</Typography>}
            options={{ search: false }}
            editable={{
                isDeletable: (rowData) => true,
                onRowAdd: async (newData) => {
                    if (onAdd) {
                        onAdd(newData)
                    }
                },
                onRowDelete: async (deletedData) => {
                    console.log({ deletedData })
                    if (onDelete) {
                        onDelete(deletedData)
                    }
                },
                onRowUpdate: async (newData, oldData) => {
                    if (onUpdate) {
                        onUpdate(newData, oldData)
                    }
                },
            }}
        />
    )
}

export default Payments
