// @flow

import React, { createContext, useContext } from "react";
import noop from "lodash/noop";

import useAPI from "../../shared/hooks/useAPI";

export type Config = {
    filesBucketName: string,
};

type ConfigContext = {
    config: ?Config,
    error: ?Error,
    isLoading: boolean,
    refetch: () => Promise<void>,
};

const Context = createContext<ConfigContext>({
    config: {
        filesBucketName: "cdn.bet.ua",
    },
    error: null,
    isLoading: false,
    refetch: noop,
});

const ConfigProvider = ({ children }: { children: * }) => {
    const { data, isLoading, error, refetch } = useAPI<*>({
        url: "/conf",
    });

    return (
        <Context.Provider
            value={{
                config: {
                    // FIXME: Name the variable "filesBucketName" properly to avoid misunderstanding
                    filesBucketName:
                        (data &&
                            new URLSearchParams(data.config.amazonS3Config).get(
                                "cdnName"
                            )) ||
                        "cdn.bet.ua",
                },
                error,
                isLoading,
                refetch,
            }}
        >
            {children}
        </Context.Provider>
    );
};

const useConfig = () => useContext<ConfigContext>(Context);

export { ConfigProvider as Provider, useConfig };
