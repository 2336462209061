// @flow

import React, { useCallback, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";

import Paper from "@material-ui/core/Paper";

import {
    Provider,
    type Complaint,
    useComplaints,
    complaintStatuses,
} from "./context/complaints";
import Table, {
    type Column,
    dataTypes,
} from "../../shared/components/SimpleTable";
import SectionToolbar from "../../shared/components/SectionToolbar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import capitalize from "lodash/capitalize";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import { DebounceInput } from "react-debounce-input";
import authorFormatter from "../../utils/authorFormatter";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { ReviewsProvider, useReviews } from "../Reviews/context/reviews";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

const columns: Column[] = [
    {
        label: "Id",
        name: "id",
    },
    {
        formatter: ({ createdBy }) => authorFormatter(createdBy),
        isSortable: false,
        label: "Author",
        name: "createdBy",
    },
    {
        label: "Sum",
        name: "sum",
    },
    {
        label: "Currency",
        name: "currency",
    },
    {
        formatter: ({ review }) => (
            <a
                href={`/reviews/${review.slug}`}
                target="_blank"
                rel="noopener noreferrer"
                title={review.slug}
            >
                {review.slug}
            </a>
        ),
        isSortable: false,
        label: "Review",
        name: "reviewId",
    },
    {
        dataType: dataTypes.DATE_TIME,
        label: "Created At",
        name: "createdAt",
    },
    {
        dataType: dataTypes.DATE_TIME,
        label: "Updated At",
        name: "updatedAt",
    },
    {
        formatter: ({ updatedBy }) => authorFormatter(updatedBy),
        isSortable: false,
        label: "Updated By",
        name: "updatedBy",
    },
];

const statuses = Object.values(complaintStatuses);

const ComplaintsSection = () => {
    const history = useHistory();
    const {
        complaints,
        count,
        isLoading,
        refetch,
        countByStatuses,
    } = useComplaints();
    const { reviews } = useReviews();
    const handleEdit = useCallback(
        ({ id }: Complaint) => {
            history.push(`/complaints/${id}`);
        },
        [history]
    );

    const { search } = useLocation();

    const [tab, setTab] = useState(() => {
        const status = new URLSearchParams(search).get("status");
        return status && statuses.includes(status)
            ? statuses.findIndex((x) => x === status) || 0
            : 0;
    });
    const handleTabChange = useCallback(
        (e, newTab) => {
            setTab(newTab);
            window.history.replaceState(
                window.history.state,
                window.document.title,
                `/complaints?status=${statuses[newTab]}`
            );
            refetch({ statuses: statuses[newTab] });
        },
        [refetch]
    );

    const handleSearch = useCallback(
        (e) =>
            refetch({
                userId: e.target.value || undefined,
            }),
        [refetch]
    );

    const handleReviewChange = useCallback(
        (_, value) => refetch({ reviewId: value?.id }),
        [refetch]
    );

    return (
        <Paper>
            <SectionToolbar title="Complaints" refetch={refetch}>
                <Box pt={2}>
                    <Grid container spacing={2}>
                        <Grid item>
                            <Box width={250}>
                                <Autocomplete
                                    fullWidth
                                    options={reviews}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Bookmaker, casino"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            helperText="Type to search"
                                        />
                                    )}
                                    getOptionLabel={({ slug }) => slug}
                                    onChange={handleReviewChange}
                                />
                            </Box>
                        </Grid>

                        <Grid item>
                            <TextField
                                label="User ID"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                    inputComponent: DebounceInput,
                                    inputProps: { debounceTimeout: 500 },
                                    onChange: handleSearch,
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </SectionToolbar>
            <Tabs
                value={tab}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleTabChange}
            >
                {statuses.map((name) => (
                    <Tab
                        label={`${capitalize(name)}(${
                            isLoading ? "?" : countByStatuses[name] || 0
                        })`}
                        key={name}
                    />
                ))}
            </Tabs>
            <Table
                isEditable
                columns={columns}
                data={complaints}
                count={count}
                refetch={refetch}
                isLoading={isLoading}
                onEdit={handleEdit}
            />
        </Paper>
    );
};

export default () => {
    const { search } = useLocation();
    const status = new URLSearchParams(search).get("status");
    return (
        <ReviewsProvider status="published">
            <Provider
                statuses={[
                    status && statuses.includes(status)
                        ? status
                        : complaintStatuses.NEW,
                ]}
            >
                <ComplaintsSection />
            </Provider>
        </ReviewsProvider>
    );
};
