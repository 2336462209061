// @flow

import React, { createContext, useContext } from "react";
import useAPI from "../../../shared/hooks/useAPI";

type CurrentUser = {
    id: string,
    name: string,
    roles: string[],
    avatarUrl?: string,
};

type CurrentUserContext = {
    currentUser: ?CurrentUser,
    error: ?Error,
    isLoading: boolean,
};

const Context = createContext<CurrentUserContext>({
    currentUser: null,
    error: null,
    isLoading: false,
});

const CurrentUserProvider = ({ children }: { children: * }) => {
    const { data: currentUser, isLoading, error } = useAPI<CurrentUser>({
        url: "/users/me",
    });

    return (
        <Context.Provider value={{ currentUser, error, isLoading }}>
            {children}
        </Context.Provider>
    );
};

const useCurrentUser = () => useContext<CurrentUserContext>(Context);

export { CurrentUserProvider as Provider, useCurrentUser };
