// @flow
import React, { useCallback, useMemo, useRef, useEffect } from "react";
import { type Match } from "react-router-dom";
import styled from "styled-components/macro";
import pick from "lodash/pick";
import uniqBy from "lodash/uniqBy";
import Sticky from "react-sticky-el";
import startOfDay from "date-fns/startOfDay";
import format from "date-fns/format";
import PubSub from "pubsub-js";

import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Box from "@material-ui/core/Box";
import green from "@material-ui/core/colors/green";
import grey from "@material-ui/core/colors/grey";

import RefreshIcon from "@material-ui/icons/Refresh";

import formatDate from "../../utils/formatDate";
import Loader from "../../shared/components/Loader";
import useAPI from "../../shared/hooks/useAPI";
import { closedStatuses, complaintStatusesList } from "./context/complaints";
import { type Config, useConfig } from "../context/config";
import withErrorHandler from "../../shared/hocs/withErrorHandler";
import useCRUD from "../../shared/hooks/useCRUD";
import NotFound from "../../shared/components/NotFound";
import type { RefetchType } from "../../shared/hooks/useAPI";
import StatusSelect from "../../shared/components/StatusSelect";
import type { Complaint } from "./context/complaints";
import { Provider as UsersProvider, useUsers } from "../Users/context/users";
import MessageInput from "../../shared/components/MessagesInput";
import apiCall from "../../utils/apiCall";
import uploadFile from "../../utils/uploadFile";
import Attachments from "../../shared/components/Attachments";

const Container = styled.div`
    padding: 15px;
`;

const ToolbarContainer = styled.div`
    > * {
        margin-right: 5px;
    }
`;

const MessagesContainer = styled.div`
    max-height: 300px;
    overflow: auto;
    padding: 5px;
`;

const Message = styled.div`
    padding: 10px;
    border-radius: 10px;
    background-color: ${(props) => (props.incomming ? grey[100] : green[50])};
    display: flex;
    align-items: flex-end;
    max-width: 70%;

    > :first-child {
        flex: 1;
        margin-right: 10px;
    }

    > :last-child {
        font-size: 12px;
        color: ${(props) => (props.incomming ? grey[600] : green[600])};
    }
`;

type Props = {
    isNew?: boolean,
    data: ?Complaint,
    isLoading: boolean,
    refetch?: RefetchType,
};

const stickyStyle = {
    backgroundColor: "white",
    paddingBottom: 15,
    paddingTop: 77,
    zIndex: 2,
};

const getEntity = ({ complaint }: { complaint: ?Complaint, config: ?Config }) =>
    complaint
        ? pick(complaint, [
              "id",
              "createdBy",
              "createdById",
              "updatedBy",
              "createdAt",
              "updatedAt",
              "review",
              "reviewId",
              "finalComment",
              "userIdOnOperatorSide",
              "assignee",
              "sum",
              "currency",
              "content",
              "status",
              "messages",
          ])
        : null;

const prepareUpdateBody = (complaint) =>
    pick(complaint, ["finalComment", "status", "userIdOnOperatorSide"]);

const ComplaintPage = ({
    data: complaint,
    isLoading,
    refetch,
    isNew = false,
}: Props) => {
    const messagesBottomRef = useRef(null);

    useEffect(() => {
        setTimeout(() => {
            if (messagesBottomRef.current) {
                messagesBottomRef.current.scrollIntoView();
            }
        }, 1000);
    }, []);

    const { config } = useConfig();
    const { users: assignees, isLoading: isAssigneesLoading } = useUsers();

    const initialEntity = useMemo(() => getEntity({ complaint }), [complaint]);

    const {
        entity,
        handleChangeEvent,
        handleChange,
        handleMultipleChange,
        isChanged,
        save,
        reset,
        isLoading: isSaving,
    } = useCRUD({
        entity: initialEntity,
        prepareBody: prepareUpdateBody,
        saveMethod: isNew ? "POST" : "PUT",
        url: `/content/reviews/complaints/${String(complaint?.id)}`,
    });

    const { id, messages } = entity || {};

    useEffect(() => {
        const token = PubSub.subscribe(
            "COMPLAINT_MESSAGE_CREATE",
            (_, data) => {
                handleChange("messages")(uniqBy([...messages, data], "id"));
            }
        );

        return () => {
            PubSub.unsubscribe(token);
        };
    }, [id, handleChange, messages]);

    const handleReload = useCallback(() => refetch && refetch(), [refetch]);

    const handleAssigneeChange = useCallback(
        (_, value) => {
            handleMultipleChange({
                assignee: value,
                userIdOnOperatorSide: value.id,
            });
        },
        [handleMultipleChange]
    );

    const handleMessageSend = useCallback(
        async ({ message: content, attachments: rawAttachments }) => {
            try {
                const attachments = rawAttachments.length
                    ? await Promise.all(
                          rawAttachments.map(async ({ url: file, type }) => {
                              const { key, url } = await uploadFile({
                                  file,
                                  filesBucketName: config.filesBucketName,
                                  isPrivate: true,
                                  name: `complaints/${id}/attachments/image-${Date.now()}`,
                                  type,
                              });

                              return {
                                  key,
                                  type,
                                  url,
                              };
                          })
                      )
                    : undefined;
                const response = await apiCall({
                    body: { attachments, content },
                    method: "POST",
                    url: `/content/reviews/complaints/${id}/messages`,
                });
                const newMessage = await response.json();
                handleChange("messages")([...messages, newMessage]);
                if (messagesBottomRef?.current) {
                    messagesBottomRef.current.scrollIntoView({
                        behavior: "smooth",
                    });
                }
            } catch (e) {
                window.alert(`Send message error: ${e.message}`);
            }
        },
        [id, handleChange, messages, config.filesBucketName]
    );

    const isClosed = useMemo(() => closedStatuses.includes(entity?.status), [
        entity,
    ]);

    if (isLoading || isAssigneesLoading) {
        return <Loader isFullHeight />;
    }

    if (!complaint || !entity) {
        return <NotFound name="Complaints" url="/complaints" />;
    }

    return (
        <Paper>
            <Container>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Sticky stickyStyle={stickyStyle}>
                            <ToolbarContainer>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    onClick={save}
                                    disabled={
                                        !isChanged ||
                                        isSaving ||
                                        (closedStatuses.includes(
                                            entity.status
                                        ) &&
                                            !entity.finalComment)
                                    }
                                    endIcon={
                                        isSaving && (
                                            <CircularProgress size={20} />
                                        )
                                    }
                                >
                                    Save
                                </Button>
                                <Button
                                    size="large"
                                    color="secondary"
                                    onClick={reset}
                                    disabled={!isChanged || isSaving}
                                >
                                    Cancel
                                </Button>
                                {refetch && (
                                    <Tooltip title="Reload article (all changes will be cancelled)">
                                        <IconButton
                                            color="primary"
                                            onClick={handleReload}
                                        >
                                            <RefreshIcon />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </ToolbarContainer>
                        </Sticky>
                        {entity.createdAt && (
                            <Typography variant="caption">
                                {formatDate(entity.createdAt)}
                                {entity.createdBy &&
                                    ` by ${
                                        [
                                            entity.createdBy.firstName,
                                            entity.createdBy.lastName,
                                        ].join(" ") || "Unknown"
                                    }`}
                            </Typography>
                        )}
                        {entity.updatedAt && (
                            <Typography variant="caption" component="div">
                                Last updated at {formatDate(entity.updatedAt)}
                                {entity.updatedBy &&
                                    ` by ${
                                        [
                                            entity.updatedBy.firstName,
                                            entity.updatedBy.lastName,
                                        ].join(" ") || "Unknown"
                                    }`}
                            </Typography>
                        )}
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <StatusSelect
                            options={complaintStatusesList}
                            value={entity.status}
                            onChange={handleChangeEvent("status")}
                            isDisabled={isSaving}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <TextField
                            fullWidth
                            value={entity.review.slug}
                            InputLabelProps={{ shrink: true }}
                            label="Bookmaker, casino"
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <TextField
                            fullWidth
                            label="Amount"
                            InputLabelProps={{ shrink: true }}
                            value={entity.sum || ""}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        {entity.currency || "UAH"}
                                    </InputAdornment>
                                ),
                            }}
                            type="number"
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <TextField
                            fullWidth
                            label="User"
                            InputLabelProps={{ shrink: true }}
                            value={`${[
                                entity.createdBy.firstName,
                                entity.createdBy.lastName,
                            ].join(" ")} (${entity.createdBy.id})`}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <Autocomplete
                            fullWidth
                            options={assignees}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Assignee (operator)"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    helperText="Type to search"
                                />
                            )}
                            onChange={handleAssigneeChange}
                            getOptionLabel={({ firstName, lastName }) =>
                                [firstName, lastName].join(" ")
                            }
                            disableClearable
                            value={entity.assignee}
                        />
                    </Grid>
                    <Grid item md={6} lg={9} />
                    <Grid item md={6} lg={9} />
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            multiline
                            disabled
                            label="Content"
                            InputLabelProps={{ shrink: true }}
                            value={entity.content}
                            rows={5}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            multiline
                            disabled={!isClosed}
                            required={isClosed}
                            helperText={
                                isClosed &&
                                !entity.finalComment &&
                                "Is required"
                            }
                            error={isClosed && !entity.finalComment}
                            onChange={handleChangeEvent("finalComment")}
                            label="Final comment"
                            InputLabelProps={{ shrink: true }}
                            value={entity.finalComment}
                            rows={5}
                        />
                    </Grid>
                    {entity.messages && (
                        <>
                            <Grid item xs={12}>
                                <Typography variant="h5">Messages</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <MessagesContainer>
                                    {entity.messages?.length ? (
                                        entity.messages.map(
                                            ({
                                                id,
                                                content,
                                                createdAt,
                                                createdById,
                                                createdBy,
                                                attachments,
                                            }) => (
                                                <Box
                                                    display="flex"
                                                    alignItems={
                                                        createdById ===
                                                        entity.createdById
                                                            ? "flex-start"
                                                            : "flex-end"
                                                    }
                                                    flexDirection="column"
                                                    mb={1}
                                                    key={id}
                                                >
                                                    <div>
                                                        {[
                                                            createdBy.firstName,
                                                            createdBy.lastName,
                                                        ].join(" ")}
                                                    </div>
                                                    <Message
                                                        incomming={
                                                            createdById ===
                                                            entity.createdById
                                                        }
                                                    >
                                                        <span>{content}</span>
                                                        <span>
                                                            {startOfDay(
                                                                new Date()
                                                            ) >
                                                            new Date(createdAt)
                                                                ? format(
                                                                      new Date(
                                                                          createdAt
                                                                      ),
                                                                      "dd.MM.yyyy H:mm:ss"
                                                                  )
                                                                : format(
                                                                      new Date(
                                                                          createdAt
                                                                      ),
                                                                      "H:mm:ss"
                                                                  )}
                                                        </span>
                                                    </Message>
                                                    {attachments?.length >
                                                        0 && (
                                                        <Attachments
                                                            attachments={
                                                                attachments
                                                            }
                                                        />
                                                    )}
                                                </Box>
                                            )
                                        )
                                    ) : (
                                        <Box
                                            p={1}
                                            display="flex"
                                            justifyContent="center"
                                        >
                                            No messages yet{" "}
                                            <span
                                                role="img"
                                                aria-label="crying"
                                            >
                                                😢
                                            </span>
                                        </Box>
                                    )}
                                    <div ref={messagesBottomRef} />
                                </MessagesContainer>
                            </Grid>
                        </>
                    )}
                </Grid>
            </Container>
            {entity.messages && (
                <Sticky mode="bottom">
                    <Paper>
                        <Box p={2}>
                            <MessageInput
                                fileNamePrefix={`complaints/${id}/attachments`}
                                onSend={handleMessageSend}
                                disabled={isClosed}
                                helperText={
                                    isClosed &&
                                    "Complaint is closed. Messaging is not allowed"
                                }
                            />
                        </Box>
                    </Paper>
                </Sticky>
            )}
        </Paper>
    );
};

type ComplaintEditPageEditPageProps = {
    match: Match,
};

const ComplaintEditPage = withErrorHandler(
    ({ match }: ComplaintEditPageEditPageProps) => {
        const fetchProps = useAPI<{ complaint: Complaint }>({
            url: `/content/reviews/complaints/${String(match.params.id)}`,
        });

        return (
            <UsersProvider roles={["admin", "complaintModerator"]}>
                <ComplaintPage {...fetchProps} />
            </UsersProvider>
        );
    }
);

export { ComplaintEditPage };
