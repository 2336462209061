// @flow

import React, { createContext, useCallback, useContext } from "react";
import noop from "lodash/noop";
import useAPI, { type PaginationProps } from "../../../shared/hooks/useAPI";
import type { Locale } from "../../Posts/context/posts";
import apiCall from "../../../utils/apiCall";

export type User = {
    id: string,
    firstName: string,
    lastName: string,
    avatarUrl?: string,
};

export type Team = {
    teamId: string,
    teamContent: {
        id: string,
        image: string,
        title: string,
    },
    win: boolean,
};

export type ForecastContent = {
    locale: Locale,
    title: string,
    description: string,
    metaTitle: string,
    metaDescription: string,
    content: string,
    createdAt: string,
    updated: string,
};

export type Forecast = {
    id: string,
    slug: string,
    teams: Team[],
    mainContent: ForecastContent[],
    forecastDate: string,
    eventDate: string,
    tags: string[],
    league: string[],
    status: "published" | "created",
    hasDraw: boolean,
    commentStatus: "opened" | "closed",
    coefficient: {
        value: number,
        reviewId: string,
    },
    createdAt: string,
    images: {
        main: string,
    },
    updatedAt: string,
    author: User,
    updatedBy?: User,
    videoUrl?: ?string,
};

type ForecastsContext = {
    authors: ?(User[]),
    count: number,
    forecasts: ?(Forecast[]),
    error: ?Error,
    isLoading: boolean,
    refetch: (PaginationProps) => Promise<void>,
    deleteForecast: (Forecast) => Promise<void>,
};

const Context = createContext<ForecastsContext>({
    authors: null,
    count: 0,
    deleteForecast: noop,
    error: null,
    forecasts: null,
    isLoading: false,
    refetch: noop,
});

const ForecastsProvider = ({ children }: { children: * }) => {
    const { data, isLoading, error, refetch } = useAPI<{
        count: number,
        list: Forecast[],
    }>({
        url: "/content/forecasts/all",
    });

    const { data: authors, isLoading: isAuthorsLoading } = useAPI<{
        list: User[],
    }>({
        url: "/content/forecasts/authors",
    });

    const deleteForecast = useCallback(
        async ({ slug }: Forecast) => {
            await apiCall({
                method: "DELETE",
                url: `/content/forecasts/${slug}`,
            });

            refetch({});
        },
        [refetch]
    );

    return (
        <Context.Provider
            value={{
                authors: authors?.list,
                count: data?.count || 0,
                deleteForecast,
                error,
                forecasts: data?.list,
                isLoading: isLoading || isAuthorsLoading,
                refetch,
            }}
        >
            {children}
        </Context.Provider>
    );
};

const useForecasts = () => useContext<ForecastsContext>(Context);

export { ForecastsProvider as Provider, useForecasts };
